import React, { FC } from 'react';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import { ChevronDownIcon } from '../../../assets/svg';

import s from './FaqHome.module.scss';

interface IFaqHome {}

interface FaqItem {
	question: string;
	answer: string;
	id: string;
}

const faqItems: FaqItem[] = [
	{
		id: '0',
		question: 'What is an Affiliate Program?',
		answer:
			'Affiliate Program is a partnership between you and the operator - binobi.com. Being part of the affiliate program the operators pay you a commission for every referred customer that is active on the platform.'
	},
	{
		id: '1',
		question: 'How do I earn money with your program?',
		answer:
			'Whenever a player registers through the tracking link provided to you and wagers money – you will earn a percentage of that player ́s monthly losses..'
	},
	{
		id: '2',
		question: 'How can I add signed up sub-affiliates?',
		answer:
			'You have a dedicated “Sub-affiliates” section. With a dedicated sub-affiliate tracking link you can refer other partners, influencers, friends and professional affiliates to join our program. The affiliates signed up with your link will start automatically generating commission for you when they become active as a partner in our program.'
	},
	{
		id: '3',
		question: 'How do I earn commission?',
		answer:
			'When you refer to a player, this player becomes linked to your affiliate account. When your players wager real money and generate revenue for our brand, you earn a percentage of that revenue which is generated.'
	},
	{
		id: '4',
		question: 'How can I keep track of my performance?',
		answer:
			'You can track your performance by logging into the binobi.com platform. On the main dashboard, you have all the relevant information and for more in-depth analysis we have a special “Reports” section.'
	},
	{
		id: '5',
		question: 'How often are my statistics being updated?',
		answer:
			'The statistics on your affiliate dashboard are updated hourly, so you’ll always be seeing accurate numbers within the hour when you log in. Clicks and Impression data get recorded in real time for when you want to test campaigns prior to going live.'
	},
	{
		id: '6',
		question: 'Does negative balance carry over to the next month?',
		answer:
			'Negative balance is renewed every month and we do not have a Negative Carryover to the next period.'
	}
];

const FaqHome: FC<IFaqHome> = () => {
	const { t } = useTranslation();

	return (
		<div className={s.faq}>
			<div className='container'>
				<div className={s.faq__inner}>
					<div className={s.faq__head}>
						<h1>{t('navigation.link3')}</h1>
					</div>
					<div className={s.faq__body}>
						<Accordion className={s.faq__accordion}>
							<ResponsiveMasonry
								columnsCountBreakPoints={{ 350: 1, 768: 1, 900: 2 }}
							>
								<Masonry gutter='0 40px'>
									{faqItems.map((item, index) => (
										<Accordion.Item
											className={s.faqItem}
											data-aos='fade-up'
											data-aos-duration='700'
											key={index}
											eventKey={item.id}
										>
											<Accordion.Header className={s.faqItemHeader}>
												{item.question}
												<ChevronDownIcon />
											</Accordion.Header>
											<Accordion.Body className={s.faqItemBody}>
												{item.answer}
											</Accordion.Body>
										</Accordion.Item>
									))}
								</Masonry>
							</ResponsiveMasonry>
						</Accordion>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FaqHome;
