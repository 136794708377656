import React from 'react';
import { SVGProps } from 'react';

const MessageIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={400}
		height={400}
		viewBox='0 0 400 400'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path d='M333.333 112.5C358.647 112.5 379.167 91.9797 379.167 66.6667C379.167 41.3537 358.647 20.8333 333.333 20.8333C308.02 20.8333 287.5 41.3537 287.5 66.6667C287.5 91.9797 308.02 112.5 333.333 112.5Z' />
		<path d='M317.333 135.833C291.166 130.167 269.833 108.833 264.166 82.6667C262 72.5 261.833 62.6667 263.666 53.3333C265.833 43 258.166 33.3333 247.5 33.3333H116.666C70.6663 33.3333 33.333 70.6667 33.333 116.667V232.5C33.333 278.5 70.6663 315.833 116.666 315.833H141.666C146.333 315.833 152.333 318.833 155 322.5L180 355.667C191 370.333 209 370.333 220 355.667L245 322.5C248.166 318.333 253 315.833 258.333 315.833H283.5C329.5 315.833 366.666 278.667 366.666 232.667V152.5C366.666 142 357 134.333 346.666 136.333C337.333 138 327.5 138 317.333 135.833ZM133.333 200C124 200 116.666 192.5 116.666 183.333C116.666 174.167 124 166.667 133.333 166.667C142.5 166.667 150 174.167 150 183.333C150 192.5 142.666 200 133.333 200ZM200 200C190.666 200 183.333 192.5 183.333 183.333C183.333 174.167 190.666 166.667 200 166.667C209.166 166.667 216.666 174.167 216.666 183.333C216.666 192.5 209.333 200 200 200ZM266.666 200C257.333 200 250 192.5 250 183.333C250 174.167 257.333 166.667 266.666 166.667C275.833 166.667 283.333 174.167 283.333 183.333C283.333 192.5 276 200 266.666 200Z' />
	</svg>
);
export default MessageIcon;
