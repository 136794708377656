import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { CabinetSvgSelector } from '../../../../assets/images/icons/CabinetSvgSelector';

import s from './index.module.scss';

interface IDatepicker {
	className?: string;
	isSoloDate?: boolean;
	isWeekDate?: boolean;
	isDatePick?: boolean;

	setDates?: (dates: any) => void;
}

const Datepicker: FC<IDatepicker> = ({
	className,
	setDates,
	isSoloDate,
	isWeekDate,
	isDatePick
}) => {
	const today = dayjs();
	const weekAgo = today.subtract(7, 'days').toDate();

	const [startDateForDate, setStartDate] = useState(new Date());
	const [endDateForDate, setEndDate] = useState(null);

	const [dateRange, setDateRange] = useState([weekAgo, today.toDate()]);
	const [startDate, endDate] = dateRange;

	const onChange = (dates: any) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
	};

	const [soloStartDate, setSoloStartDate] = useState(today.toDate());

	useEffect(() => {
		if (isDatePick) {
			if (setDates && startDateForDate && endDateForDate)
				setDates([
					dayjs(startDateForDate).startOf('day'),
					dayjs(endDateForDate).endOf('day')
				]);
		} else if (isSoloDate) {
			if (setDates && soloStartDate) {
				setDates([
					dayjs(soloStartDate).startOf('day'),
					dayjs(soloStartDate).endOf('day')
				]);
			}
		} else {
			if (setDates) setDates(dateRange);
		}
	}, [dateRange, startDateForDate, endDateForDate, soloStartDate]);

	return (
		<div className={`datepicker-input ${className} `}>
			{isDatePick ? (
				<ReactDatePicker
					selected={startDateForDate}
					wrapperClassName={s.datepicker}
					maxDate={today.add(1, 'day').toDate()}
					startDate={startDateForDate}
					endDate={endDateForDate}
					onChange={onChange}
					selectsRange
					monthsShown={2}
				/>
			) : !isSoloDate && !isDatePick ? (
				<ReactDatePicker
					dateFormat='yyyy-MM-dd'
					wrapperClassName={s.datepicker}
					selectsRange={true}
					startDate={startDate}
					endDate={
						!isWeekDate ? endDate : dayjs(startDate).add(7, 'days').toDate()
					}
					maxDate={today.add(1, 'day').toDate()}
					onChange={(update) => {
						let secondDate = update[1];

						if (isSoloDate) {
							secondDate = dayjs(update[0]).endOf('day').toDate();
						}

						if (isWeekDate) {
							secondDate = dayjs(update[0]).add(7, 'days').toDate();
						}

						if (dayjs(secondDate).isAfter(today.add(1, 'day').toDate())) {
							secondDate = today.endOf('day').toDate();
						}

						setDateRange([
							dayjs(update[0]).startOf('day').toDate(),
							dayjs(secondDate).endOf('day').toDate()
						]);
					}}
					monthsShown={2}
				/>
			) : (
				<ReactDatePicker
					wrapperClassName={s.datepicker}
					selected={soloStartDate}
					maxDate={today.add(1, 'day').toDate()}
					// @ts-ignore
					onChange={(date) => setSoloStartDate(date)}
				/>
			)}

			<div className='datepicker-input__icons'>
				<div className='datepicker-input__icon icon'>
					<CabinetSvgSelector id='calendar' />
				</div>
				<div className='datepicker-input__icon datepicker-input__icon-arrow icon'>
					<CabinetSvgSelector id='chevron_down' />
				</div>
			</div>
		</div>
	);
};

export default Datepicker;
