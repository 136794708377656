import { TTraffic } from 'store/services/promos';

export const calculateTrafficTotal = (traffics: TTraffic[]) => {
	const total = {
		fdp: 0,
		rdp: 0,
		income: 0,
		clicks: 0,
		winSum: 0,
		betSum: 0,
		loseSum: 0,
		depositsSum: 0,
		registrations: 0
	};

	traffics.map((traffic) => {
		total.fdp = total.fdp + traffic.fdp;
		total.rdp = total.rdp + traffic.rdp;
		total.clicks = total.clicks + traffic.clicks;
		total.winSum = total.winSum + traffic.winSum;
		total.betSum = total.betSum + traffic.betSum;
		total.loseSum = total.loseSum + traffic.loseSum;
		total.registrations = total.registrations + traffic.registrations;

		total.income = total.income + traffic.fdp * traffic.cpa;
	});

	return total;
};
