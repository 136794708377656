import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { CabinetSvgSelector } from '../../../assets/images/icons/CabinetSvgSelector';
import userColor from '../../../assets/images/svg/user_color.svg';
import { UserIcon } from '../../../assets/svg';
import Language from '../Language/Language';

interface INavbarCabinet {
	toggleNavbar: () => void;
	showNavbar: boolean;
}

const NavbarCabinet: FC<INavbarCabinet> = ({ toggleNavbar, showNavbar }) => {
	return (
		<div className={showNavbar ? 'navbar active' : 'navbar'}>
			<button className='navbar__close' onClick={toggleNavbar}>
				<div className='icon'>
					<CabinetSvgSelector id='close' />
				</div>
			</button>
			<div className='navbar__wrapper'>
				<div className='navbar__control navbar-control'>
					<div className='navbar-control__icons header-control'>
						<Link to='/promo/my/mail' className='header-control__mail'>
							<div className='icon'>
								<CabinetSvgSelector id='mail' />

								<small></small>
							</div>
						</Link>
						<div className='header-control__language language'>
							<Language isCabinet />
						</div>
						<div className='header-control__settings'>
							<Link to='/profile' className='icon' onClick={toggleNavbar}>
								<UserIcon />
							</Link>
						</div>
						<div className='header-control__exit'>
							<Link to='/' className='icon' onClick={toggleNavbar}>
								<CabinetSvgSelector id='exit' />
							</Link>
						</div>
					</div>
					<div className='navbar-control__info'>
						<div className='header-control__username'>
							<span>User name</span>
							User 01234
						</div>
						<div className='header-control__balance'>
							<span> Wallet </span>
							100.00 USD
						</div>
					</div>
				</div>

				<div className='navbar__sections'>
					<div className='navbar__section navbar-section'>
						<div className='navbar-section__head'>
							<div className='navbar-section__title'>Bonuses</div>
							<div className='navbar-section__icon icon'>
								<CabinetSvgSelector id='info' />
							</div>
						</div>
						<div className='navbar-section__body'>
							<div className='navbar-section__bonuses'>
								<ul>
									<li>
										<span>In processing</span>
										<b>0.0000000 USD</b>
									</li>
									<li>
										<span>In payment</span>
										<b>0.0000000 USD</b>
									</li>
									<li>
										<span>Payd</span>
										<b>0.0000000 USD</b>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NavbarCabinet;
