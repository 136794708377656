import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { API_ROUTES } from 'api/routes';

import { baseQueryWithReauth } from '../interceptor';

export type TPartnerBalance = {
	balance: { amount: string };
	processing: number;
	inPayment: number;
	declined: number;
	paid: number;
};

export const personalPartnerApi = createApi({
	reducerPath: 'personalApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['Personal'],
	endpoints: (builder) => ({
		getPaymentsCredentials: builder.query<TPayment[], any>({
			query: (params) => ({
				url: API_ROUTES.GET_PAYMENTS_CREDENTIALS,
				params
			}),
			providesTags: ['Personal']
		}),
		getPartnerBalance: builder.query<TPartnerBalance, any>({
			query: (params) => ({
				url: API_ROUTES.GET_PARTNER_BALANCE,
				params
			}),
			providesTags: ['Personal']
		}),
		getPartnerPayments: builder.query<any, any>({
			query: (params) => ({
				url: API_ROUTES.GET_PARTNER_PAYMENTS,
				params
			}),
			providesTags: ['Personal']
		}),
		createPayment: builder.mutation<any, any>({
			query: (params) => ({
				url: `${API_ROUTES.CREATE_PAYMENT}`,
				body: params,
				method: 'POST'
			}),
			invalidatesTags: ['Personal']
		}),
		createWithdraw: builder.mutation<any, any>({
			query: (params) => ({
				url: `${API_ROUTES.CREATE_WITHDRAW}`,
				body: params,
				method: 'POST'
			}),
			invalidatesTags: ['Personal']
		}),
		setActivePayment: builder.mutation<any, any>({
			query: (paymentId: string) => ({
				url: `${API_ROUTES.SET_ACTIVE_PAYMENT}/${paymentId}`
			}),
			invalidatesTags: ['Personal']
		}),
		removePayment: builder.mutation<any, any>({
			query: (paymentId: string) => ({
				url: `${API_ROUTES.DELETE_PAYMENT}/${paymentId}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Personal']
		})
	})
});

export const {
	useRemovePaymentMutation,
	useCreatePaymentMutation,
	useGetPartnerBalanceQuery,
	useCreateWithdrawMutation,
	useGetPartnerPaymentsQuery,
	useSetActivePaymentMutation,
	useGetPaymentsCredentialsQuery
} = personalPartnerApi;

export type TPayment = {
	id: string;
	active: boolean;
	paymentMethod: string;
	paymentAddress: string;
	createdAt: Date;
	updatedAt: Date;
};
