import ErrorMessage from 'pages/components/ErrorMessage/ErrorMessage';
import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
	useCreateWithdrawMutation,
	useGetPartnerPaymentsQuery
} from 'store/services/personal-partner';
import { errorNotification } from 'utils/toasts';

import { CloseIcon } from '../../../../assets/svg';
import { Button } from '../../Button/Button';
import Input from '../../Input/Input';
import s from '../ModalLogin/ModalLogin.module.scss';

interface ICreateWithdrawModal {
	showCreateWithdrawModal: boolean;
	setShowCreateWithdrawModal: (show: boolean) => void;
}

interface CreateWithdrawFormData {
	amount: string;
}

const CreateWithdrawModal: FC<ICreateWithdrawModal> = ({
	showCreateWithdrawModal,
	setShowCreateWithdrawModal
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<CreateWithdrawFormData>();

	const [createPayment] = useCreateWithdrawMutation();
	const { refetch } = useGetPartnerPaymentsQuery({ currentPage: 1 });

	const onSubmit = async (data: CreateWithdrawFormData) => {
		console.log(data);

		const res = (await createPayment(data)) as any;

		if (res?.data?.error) {
			errorNotification(res.data.message);
			return;
		}

		refetch();
		setShowCreateWithdrawModal(false);
	};

	return (
		<Modal
			show={showCreateWithdrawModal}
			dialogClassName={s.modal}
			onHide={() => setShowCreateWithdrawModal(false)}
			centered
		>
			<div className={s.inner}>
				<div
					className={s.close}
					onClick={() => setShowCreateWithdrawModal(false)}
				>
					<CloseIcon />
				</div>
				<div className={s.wrapper}>
					<div className={s.authTabs}>
						<h2 className={s.title}>Create Withdraw</h2>
					</div>
					<form className={s.form} onSubmit={handleSubmit(onSubmit)}>
						<div className={s.inputs}>
							<Input
								placeholder='amount'
								name='amount'
								register={register}
								validationSchema={{
									required: 'amount is required'
								}}
							/>
							{errors.amount && (
								<ErrorMessage>{errors.amount.message}</ErrorMessage>
							)}
						</div>
						<div className={s.button}>
							<Button type='submit'>Create</Button>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default CreateWithdrawModal;
