import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { ChevronDownIcon } from '../../../assets/svg';

import styles from './Dropdown.module.scss';

export interface IDropdownOption {
	value: string;
	label: string;
	icon?: JSX.Element;
}

interface DropdownProps {
	options: IDropdownOption[];
	className?: string;
	triggerClassName?: string;
	onSelect?: (value: string) => void;
	placeholder?: string;
	defaultValue?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
	options,
	onSelect,
	className,
	triggerClassName,
	placeholder = '',
	defaultValue
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState(
		placeholder ? null : options[0]
	);

	const dropdownRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleOutsideClick);
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, []);

	const handleOptionClick = (option: IDropdownOption) => {
		setSelectedOption(option);
		setIsOpen(false);

		if (onSelect) {
			onSelect(option.value);
		}
	};

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		const defaultOption = options.find(({ value }) => value === defaultValue);
		if (defaultOption) handleOptionClick(defaultOption);
	}, [defaultValue]);

	return (
		<div
			className={classNames(
				styles.dropdown,
				className,
				isOpen && styles.opened
			)}
			ref={dropdownRef}
		>
			<div
				className={classNames(styles.dropdown__trigger, triggerClassName)}
				onClick={toggleDropdown}
			>
				{selectedOption?.icon && (
					<div className={styles.icon}>{selectedOption.icon}</div>
				)}
				<span className={styles.text}>
					{selectedOption?.label || placeholder}
				</span>
				<ChevronDownIcon
					className={styles.iconArrow}
					focusable='false'
					aria-hidden='true'
				/>
			</div>
			{isOpen && (
				<div className={styles.dropdown__inner}>
					<div className={styles.dropdown__content}>
						<div className={styles.dropdownMenu}>
							<ul className={styles.dropdownMenu__inner}>
								{options.map((option) => (
									<li key={option.value} className={styles.dropdownMenu__item}>
										<button
											className={`${styles.dropdownMenu__link} ${
												option.value === selectedOption?.value
													? styles.dropdownMenu__link_selected
													: ''
											}`}
											onClick={() => handleOptionClick(option)}
										>
											{option.icon && (
												<div className={styles.icon}>{option.icon}</div>
											)}

											<span className={styles.text}>{option.label}</span>
										</button>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Dropdown;
