import { useEffect, useState } from 'react';
import { OptionsPaymentModel } from 'types/options';
import countries from 'utils/countries';

import MySelect from '../../components/MySelect';
import PeriodSorting from '../components/PeriodSorting';

const geoOptions = countries
	.sort(function (a, b) {
		if (a.value < b.value) {
			return -1;
		}
		if (a.value > b.value) {
			return 1;
		}
		return 0;
	})
	.map(({ value, label }) => ({ label: `${label}: ${value}`, value }));

export const PromoFilters = ({
	setFilters
}: {
	setFilters: (v: any) => void;
}) => {
	const [dateRange, setDates] = useState<any>();
	const [geo, setSelectedGeo] = useState<any>();
	const [paymentModel, setSelectedPaymentModel] = useState<any>();

	useEffect(() => {
		setFilters({
			...(dateRange && { dateRange }),
			...(geo && { geo }),
			...(paymentModel && { paymentModel })
		});
	}, [dateRange, geo, paymentModel]);

	return (
		<>
			<PeriodSorting setDates={setDates} className='my-promo-mail__period' />

			<div className='my-promo-mail__control promo-mail-control'>
				<div className='promo-mail-control__select select'>
					<span>Payment Model</span>
					<MySelect
						onChange={(v) => setSelectedPaymentModel((v as any).value)}
						options={OptionsPaymentModel}
						defaultValue={OptionsPaymentModel[0]}
					/>
				</div>
				<div className='promo-mail-control__select select'>
					<span>GEO</span>
					<MySelect
						onChange={(v) => setSelectedGeo((v as any).value)}
						options={[{ value: 'all', label: 'All' }, ...geoOptions]}
						defaultValue={{ value: 'all', label: 'All' }}
					/>
				</div>
				{/* <div className='promo-mail-control__select select'>
				<span>Landings</span>
				<MySelect options={optionsLandings} defaultValue={optionsLandings[0]} />
			</div> */}
			</div>
		</>
	);
};
