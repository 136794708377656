import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import s from './ErrorMessage.module.scss';

interface ErrorMessageProps {
	message?: string;
	className?: string;
}

const ErrorMessage: React.FC<PropsWithChildren<ErrorMessageProps>> = ({
	className,
	children = 'Error!'
}) => {
	return <div className={classNames(s.error, className)}>{children}</div>;
};

export default ErrorMessage;
