import { useAuthUser } from 'hooks/useAuth';
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CabinetSvgSelector } from '../../../assets/images/icons/CabinetSvgSelector';
import userColor from '../../../assets/images/svg/user_color.svg';
import { CloseIcon } from '../../../assets/svg';
import Language from '../Language/Language';
import NavigationCabinet from '../NavigationCabinet/NavigationCabinet';

import s from './MenuCabinet.module.scss';
import SupportDropdown from './support-dropdown/SupportDropdown';

interface IMenuCabinet {
	toggleMenu: () => void;
	showMenu: boolean;
}

const MenuCabinet: FC<IMenuCabinet> = ({ toggleMenu, showMenu }) => {
	const isMobile = useMediaQuery({ query: '(max-width: 991.98px)' });

	const user = useAuthUser();

	return (
		<>
			<div className={s.menu}>
				<div className={s.content}>
					<div className={s.head}>
						<button className={s.close} onClick={toggleMenu}>
							<CloseIcon />
						</button>
					</div>
					<div className={s.body}>
						<NavigationCabinet className={s.navigation} />
					</div>
					<div className={s.bottom}>
						<SupportDropdown />
					</div>
				</div>

				{!isMobile && (
					<div className='navbar__wrapper'>
						<div className='navbar__control navbar-control'>
							<div className='navbar-control__icons header-control'>
								<Link to='/promo/my/mail' className='header-control__mail'>
									<div className='icon'>
										<CabinetSvgSelector id='mail' />

										<small></small>
									</div>
								</Link>
								<div className='header-control__language language'>
									<Language isCabinet />
								</div>
								<div className='header-control__settings'>
									<Link
										to='/settings/profile'
										className='icon'
										onClick={toggleMenu}
									>
										<CabinetSvgSelector id='settings' />
									</Link>
								</div>
								<div className='header-control__exit'>
									<Link to='/' className='icon' onClick={toggleMenu}>
										<CabinetSvgSelector id='exit' />
									</Link>
								</div>
							</div>
							<div className='navbar-control__info'>
								<div className='header-control__username'>
									<span>User</span>
									{user?.email}
								</div>
								{/* <div className='header-control__balance'>
									<span> Wallet </span>
									100.00 USD
								</div> */}
							</div>
						</div>
					</div>
				)}
			</div>
			<div
				className={showMenu ? 'header__overlay active' : 'header__overlay'}
				onClick={toggleMenu}
			></div>
		</>
	);
};

export default MenuCabinet;
