import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { API_ROUTES } from 'api/routes';
import { TMeta } from 'types';

import { baseQueryWithReauth } from '../interceptor';

export const offersApi = createApi({
	reducerPath: 'offersApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['Offers', 'Landings'],
	endpoints: (builder) => ({
		getOffers: builder.query<TOfferResponse, any>({
			query: (params) => ({
				url: API_ROUTES.GET_OFFERS,
				params
			}),
			providesTags: ['Offers']
		}),

		getLandings: builder.query<TLandingsResponse, TMeta>({
			query: (params) => ({
				url: API_ROUTES.GET_LANDINGS,
				params
			}),
			providesTags: ['Landings']
		})
	})
});

export type TOffer = {
	id: string;
	goal: string;
	geo: string;
	domain: OfferDomains;
	paymentModel: OfferPaymentModel;
	landings: string[];
};

export type TLanding = {
	id: string;
	domain: string;
	language: string;
	imageUrl: string;
};

export type TOfferResponse = { [k: string]: TOffer[] };
export type TLandingsResponse = TLanding[];

export enum OfferPaymentModel {
	REVSHARE = 'revshare',
	CPA = 'cpa'
}

export const OfferPaymentModelLabels = {
	revshare: 'RevShare',
	cpa: 'CPA'
} as { [k: string]: string };

export enum OfferDomains {
	CASINO = 'casino'
}

export const OfferDomainLabels = {
	casino: 'Casino',
	betting: 'Betting',
	referalProgram: 'Referal Program'
} as { [k: string]: string };
