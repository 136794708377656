import React from 'react';
import { Link } from 'react-router-dom';

import image1 from '../../../assets/images/news/image1.jpg';
import BackButtonCabinet from '../../components/BackButtonCabinet/BackButtonCabinet';
import PaginationTableCabinet from '../../components/PaginationTableCabinet/PaginationTableCabinet';
import Datepicker from '../components/Datepicker';

const News = () => {
  const items = [
    {
      link: `/`,
      image: image1,
      title: `Binobi Days Malta`,
      text: `Binobi Days Malta: a party for the TOPs of affliate ma...`,
      date: `11.12.2022`,
    },
    {
      link: `/`,
      image: image1,
      title: `Binobi Days Malta`,
      text: `Binobi Days Malta: a party for the TOPs of affliate ma...`,
      date: `11.12.2022`,
    },
    {
      link: `/`,
      image: image1,
      title: `Binobi Days Malta`,
      text: `Binobi Days Malta: a party for the TOPs of affliate ma...`,
      date: `11.12.2022`,
    },
    {
      link: `/`,
      image: image1,
      title: `Binobi Days Malta`,
      text: `Binobi Days Malta: a party for the TOPs of affliate ma...`,
      date: `11.12.2022`,
    },
    {
      link: `/`,
      image: image1,
      title: `Binobi Days Malta`,
      text: `Binobi Days Malta: a party for the TOPs of affliate ma...`,
      date: `11.12.2022`,
    },
    {
      link: `/`,
      image: image1,
      title: `Binobi Days Malta`,
      text: `Binobi Days Malta: a party for the TOPs of affliate ma...`,
      date: `11.12.2022`,
    },
  ];

  return (
    <div className="news">
      <div className="news__top top">
        <BackButtonCabinet className="top__back" />
        <div className="top__wrapper">
          <div className="top__title title">News</div>
        </div>
      </div>
      <div className="news__wrapper">
        <div className="news__head">
          <label className="news__period">
            <span>Period</span>
            <Datepicker />
          </label>
          <div className="news__sorting">
            <ul>
              <li className="active">
                <a href="#">All</a>
              </li>
              <li>
                <a href="#">#Rate</a>
              </li>
              <li>
                <a href="#">New promo</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="news__body">
          <div className="news__items">
            {items && items.map((item, index) => <NewsItem item={item} key={index} />)}
          </div>
          <PaginationTableCabinet className="news__pagination" />
        </div>
      </div>
    </div>
  );
};

const NewsItem = ({ key, item }) => {
  return (
    <div key={key} className="news__item news-item">
      <Link to={item.link} className="news-item__image">
        <img src={item.image} alt={item.title} />
      </Link>
      <div className="news-item__wrapper">
        <div className="news-item__title">{item.title}</div>
        <div className="news-item__text">{item.text}</div>
        <div className="news-item__inner">
          <div className="news-item__date">{item.date}</div>
          <div className="news-item__button">
            <Link to={item.link} className="btn btn--rounded">
              More
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
