import { CabinetSvgSelector } from 'assets/images/icons/CabinetSvgSelector';
import { FC } from 'react';
import { OfferActionTypeOptions } from 'types/options';

export type TOfferType = { value: string; label: string; imageId: string };

type TSecondStep = {
	setOfferType: (v: TOfferType) => void;
	selectedOfferType: TOfferType | undefined;
};
export const SecondStep: FC<TSecondStep> = ({
	setOfferType,
	selectedOfferType
}) => {
	return (
		<div className='create-promo-steps__section create-promo-section active'>
			<div className='create-promo-section__body'>
				<div className='promo-type'>
					<div className='promo-type__items'>
						{OfferActionTypeOptions.filter(({ value }) => value !== 'all').map(
							({ value, label, imageId }) => (
								<label className='promo-type__item promo-offer-item'>
									<div className='promo-offer-item__content'>
										<div className='promo-offer-item__link'>
											<div className='icon'>
												<CabinetSvgSelector id={imageId} />
											</div>
											{label}
										</div>
									</div>
									<input
										type='radio'
										name='promo-type'
										checked={selectedOfferType?.value === value}
										onClick={() => setOfferType({ value, label, imageId })}
									/>
									<div className='promo-offer-item__selected'>
										<span>Select</span>
									</div>
								</label>
							)
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
