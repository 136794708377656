import AOS from 'aos';
import 'aos/dist/aos.css';
import React from 'react';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

import s from './FaqList.module.scss';

AOS.init({
	once: true
});

const FaqList = () => {
	const { t } = useTranslation();

	const items = [
		{
			id: '0',
			title: t('pages.faq.item1.title'),
			text: t('pages.faq.item1.text')
		},
		{
			id: '1',
			title: t('pages.faq.item2.title'),
			text: t('pages.faq.item2.text')
		},
		{
			id: '2',
			title: t('pages.faq.item3.title'),
			text: t('pages.faq.item3.text')
		},
		{
			id: '3',
			title: t('pages.faq.item4.title'),
			text: t('pages.faq.item4.text')
		},
		{
			id: '4',
			title: t('pages.faq.item5.title'),
			text: t('pages.faq.item5.text')
		},
		{
			id: '5',
			title: t('pages.faq.item6.title'),
			text: t('pages.faq.item6.text')
		},
		{
			id: '6',
			title: t('pages.faq.item7.title'),
			text: t('pages.faq.item7.text')
		},
		{
			id: '7',
			title: t('pages.faq.item8.title'),
			text: t('pages.faq.item8.text')
		},
		{
			id: '8',
			title: t('pages.faq.item9.title'),
			text: t('pages.faq.item9.text')
		}
	];

	return (
		<div className={s.faq}>
			<div className='container'>
				<div className={s.wrapper}>
					<Breadcrumbs name='FAQ' />
					<div className={s.accordion}>
						<Accordion>
							{items &&
								items.map((item) => (
									<Accordion.Item
										data-aos='fade-up'
										data-aos-duration='700'
										key={item.id}
										eventKey={item.id}
									>
										<Accordion.Header>
											<div className='icon'></div>
											{item.title}
										</Accordion.Header>
										<Accordion.Body
											dangerouslySetInnerHTML={{ __html: item.text }}
										></Accordion.Body>
									</Accordion.Item>
								))}
						</Accordion>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FaqList;
