import { FC } from 'react';
import { Link } from 'react-router-dom';

import { CabinetSvgSelector } from '../../../assets/images/icons/CabinetSvgSelector';

type PaginationTableCabinetProps = {
	pages: number;
	className?: string;
	currentPage: number;
	setCurrentPage: (n: number) => void;
};

export const countPages = (count: number, perPage: number) => {
	return Math.ceil(count / perPage);
};

const PaginationTableCabinet: FC<PaginationTableCabinetProps> = ({
	pages,
	className,
	currentPage,
	setCurrentPage
}) => {
	const increasePage = () => {
		setCurrentPage(currentPage + 1);
	};

	const decreasePage = () => {
		setCurrentPage(currentPage - 1);
	};

	return (
		<div className={`${className} pagination `}>
			<div className='pagination__page'>
				Page {currentPage}/{pages}
			</div>
			<nav aria-label='Navigation'>
				<ul>
					<li className={currentPage === 1 ? 'disabled' : ''}>
						<Link to='#' onClick={() => decreasePage()}>
							<div className='icon'>
								<CabinetSvgSelector id='chevron_prev' />
							</div>
						</Link>
					</li>
					<li className='current'>
						<Link to='#'>{currentPage}</Link>
					</li>
					<li className={pages === currentPage ? 'disabled' : ''}>
						<Link to='#' onClick={() => increasePage()}>
							<div className='icon'>
								<CabinetSvgSelector id='chevron_next' />
							</div>
						</Link>
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default PaginationTableCabinet;
