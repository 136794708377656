/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-mixed-operators */
import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from 'api/routes';
import axios from 'axios';
import local from 'hooks/local';
import { baseQueryWithReauth } from 'store/interceptor';
import { TSignIn } from 'types/auth';
import { errorNotification } from 'utils/toasts';

const storage = local;
const storageToken = storage.local<string>('token', { stringify: false });

export const usersApi = createApi({
	reducerPath: 'usersApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['User'],
	endpoints: (builder) => ({
		login: builder.mutation<any, TSignIn>({
			async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
				const ipChecker = await axios.get('https://lumtest.com/echo.json');

				const result = await fetchWithBQ({
					url: API_ROUTES.LOGIN,
					method: 'POST',
					body: {
						..._arg,
						ip: ipChecker.data.ip
					}
				});

				const signInResult = result as any;

				if (signInResult.data.error) {
					errorNotification(signInResult.data.message);
				}

				if (signInResult.error) {
					errorNotification('Something wrong!');
				}

				if (
					!signInResult.error &&
					signInResult.data &&
					'token' in signInResult.data
				) {
					storageToken.set(signInResult.data.token);
				}

				delete signInResult.data.token;

				return signInResult;
			}
		}),
		editProfile: builder.mutation<any, any>({
			query: (params) => ({
				url: `${API_ROUTES.EDIT_PROFILE}`,
				body: params,
				method: 'PATCH'
			}),
			invalidatesTags: ['User']
		})
	})
});

export const { useLoginMutation, useEditProfileMutation } = usersApi;
