import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import {
	useGetPaymentsCredentialsQuery,
	useRemovePaymentMutation,
	useSetActivePaymentMutation
} from 'store/services/personal-partner';

import {
	CheckCircleIcon,
	MoreVerticalIcon,
	TrashIcon
} from '../../../../../../assets/svg';

import s from './WalletItem.module.scss';

export interface IWalletItem {
	logoSrc: string;
	checkText: string;
	statusIcon: JSX.Element;
	statusText: string;
	className?: string;
	paymentId: string;
}

const WalletItem: React.FC<IWalletItem> = ({
	logoSrc,
	checkText,
	statusIcon,
	statusText,
	paymentId,
	className
}) => {
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = useState(false);

	const [removePayment] = useRemovePaymentMutation();
	const { refetch } = useGetPaymentsCredentialsQuery({});
	const [setActivePayment] = useSetActivePaymentMutation();

	const handleSetActivePayment = async () => {
		await setActivePayment(paymentId);
		refetch();
	};

	const handleRemovePayment = async () => {
		await removePayment(paymentId);
		refetch();
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleDropdownToggle = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className={classNames(s['wallets-item'], className)}>
			<div className={s['wallets-item__top-wrap']}>
				<img className={s['wallets-item__logo']} src={logoSrc} alt='Logo' />
				<div className={s['wallet-dropdown']} ref={dropdownRef}>
					<button className={s.burger} onClick={handleDropdownToggle}>
						<MoreVerticalIcon />
					</button>
					{isOpen && (
						<ul className={s['wallet-dropdown__list']}>
							<li className={s['wallet-dropdown__item']}>
								<button
									className={s['wallet-dropdown__item-btn']}
									type='button'
									onClick={() => handleSetActivePayment()}
								>
									<CheckCircleIcon />
									<span>Make it the main wallet</span>
								</button>
							</li>
							<li className={s['wallet-dropdown__item']}>
								<button
									className={s['wallet-dropdown__item-btn']}
									type='button'
									onClick={() => handleRemovePayment()}
								>
									<TrashIcon />
									<span>Delete</span>
								</button>
							</li>
						</ul>
					)}
				</div>
			</div>
			<div className={s['wallets-item__check']}>{checkText}</div>
			<div className={s['wallets-item__status-wrap']}>
				<div className={s['wallets-item__status-img']}>{statusIcon}</div>
				<div className={s['wallets-item__status-text']}>{statusText}</div>
			</div>
		</div>
	);
};

export default WalletItem;
