import React from 'react';
import { SVGProps } from 'react';

const MoreVerticalIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={400}
		height={400}
		viewBox='0 0 400 400'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M240 60C240 49.3913 235.786 39.2172 228.284 31.7157C220.783 24.2143 210.609 20 200 20C189.391 20 179.217 24.2143 171.716 31.7157C164.214 39.2172 160 49.3913 160 60C160 70.6087 164.214 80.7828 171.716 88.2843C179.217 95.7857 189.391 100 200 100C210.609 100 220.783 95.7857 228.284 88.2843C235.786 80.7828 240 70.6087 240 60ZM200 160C210.609 160 220.783 164.214 228.284 171.716C235.786 179.217 240 189.391 240 200C240 210.609 235.786 220.783 228.284 228.284C220.783 235.786 210.609 240 200 240C189.391 240 179.217 235.786 171.716 228.284C164.214 220.783 160 210.609 160 200C160 189.391 164.214 179.217 171.716 171.716C179.217 164.214 189.391 160 200 160ZM200 300C210.609 300 220.783 304.214 228.284 311.716C235.786 319.217 240 329.391 240 340C240 350.609 235.786 360.783 228.284 368.284C220.783 375.786 210.609 380 200 380C189.391 380 179.217 375.786 171.716 368.284C164.214 360.783 160 350.609 160 340C160 329.391 164.214 319.217 171.716 311.716C179.217 304.214 189.391 300 200 300Z'
		/>
	</svg>
);
export default MoreVerticalIcon;
