import React from 'react';
import Select, { Props as SelectProps } from 'react-select';

type MySelectProps<T> = {
	options: any;
	placeholder?: string;
	defaultValue?: any;
	type?: string;
} & SelectProps<T>;

const MySelect = <T extends {}>({
	options,
	placeholder,
	defaultValue,
	type,
	...props
}: MySelectProps<T>) => {
	return (
		<Select
			defaultValue={defaultValue}
			placeholder={placeholder}
			options={options}
			{...props}
			className={`react-select-container select-select2 ${type}`}
			classNamePrefix='react-select'
		/>
	);
};

export default MySelect;
