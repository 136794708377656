import classNames from 'classnames';
import React, { Suspense } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Outlet } from 'react-router-dom';

import useScrollBlock from '../../../hooks/useScrollBlock';
import NavigationContent from '../../Cabinet/Statistics/components/NavigationContent';
import Footer from '../../components/Footer/Footer';
import HeaderCabinet from '../../components/HeaderCabinet/HeaderCabinet';
import MenuCabinet from '../../components/MenuCabinet/MenuCabinet';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import s from '../CabinetLayout/CabinetLayout.module.scss';

const TabsLayout = ({ navigation }: { navigation: any }) => {
	const isMobile = useMediaQuery({ query: '(max-width: 991.98px)' });
	const [showNavbar, setShowNavbar] = React.useState(false);
	const [showMenu, setShowMenu] = React.useState(false);
	const [blockScroll, allowScroll] = useScrollBlock();
	const toggleNavbar = () => {
		setShowNavbar(!showNavbar);

		if (isMobile) {
			!showNavbar ? blockScroll() : allowScroll();
		}
	};
	const toggleMenu = () => {
		setShowMenu(!showMenu);

		if (isMobile) {
			!showNavbar ? blockScroll() : allowScroll();
		}
	};

	return (
		<ScrollToTop>
			<HeaderCabinet
				showMenu={showMenu}
				toggleMenu={toggleMenu}
				showNavbar={showNavbar}
				toggleNavbar={toggleNavbar}
			/>
			<main className={classNames(s.main, showMenu && s.open)}>
				<div className='container-full'>
					<div className='wrapper'>
						<div className='content'>
							{/* <NavigationContent navigation={navigation} /> */}
							<Suspense fallback='...'>
								<Outlet />
							</Suspense>
						</div>
					</div>
				</div>
			</main>
			<Footer isCabinet />
			{showMenu && <MenuCabinet showMenu={showMenu} toggleMenu={toggleMenu} />}
		</ScrollToTop>
	);
};

export default TabsLayout;
