import React from 'react';
import BackButtonCabinet from '../../components/BackButtonCabinet/BackButtonCabinet';
import MySelect from '../../components/MySelect';
import PaginationTableCabinet from '../../components/PaginationTableCabinet/PaginationTableCabinet';
import Datepicker from '../components/Datepicker';

const Notifications = () => {
  const optionsStatus = [
    { value: 'All', label: 'All' },
    { value: 'CasinoCPAInd', label: 'Casino CPA Ind...' },
    { value: 'SportCPAKZlic', label: 'Sport CPA KZ lic...' },
  ];

  const optionsPriority = [
    { value: 'All', label: 'All' },
    { value: 'DirectLink', label: 'Direct link' },
    { value: 'Banners', label: 'Banners' },
    { value: 'Landings', label: 'Landings' },
    { value: 'ThemedLandings', label: 'Themed landings' },
    { value: 'IframeGames', label: 'Iframe games' },
    { value: 'EventLanding', label: 'Event Landing P...' },
    { value: 'Videos', label: 'Videos' },
  ];

  return (
    <div className="notifications">
      <div className="notifications__top top">
        <BackButtonCabinet className="top__back" />
        <div className="top__wrapper">
          <div className="top__title title">Notifications</div>
          <div className="notifications__filter notifications-filter">
            <label className="notifications-filter__period select">
              <span>Period:</span>

              <Datepicker />
            </label>
            <div className="notifications-filter__select select">
              <span>Status:</span>
              <MySelect options={optionsStatus} />
            </div>
            <div className="notifications-filter__select select">
              <span>Priority:</span>
              <MySelect options={optionsPriority} />
            </div>
          </div>
        </div>
      </div>
      <div className="notifications__wrapper">
        <div className="notifications__table table" data-simplebar>
          <table>
            <thead>
              <tr>
                <td>Date</td>
                <td>Topic</td>
                <td>Comment</td>
                <td>Status</td>
                <td>Priority</td>
                <td>Settings</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>01.09.2022</td>
                <td>Text</td>
                <td>Text</td>
                <td>New</td>
                <td>Low</td>
                <td>-</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <PaginationTableCabinet className="notifications__pagination" />
      </div>
    </div>
  );
};

export default Notifications;
