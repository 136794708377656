import AOS from 'aos';
import 'aos/dist/aos.css';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Parallax } from 'react-scroll-parallax';

import background from '../../../assets/images/hero/background.png';
import backgroundMoney from '../../../assets/images/hero/background.svg';
import backgroundMoneyMobile from '../../../assets/images/hero/background_mobile.png';
import backgroundMobile from '../../../assets/images/hero/background_mobile.svg';
import image from '../../../assets/images/hero/image.png';
import itemsLine from '../../../assets/images/hero/lines_items.svg';
import logo from '../../../assets/images/logo.svg';
import { Button } from '../../components/Button/Button';
import ModalLogin from '../../components/modals/ModalLogin/ModalLogin';
import ModalRegistration from '../../components/modals/ModalRegistration/ModalRegistration';
import ModalThanks from '../../components/modals/ModalThanks/ModalThanks';

import s from './Hero.module.scss';

AOS.init({
	once: true
});

const Hero = () => {
	const { t } = useTranslation();

	const [showRegistrationModal, setShowRegistrationModal] = useState(false);
	const [showLoginModal, setShowLoginModal] = useState(false);
	const [showThanksModal, setShowThanksModal] = useState(false);

	const isMobile = useMediaQuery({ query: '(max-width: 767.98px)' });

	return (
		<>
			<div className={s.hero}>
				<div className={s.background}>
					{!isMobile ? (
						<div className={s.background__image}>
							<Parallax speed={10}>
								<img src={background} alt='' />
							</Parallax>
						</div>
					) : (
						<div className={s.background__image}>
							<Parallax speed={10}>
								<img src={backgroundMoneyMobile} alt='' />
							</Parallax>
						</div>
					)}
					{!isMobile ? (
						<div className={s.background__money}>
							<Parallax speed={-10}>
								<img src={backgroundMoney} alt='' />
							</Parallax>
						</div>
					) : (
						<div className={s.background__money}>
							<Parallax speed={-10}>
								<img src={backgroundMobile} alt='' />
							</Parallax>
						</div>
					)}
				</div>
				<div className='container'>
					<div className={s.wrapper}>
						<div className={s.inner}>
							<div
								data-aos='fade-up'
								data-aos-duration='900'
								className={s.logo}
							>
								<div className={s.logo__image}>
									<img src={logo} alt='' />
								</div>
								<span>Partners</span>
							</div>
							<div
								data-aos='fade-up'
								data-aos-duration='900'
								data-aos-delay='200'
								className={s.text}
							>
								{t('pages.home.hero.title')}
							</div>
							<div
								data-aos='fade-up'
								data-aos-duration='900'
								data-aos-delay='400'
								className={s.button}
							>
								<Button onClick={() => setShowLoginModal(true)}>
									{t('buttonJoin')}
								</Button>
							</div>

							<div
								data-aos='fade-up'
								data-aos-duration='900'
								data-aos-delay='600'
								className={classNames(s.image, 'iresponsive')}
							>
								<img src={image} alt='' />
							</div>
						</div>

						<div className={s.items}>
							<div className={s.items__line}>
								<img src={itemsLine} alt='' />
							</div>
							<ul>
								<li data-aos='zoom-in' data-aos-duration='900'>
									<span>{t('pages.home.hero.items.item1.title')}</span>
									<strong>{t('pages.home.hero.items.item1.text')}</strong>
								</li>
								<li data-aos='zoom-in' data-aos-duration='900'>
									<span>{t('pages.home.hero.items.item2.title')}</span>
									<strong>{t('pages.home.hero.items.item2.text')}</strong>
								</li>
								<li data-aos='zoom-in' data-aos-duration='900'>
									<span>{t('pages.home.hero.items.item3.title')}</span>
									<strong>{t('pages.home.hero.items.item3.text')}</strong>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<ModalRegistration
				showRegistrationModal={showRegistrationModal}
				setShowRegistrationModal={setShowRegistrationModal}
				setShowLoginModal={setShowLoginModal}
				setShowThanksModal={setShowThanksModal}
			/>
			<ModalLogin
				showLoginModal={showLoginModal}
				setShowLoginModal={setShowLoginModal}
				setShowRegistrationModal={setShowRegistrationModal}
			/>
			<ModalThanks
				showThanksModal={showThanksModal}
				setShowThanksModal={setShowThanksModal}
			/>
		</>
	);
};

export default Hero;
