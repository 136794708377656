// import { Element } from 'react-scroll';
import { ParallaxProvider } from 'react-scroll-parallax';

import Contacts from '../Home/Contacts/Contacts';

import Hero from './Hero/Hero';
import Info from './Info/Info';

const InfoPage = () => {
	return (
		<ParallaxProvider>
			<Hero />
			<Info />
		</ParallaxProvider>
	);
};

export default InfoPage;
