import { getUserSessions } from 'api/requests/auth';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import BaseTable from '../../../components/BaseTable';

const headers = ['Date', 'Device', 'GEO', 'IP', 'STATUS'];
export type ISession = {
	id: string;
	ip: string;
	geo: string;
	browser: string;
	updatedAt: string;
	createdAt: string;
	currentSession?: boolean;
	admin: boolean;
};

const Logs = () => {
	const [data, setData] = useState([]);

	const getData = async () => {
		const sessions = await getUserSessions();

		const filteredData = sessions.filter((i: ISession) => !i.admin);
		const newData = filteredData.map((item: ISession) => {
			const session = [
				dayjs(new Date(item?.updatedAt)).format('YYYY-MM-DD hh-mm-ss'),
				item.browser,
				item.geo,
				item.ip
			];
			if (item.currentSession) {
				session.push('ACTIVE');
			} else {
				session.push('-');
			}

			return session;
		});

		setData(newData);
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<div className='main-settings'>
			<div className='main-settings__sections'>
				<div className='main-settings__section main-settings-section'>
					<div className='main-settings-section__content'>
						<div className='main-settings-section__table table' data-simplebar>
							<BaseTable headers={headers} rows={data} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Logs;
