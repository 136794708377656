import CheckboxCabinet from 'pages/components/CheckboxCabinet/CheckboxCabinet';
import ErrorMessage from 'pages/components/ErrorMessage/ErrorMessage';
import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';

import { CloseIcon } from '../../../../assets/svg';
import { Button } from '../../Button/Button';
import Dropdown, { IDropdownOption } from '../../Dropdown/Dropdown';
import Input from '../../Input/Input';
import s from '../ModalLogin/ModalLogin.module.scss';

interface ICreateTicketModal {
	showCreateTicketModal: boolean;
	setShowCreateTicketModal: (show: boolean) => void;
}

interface CreateTicketFormData {
	subject: string;
	message: string;
}

const CreateTicketModal: FC<ICreateTicketModal> = ({
	showCreateTicketModal,
	setShowCreateTicketModal
}) => {
	const {
		register,
		handleSubmit,
		control,
		formState: { errors }
	} = useForm<CreateTicketFormData>();

	const onSubmit = (data: CreateTicketFormData) => {
		console.log(data);
		setShowCreateTicketModal(false);
	};

	const optionsCategory: IDropdownOption[] = [
		{
			value: 'general',
			label: 'General questions'
		},
		{
			value: 'request',
			label: 'Offer enabling request'
		},
		{
			value: 'technical',
			label: 'Technical error'
		},
		{
			value: 'suggestion',
			label: 'Feedback'
		}
	];

	return (
		<Modal
			show={showCreateTicketModal}
			dialogClassName={s.modal}
			onHide={() => setShowCreateTicketModal(false)}
			centered
		>
			<div className={s.inner}>
				<div
					className={s.close}
					onClick={() => setShowCreateTicketModal(false)}
				>
					<CloseIcon />
				</div>
				<div className={s.wrapper}>
					<div className={s.authTabs}>
						<h2 className={s.title}>Create ticket</h2>
					</div>
					<form className={s.form} onSubmit={handleSubmit(onSubmit)}>
						<div className={s.inputs}>
							<Dropdown
								className={s.dropdown}
								triggerClassName={s.dropdown__trigger}
								options={optionsCategory}
								placeholder='Category'
							/>

							<Input
								placeholder='Subject'
								name='subjects'
								register={register}
								validationSchema={{
									required: 'Subject is required'
								}}
							/>
							{errors.subject && (
								<ErrorMessage>{errors.subject.message}</ErrorMessage>
							)}
							<Controller
								name='message'
								control={control}
								rules={{ required: true }}
								render={({ field: { onChange, value } }) => (
									<Input
										textarea={
											<textarea
												placeholder='Message'
												onChange={onChange}
												value={value}
											/>
										}
									/>
								)}
							/>
							{errors.message && (
								<ErrorMessage>{errors.message.message}</ErrorMessage>
							)}
						</div>
						<div className={s.button}>
							<Button type='submit'>Create</Button>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default CreateTicketModal;
