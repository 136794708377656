import React from 'react';
import { CabinetSvgSelector } from '../../../assets/images/icons/CabinetSvgSelector';
import BackButtonCabinet from '../../components/BackButtonCabinet/BackButtonCabinet';

import topImage from '../../../assets/images/promo/personal/top.png';
import leftImage from '../../../assets/images/promo/personal/left.png';
import centerImage from '../../../assets/images/promo/personal/center.png';
import rightImage from '../../../assets/images/promo/personal/right.png';
import MySelect from '../../components/MySelect';

const PersonalPromo = () => {
  const optionsType = [
    { value: 'All', label: 'All' },
    { value: 'Banners', label: 'Banners' },
    { value: 'DirectLink', label: 'Direct link' },
    { value: 'Landings', label: 'Landings' },
    { value: 'ThemedLandings', label: 'Themed landings' },
    { value: 'EventLanding', label: 'Event Landing Page' },
  ];
  const optionsLanguage = [
    { value: 'English', label: 'English' },
    { value: 'Ukraine', label: 'Ukrainian' },
    { value: 'Russian', label: 'Russian' },
  ];
  const optionsCurrency = [
    { value: 'USD', label: 'USD' },
    { value: 'EURO', label: 'EURO' },
    { value: 'UAH', label: 'UAH' },
    { value: 'RUB', label: 'RUB' },
  ];

  return (
    <div className="personal-promo">
      <BackButtonCabinet className="personal-promo__back" />
      <div className="personal-promo__content">
        <div className="personal-promo__title title-section">Personal promo</div>
        <div className="personal-promo__form personal-promo-form">
          <form action="#" method="post">
            <div className="personal-promo-form__content">
              <div className="personal-promo-form__items">
                <div className="personal-promo-form__item select">
                  <MySelect options={optionsType} placeholder="Promo Type" type="input-select" />
                </div>
                <div className="personal-promo-form__item select">
                  <MySelect
                    options={optionsLanguage}
                    placeholder="Promo Language"
                    type="input-select"
                  />
                </div>
                <div className="personal-promo-form__item select">
                  <MySelect
                    options={optionsCurrency}
                    placeholder="Promo Currency"
                    type="input-select"
                  />
                </div>
                <label className="personal-promo-form__item input">
                  <div className="input__field">
                    <textarea placeholder="Promo Description"></textarea>
                  </div>
                </label>
                <label className="personal-promo-form__item input-file">
                  <input type="file" />
                  <div className="input-file__label">Personal Promo example</div>
                  <div className="input-file__icon icon">
                    <CabinetSvgSelector id="download" />
                  </div>
                </label>
              </div>
              <div className="personal-promo-form__button">
                <button type="submit" className="btn">
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="personal-promo__bg personal-promo__bg--top">
        <img src={topImage} alt="" />
      </div>
      <div className="personal-promo__bg personal-promo__bg--left">
        <img src={leftImage} alt="" />
      </div>
      <div className="personal-promo__bg personal-promo__bg--center">
        <img src={centerImage} alt="" />
      </div>
      <div className="personal-promo__bg personal-promo__bg--right">
        <img src={rightImage} alt="" />
      </div>
    </div>
  );
};

export default PersonalPromo;
