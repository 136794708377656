/* eslint-disable jsx-a11y/anchor-is-valid */
import { useDebouncedState } from '@mantine/hooks';
import { CabinetSvgSelector } from 'assets/images/icons/CabinetSvgSelector';
import { useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
	OfferPaymentModel,
	OfferPaymentModelLabels
} from 'store/services/offers';
import { TTraffic, useGetStatisticOnPromoQuery } from 'store/services/promos';
import { calculateTrafficTotal } from 'utils/calculate';
import { copy } from 'utils/copy';
import { countries, getLanguageIcon } from 'utils/getLanguageIcon';

import { PromoFilters } from './PromoFilters';

const StatisticRecord = ({ traffic }: { traffic: TTraffic }) => (
	<tr
		style={{
			borderBottom: '1px solid  #363636'
		}}
	>
		<td>{traffic.date}</td>
		<td>{traffic.clicks}</td>
		<td>{traffic.registrations}</td>
		<td>{traffic.fdp}</td>
		<td>{traffic.rdp}</td>
		<td>{traffic.betSum}</td>
		<td>{traffic.winSum}</td>
		<td>{traffic.loseSum}</td>
	</tr>
);

const GroupedStatisticRecord = ({
	traffics,
	campaign
}: {
	traffics: TTraffic[];
	campaign: any;
}) => {
	const total = useMemo(() => calculateTrafficTotal(traffics), [traffics]);

	console.log(campaign);

	const [isHide, setIsHide] = useState(true);

	return (
		<tr
			style={{
				marginTop: '5px',
				borderBottom: '7px solid  #363636',
				...(isHide && { height: `30px`, lineHeight: 0, minHeight: 0 })
			}}
		>
			<td>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '15px'
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '25px'
						}}
					>
						<div>{campaign.nanoId}</div>
					</div>

					{!isHide && (
						<>
							{campaign.offer ? (
								<>
									<img
										style={{
											width: '30px',
											height: '30px',
											margin: '0 auto',
											borderRadius: '15px'
										}}
										src={
											countries.find(
												({ value }) => campaign?.offer?.geo === value
											)?.icon
										}
										alt={campaign?.offer?.geo}
									/>

									<div>{campaign?.offer?.domain}</div>

									<div>
										{OfferPaymentModelLabels[campaign?.offer?.paymentModel] ||
											'Offer not set'}
									</div>
								</>
							) : (
								<>
									<img
										style={{
											width: '30px',
											height: '30px',
											margin: '0 auto',
											borderRadius: '15px'
										}}
										src={
											countries.find(({ value }) => campaign?.geo === value)
												?.icon
										}
										alt={campaign?.geo}
									/>

									<div>
										{OfferPaymentModelLabels[campaign?.offer?.paymentModel] ||
											'Offer not set'}
									</div>
								</>
							)}

							<div
								onClick={() => setIsHide(!isHide)}
								style={{
									cursor: 'pointer',
									margin: '0 auto',
									height: '30px',
									width: '30px',
									color: 'white',
									fill: 'whitesmoke'
								}}
							>
								<CabinetSvgSelector
									id={!isHide ? 'chevron_up' : 'chevron_down'}
								/>
							</div>
						</>
					)}
				</div>
			</td>
			<table
				style={{
					width: '100%',
					tableLayout: 'fixed',
					position: 'relative'
				}}
			>
				{campaign?.offer ? (
					<>
						{(campaign.approved && campaign?.cpa) ||
						(campaign.approved &&
							campaign?.offer?.paymentModel === OfferPaymentModel.REVSHARE) ? (
							<>
								{isHide && (
									<div
										onClick={() => setIsHide(!isHide)}
										style={{
											cursor: 'pointer',
											position: 'absolute',
											height: '30px',
											width: '30px',
											left: 'calc(50% - 15px)',
											top: '15px',
											color: 'white',
											fill: 'whitesmoke'
										}}
									>
										<CabinetSvgSelector
											id={!isHide ? 'chevron_up' : 'chevron_down'}
										/>
									</div>
								)}
							</>
						) : (
							<div
								style={{
									position: 'absolute',
									height: '30px',
									left: 'calc(45%)',
									top: '15px',
									color: 'white'
								}}
							>
								{!campaign?.cpa &&
								campaign?.offer?.paymentModel === OfferPaymentModel.CPA
									? 'The promo expects a cpa'
									: 'Promo is not approved'}
							</div>
						)}
					</>
				) : (
					<>
						{isHide && (
							<div
								onClick={() => setIsHide(!isHide)}
								style={{
									cursor: 'pointer',
									position: 'absolute',
									height: '30px',
									width: '30px',
									left: 'calc(50% - 15px)',
									top: '15px',
									color: 'white',
									fill: 'whitesmoke'
								}}
							>
								<CabinetSvgSelector
									id={!isHide ? 'chevron_up' : 'chevron_down'}
								/>
							</div>
						)}
					</>
				)}

				{!isHide && (
					<>
						<thead
							style={{ width: '100%', borderBottom: '1px solid  #363636' }}
						>
							<td>Date</td>
							<td>Clicks</td>
							<td>Registrations</td>
							<td>First Deposit</td>
							<td>Repeat Deposit</td>
							<td>Wagger Sum</td>
							<td>Win Sum</td>
							<td>Lose Sum</td>
						</thead>

						<tbody>
							{traffics.map((traffic) => (
								<StatisticRecord traffic={traffic} />
							))}
						</tbody>

						<tfoot>
							<tr style={{ borderTop: '4px solid #363636' }}>
								<td></td>
								<td>{total.clicks}</td>
								<td>{total.registrations}</td>
								<td>{total.fdp}</td>
								<td>{total.rdp}</td>
								<td>{total.betSum}</td>
								<td>{total.winSum}</td>
								<td>{total.loseSum}</td>
							</tr>
						</tfoot>
					</>
				)}
			</table>
		</tr>
	);
};

const MyPromoStatistic = () => {
	const [searchParams] = useSearchParams();
	const promoId = searchParams.get('promoId');

	const [filters, setFilters] = useDebouncedState({}, 500);

	const { data: statistic, isLoading } = useGetStatisticOnPromoQuery({
		promoId: promoId || 'all',
		...filters
	});

	return (
		<div className='my-promo my-promo-mail'>
			{promoId && (
				<div className='my-promo-mail__space'>
					<div className='my-promo-mail__promoId' onClick={() => copy(promoId)}>
						Promo: <strong>{promoId}</strong>
					</div>

					<Link
						to={`/promo/my?promoId=${promoId}`}
						className='my-promo-mail__promoId'
					>
						To promo
					</Link>
				</div>
			)}

			<PromoFilters setFilters={setFilters} />
			<div className='my-promo-mail__body'>
				<div className='main-settings-section__table table' data-simplebar>
					<table>
						<thead>
							{!promoId ? (
								<>
									<td>Promo</td>
									<td>Statistic</td>
								</>
							) : (
								<>
									<td>Date</td>
									<td>Clicks</td>
									<td>Registrations</td>
									<td>First Deposit</td>
									<td>Repeat Deposit</td>
									<td>Wagger Sum</td>
									<td>Win Sum</td>
									<td>Lose Sum</td>
								</>
							)}
						</thead>

						{/* MAIN FOR BODY */}
						<tbody>
							{isLoading ? (
								'LOADING'
							) : (
								<>
									{statistic?.traffics ? (
										<>
											{promoId ? (
												<>
													{statistic.traffics.map((traffic) => (
														<StatisticRecord traffic={traffic} />
													))}
												</>
											) : (
												<>
													{statistic?.groupedByCampaignId ? (
														Object.keys(statistic?.groupedByCampaignId).map(
															(group) =>
																statistic.groupedByCampaignId && (
																	<GroupedStatisticRecord
																		traffics={
																			statistic.groupedByCampaignId[group]
																		}
																		campaign={statistic.campaigns?.find(
																			({ id }) => id === group
																		)}
																	/>
																)
														)
													) : (
														<>
															{[].map((traffic) => (
																<StatisticRecord traffic={traffic} />
															))}
														</>
													)}
												</>
											)}
										</>
									) : (
										<tr>
											{!promoId && <td> </td>}

											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
										</tr>
									)}
								</>
							)}
						</tbody>

						{/* TOTAL FOR ONE PROMO STATISTIC */}
						{promoId && (
							<tfoot>
								<tr style={{ borderTop: '4px solid #363636' }}>
									<>
										<td>Total</td>

										<td>{statistic?.total.clicks}</td>
										<td>{statistic?.total.registrations}</td>
										<td>{statistic?.total.fdp}</td>
										<td>{statistic?.total.rdp}</td>
										<td>{statistic?.total.betSum}</td>
										<td>{statistic?.total.winSum}</td>
										<td>{statistic?.total.loseSum}</td>
									</>
								</tr>
							</tfoot>
						)}
					</table>
				</div>

				{/* FOOTER WITH TOTAL INFO  */}
				{/* FOOTER WITH TOTAL INFO  */}
				{/* FOOTER WITH TOTAL INFO  */}

				<div
					className='main-settings-section__table table table_title'
					data-simplebar
					style={{ marginTop: '30px', width: '100%' }}
				>
					{!promoId && (
						<table
							className='main-settings-section__table'
							style={{ width: '100%', tableLayout: 'fixed' }}
						>
							<thead className=''>
								<td style={{ width: '95px' }}></td>
								<td></td>
								<td>Clicks</td>
								<td>Registrations</td>
								<td>First Deposit</td>
								<td>Repeat Deposit</td>
								<td>Wagger Sum</td>
								<td>Win Sum</td>
								<td>Lose Sum</td>
							</thead>
							<tfoot>
								<tr style={{ borderTop: '4px solid #363636', fontWeight: 400 }}>
									<td>Total</td>
									<td></td>

									<td>{statistic?.total.clicks}</td>
									<td>{statistic?.total.registrations}</td>
									<td>{statistic?.total.fdp}</td>
									<td>{statistic?.total.rdp}</td>
									<td>{statistic?.total.betSum}</td>
									<td>{statistic?.total.winSum}</td>
									<td>{statistic?.total.loseSum}</td>
								</tr>
							</tfoot>
						</table>
					)}
				</div>
			</div>
		</div>
	);
};

export default MyPromoStatistic;
