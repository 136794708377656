import classNames from 'classnames';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { DocumentIcon, HandshakeIcon } from '../../../assets/svg';

import FaqSettings from './components/Faq';
import Logs from './components/Logs';
import Postback from './components/Postback';
import UserProfile from './components/user-profile/UserProfile';
import ProfileWallets from './components/wallets/ProfileWallets';
import s from './index.module.scss';

interface ITab {
	id: string;
	trigger: string;
	content: JSX.Element;
}

type LinkProps = {
	href: string;
	label: string;
	icon: JSX.Element;
};

const Profile: FC = () => {
	const tabs: ITab[] = [
		{
			id: '0',
			trigger: 'Last logins',
			content: <Logs />
		},
		{
			id: '1',
			trigger: 'Postback settings',
			content: <Postback />
		},
		{
			id: '2',
			trigger: 'FAQ',
			content: <FaqSettings />
		}
	];

	const links: LinkProps[] = [
		{
			href: 'documentation',
			label: 'Documentation',
			icon: <DocumentIcon />
		},
		{
			href: 'affiliate-agreement',
			label: 'Affiliate Agreement',
			icon: <HandshakeIcon />
		}
	];

	return (
		<div className={s.wrapper}>
			<h3 className={s.title}>Profile</h3>
			<div className={s.page}>
				<div className={s.inner}>
					<UserProfile />

					<div className={s.links}>
						<ul>
							{links.map((link) => (
								<li key={link.href}>
									<Link to={link.href}>
										{link.icon} {link.label}
									</Link>
								</li>
							))}
						</ul>
					</div>
				</div>
				<div className={s.content}>
					<ProfileWallets className={s.wallet} />
					<Tabs className={s.tabs}>
						<div className={classNames(s.nav, 'settings-nav')}>
							<TabList className={s.tablist}>
								{tabs.map((tab) => (
									<Tab className={s.tab} key={tab.id}>
										<button>{tab.trigger}</button>
									</Tab>
								))}
							</TabList>
						</div>

						{tabs.map((tab) => (
							<TabPanel key={tab.id}>{tab.content}</TabPanel>
						))}
					</Tabs>
				</div>
			</div>
		</div>
	);
};

export default Profile;
