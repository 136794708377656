import AOS from 'aos';
import 'aos/dist/aos.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Parallax } from 'react-scroll-parallax';

import logo from '../../../assets/images/logo.svg';
import imageBg from '../../../assets/images/stats/image.png';
import image from '../../../assets/images/stats/items/Icon-01.svg';
import image2 from '../../../assets/images/stats/items/Icon-02.svg';
import image3 from '../../../assets/images/stats/items/Icon-03.svg';
import image4 from '../../../assets/images/stats/items/Icon-04.svg';
import image5 from '../../../assets/images/stats/items/Icon-05.svg';
import image6 from '../../../assets/images/stats/items/Icon-06.svg';
import image7 from '../../../assets/images/stats/items/Icon-07.svg';
import image8 from '../../../assets/images/stats/items/Icon-08.svg';
import image9 from '../../../assets/images/stats/items/Icon-09.svg';
import image10 from '../../../assets/images/stats/items/Icon-10.svg';
import image11 from '../../../assets/images/stats/items/Icon-10.svg';
import image12 from '../../../assets/images/stats/items/Icon-12.svg';
import money from '../../../assets/images/stats/money.png';
import moneyMobile from '../../../assets/images/stats/money_mobile.png';

import s from './Stats.module.scss';

AOS.init({
	once: true
});

const Stats = () => {
	const { t } = useTranslation();

	const isMobile = useMediaQuery({ query: '(max-width: 767.98px)' });

	const items = [
		{
			image: image,
			text: t('pages.home.stats.item1.list.item1')
		},
		{
			image: image2,
			text: t('pages.home.stats.item1.list.item2')
		},
		{
			image: image3,
			text: t('pages.home.stats.item1.list.item3')
		},
		{
			image: image4,
			text: t('pages.home.stats.item1.list.item4')
		},
		{
			image: image5,
			text: t('pages.home.stats.item1.list.item5')
		},
		{
			image: image6,
			text: t('pages.home.stats.item1.list.item6')
		}
	];
	const items2 = [
		{
			image: image7,
			text: t('pages.home.stats.item2.list.item1')
		},
		{
			image: image8,
			text: t('pages.home.stats.item2.list.item2')
		},
		{
			image: image9,
			text: t('pages.home.stats.item2.list.item3')
		},
		{
			image: image10,
			text: t('pages.home.stats.item2.list.item4')
		},
		{
			image: image11,
			text: t('pages.home.stats.item2.list.item5')
		},
		{
			image: image12,
			text: t('pages.home.stats.item2.list.item6')
		}
	];

	return (
		<div className={s.stats}>
			{isMobile && (
				<div className={s.money_mobile}>
					<img src={moneyMobile} alt='' />
				</div>
			)}
			<div className='container'>
				<div className={s.wrapper}>
					<div className={s.columns}>
						<div className={s.column}>
							<div className={s.head}>
								<div
									data-aos='fade-up'
									data-aos-duration='700'
									className={s.logo}
								>
									<div className={s.logo__image}>
										<img src={logo} alt='' />
									</div>
									<span>CASINO</span>
								</div>
								<div
									data-aos='fade-up'
									data-aos-duration='700'
									className={s.text}
								>
									{t('pages.home.stats.item1.text')}
								</div>
							</div>
							<div
								data-aos='fade-up'
								data-aos-duration='700'
								className={s.items}
							>
								<ul>
									{items &&
										items.map((item, index) => (
											<li key={index}>
												<div className={s.items__image}>
													<img src={item.image} alt={item.text} />
												</div>
												<div className={s.items__content}>
													<span>{item.text}</span>
												</div>
											</li>
										))}
								</ul>
							</div>
						</div>
						<div className={s.money}>
							<Parallax speed={-5}>
								<img src={money} alt='' />
							</Parallax>
						</div>
						<div className={s.column}>
							<div className={s.head}>
								<div
									data-aos='fade-up'
									data-aos-duration='700'
									className={s.logo}
								>
									<div className={s.logo__image}>
										<img src={logo} alt='' />
									</div>
									<span>
										BET <small>SOON</small>
									</span>
								</div>
								<div
									data-aos='fade-up'
									data-aos-duration='700'
									className={s.text}
								>
									{t('pages.home.stats.item2.text')}
								</div>
							</div>
							<div
								data-aos='fade-up'
								data-aos-duration='700'
								className={s.items}
							>
								<ul>
									{items2 &&
										items2.map((item, index) => (
											<li key={index}>
												<div className={s.items__image}>
													<img src={item.image} alt={item.text} />
												</div>
												<div className={s.items__content}>
													<span>{item.text}</span>
												</div>
											</li>
										))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div data-aos='fade' data-aos-duration='700' className={s.image}>
				<img src={imageBg} alt='' />

				{/* <div data-aos="fade" data-aos-duration="700" className={s.image__inner}>
          <img src={imageGirls} alt="" />
        </div> */}
			</div>
		</div>
	);
};

export default Stats;
