import React from 'react';
import { Button } from '../../Button/Button';

import thanks from '../../../../assets/images/modals/thanks.png';

import s from './ModalThanks.module.scss';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ModalThanks = ({ showThanksModal, setShowThanksModal }) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={showThanksModal}
      dialogClassName="modal-thanks"
      onHide={() => setShowThanksModal(false)}
      centered>
      <div className={s.modal}>
        <div className={s.wrapper}>
          <div className={s.image}>
            <img src={thanks} alt="" />
          </div>

          <div className={s.title}>{t('modals.modalThanks.title')}</div>
          <div className={s.text}>{t('modals.modalThanks.text')}</div>
          <div className={s.button}>
            <Button onClick={() => setShowThanksModal(false)}>
              {t('modals.modalThanks.button')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalThanks;
