import ErrorMessage from 'pages/components/ErrorMessage/ErrorMessage';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from 'store/services/users';
import { setCredentials } from 'store/slices/auth';

import banner from '../../../../assets/images/modals/banner.png';
import image_sign2 from '../../../../assets/images/modals/image_sign2.png';
import { CloseIcon, ShieldIcon } from '../../../../assets/svg';
import { Button } from '../../Button/Button';
import Input from '../../Input/Input';

import s from './ModalLogin.module.scss';

type FormData = {
	email: string;
	password: string;
	twoFactorAuthCode?: string;
};

const ModalLogin = ({
	showLoginModal,
	setShowLoginModal,
	setShowRegistrationModal
}: {
	showLoginModal: boolean;
	setShowLoginModal: (show: boolean) => void;
	setShowRegistrationModal: (show: boolean) => void;
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [login, { isLoading }] = useLoginMutation();

	const isMobile = useMediaQuery({ query: '(max-width: 767.98px)' });
	const { t } = useTranslation();
	const {
		handleSubmit,
		register,
		formState: { errors }
	} = useForm<FormData>();

	const showRegistration = () => {
		setShowLoginModal(false);
		setShowRegistrationModal(true);
	};

	const onSubmit = async (data: FormData) => {
		const signInResult = (await login(data)) as any;

		if (signInResult && signInResult.data && !signInResult.data.error) {
			dispatch(setCredentials(signInResult.data));

			navigate('/dashboard');
		}
	};

	return (
		<Modal
			show={showLoginModal}
			dialogClassName={s.modal}
			onHide={() => setShowLoginModal(false)}
			centered
		>
			<div className={s.inner}>
				<div className={s.close} onClick={() => setShowLoginModal(false)}>
					<CloseIcon />
				</div>
				<div className={s.wrapper}>
					<div className={s.authTabs}>
						<h2 className={s.title}>{t('modals.modalLogin.title')}</h2>
					</div>
					<form className={s.form} onSubmit={handleSubmit(onSubmit)}>
						{isMobile && (
							<div className={s.image}>
								<img src={image_sign2} alt='' />
							</div>
						)}
						<div className={s.inputs}>
							<Input
								icon='email'
								type='email'
								placeholder={
									t('modals.modalLogin.inputs.input1.label') as string
								}
								name='email'
								register={register}
								validationSchema={{
									required: 'Email is required',
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
										message: 'Invalid email address'
									},
									minLength: {
										value: 6,
										message: 'Min 6 length'
									}
								}}
							/>
							{errors.email && (
								<ErrorMessage>{errors.email.message}</ErrorMessage>
							)}

							<Input
								password
								icon='key'
								type='password'
								placeholder={
									t('modals.modalLogin.inputs.input2.label') as string
								}
								name='password'
								register={register}
								validationSchema={{
									required: 'Password is required',
									maxLength: {
										value: 15,
										message: 'Max 15 length'
									},
									minLength: {
										value: 6,
										message: 'Min 6 length'
									}
								}}
							/>
							{errors.password && (
								<ErrorMessage>{errors.password.message}</ErrorMessage>
							)}

							{/* <Input
								iconJsx={<ShieldIcon />}
								type='text'
								placeholder={'2FA code (optional)'}
								name='twoFactorAuthCode'
								register={register}
							/> */}
						</div>
						<div className={s.button}>
							<Button type='submit' disabled={isLoading}>
								{t('modals.modalLogin.buttonSubmit')}
							</Button>
						</div>
					</form>
					<div className={s.authHelp}>
						{t('modals.modalLogin.auth.title')}&nbsp;
						<button onClick={showRegistration} className={s.authHelpBtn}>
							{' '}
							{t('modals.modalLogin.auth.button')}
						</button>
					</div>
					<div className={s.authBanner}>
						<img className={s.authBannerImg} src={banner} alt='' />
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ModalLogin;
