import React from 'react';
import { SVGProps } from 'react';

const DocumentIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={374}
		height={374}
		viewBox='0 0 374 374'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M74.8004 18.7C64.8813 18.7 55.3685 22.6403 48.3546 29.6542C41.3407 36.6681 37.4004 46.1809 37.4004 56.1V317.9C37.4004 327.819 41.3407 337.332 48.3546 344.346C55.3685 351.36 64.8813 355.3 74.8004 355.3H299.2C309.12 355.3 318.632 351.36 325.646 344.346C332.66 337.332 336.6 327.819 336.6 317.9V138.642C336.598 128.724 332.657 119.212 325.642 112.2L243.1 29.6582C236.088 22.6439 226.577 18.7021 216.659 18.7H74.8004ZM74.8004 56.1H216.659L299.2 138.642V317.9H74.8004V56.1ZM112.2 93.5C107.241 93.5 102.484 95.4702 98.9775 98.9771C95.4706 102.484 93.5004 107.24 93.5004 112.2C93.5004 117.16 95.4706 121.916 98.9775 125.423C102.484 128.93 107.241 130.9 112.2 130.9H205.7C210.66 130.9 215.416 128.93 218.923 125.423C222.43 121.916 224.4 117.16 224.4 112.2C224.4 107.24 222.43 102.484 218.923 98.9771C215.416 95.4702 210.66 93.5 205.7 93.5H112.2ZM93.5004 187C93.5004 182.04 95.4706 177.284 98.9775 173.777C102.484 170.27 107.241 168.3 112.2 168.3H261.8C266.76 168.3 271.516 170.27 275.023 173.777C278.53 177.284 280.5 182.04 280.5 187C280.5 191.96 278.53 196.716 275.023 200.223C271.516 203.73 266.76 205.7 261.8 205.7H112.2C107.241 205.7 102.484 203.73 98.9775 200.223C95.4706 196.716 93.5004 191.96 93.5004 187ZM112.2 243.1C107.241 243.1 102.484 245.07 98.9775 248.577C95.4706 252.084 93.5004 256.84 93.5004 261.8C93.5004 266.76 95.4706 271.516 98.9775 275.023C102.484 278.53 107.241 280.5 112.2 280.5H261.8C266.76 280.5 271.516 278.53 275.023 275.023C278.53 271.516 280.5 266.76 280.5 261.8C280.5 256.84 278.53 252.084 275.023 248.577C271.516 245.07 266.76 243.1 261.8 243.1H112.2Z'
		/>
	</svg>
);
export default DocumentIcon;
