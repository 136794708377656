import Input from 'pages/components/Input/Input';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { promosApi, useEditPromoMutation } from 'store/services/promos';

import { CabinetSvgSelector } from '../../../../assets/images/icons/CabinetSvgSelector';

const ModalPromoEdit = ({ showModalPromoEdit, setShowModalPromoEdit }) => {
	const { register, handleSubmit } = useForm({
		name: showModalPromoEdit.name || 'Binobi.Casino',
		additionalPostbackParams: showModalPromoEdit.additionalPostbackParams
	});

	const [editPromo] = useEditPromoMutation();

	const { refetch } = promosApi.useGetPromosQuery({
		currentPage: 1
	});

	const onSubmit = async (fields) => {
		await editPromo({
			...fields,
			campaignId: showModalPromoEdit.campaignId
		});

		refetch();

		setShowModalPromoEdit(false);
	};

	return (
		<Modal
			show={showModalPromoEdit}
			dialogClassName='modal-promo'
			onHide={() => setShowModalPromoEdit(false)}
			centered
		>
			<div
				onClick={() => setShowModalPromoEdit(false)}
				className='modal__close icon'
			>
				<CabinetSvgSelector id='close' />
			</div>

			<div className='modal__title'>Promo Edit</div>
			<div className='modal__form modal-form'>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className='modal-form__content'>
						<div className='modal-form__inputs'>
							<Input
								register={register}
								validationSchema={{ value: showModalPromoEdit.name }}
								className='modal-form__input'
								label='Promo Name (Not necessary)'
								placeholder='Promo Name'
								name='name'
							/>
							<Input
								register={register}
								validationSchema={{
									value: showModalPromoEdit.additionalPostbackParams
								}}
								className='modal-form__input'
								label='Additional Postback Params'
								name='additionalPostbackParams'
								placeholder='&YourParam=YourParamValue&AnotherParam=AnotherParamValue'
							/>
						</div>
						<div className='modal-form__button'>
							<button type='submit' className='btn'>
								Save
							</button>
						</div>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default ModalPromoEdit;
