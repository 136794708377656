import React from 'react';
import { SVGProps } from 'react';

const SkypeIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={47}
		height={47}
		viewBox='0 0 47 47'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path d='M39.5418 30.8645C39.3861 28.9243 38.637 27.5994 37.9423 26.7608C38.1788 25.7092 38.3154 24.6193 38.3154 23.4958C38.3154 15.3148 31.684 8.68341 23.503 8.68341C22.3794 8.68341 21.2896 8.82 20.238 9.05647C19.4992 8.41169 17.4356 6.97672 13.9943 7.46141C9.60865 8.07829 7.03834 12.3626 7.34384 16.1564C7.50687 18.1818 8.31174 19.5272 9.02996 20.3585C8.80629 21.3893 8.69256 22.441 8.69068 23.4958C8.69068 31.6767 15.3221 38.3096 23.503 38.3096C24.584 38.3096 25.6357 38.1862 26.6506 37.9673C27.3937 38.6136 29.4559 40.0427 32.8913 39.5595C37.2755 38.9426 39.8473 34.6597 39.5418 30.8645ZM30.8203 30.5002C29.8069 31.637 28.4645 32.7738 25.2435 33.1851C19.8502 33.8739 16.7526 31.2581 15.9257 30.4547C15.0988 29.6513 14.9622 27.6479 15.8802 26.9209C17.0963 25.9574 18.101 26.1204 19.9648 28.0224C22.259 30.3636 25.9764 29.7306 26.5977 28.4352C27.125 27.3336 26.4831 26.4847 25.289 26.141C24.0274 25.7767 19.3406 24.6032 18.5196 24.2815C14.2998 22.6321 15.2604 18.6136 15.444 17.9703C15.6276 17.327 16.1137 14.5496 21.388 13.7242C26.6638 12.8987 29.9244 15.469 29.9244 15.469C31.8514 17.0068 31.095 19.0028 30.039 19.5536C28.9829 20.1044 28.0885 19.9208 26.6432 18.5901C25.198 17.2594 23.2475 17.4195 22.7877 17.4651C22.328 17.5106 20.9063 17.6252 20.5626 18.5931C20.2189 19.561 20.8152 20.125 21.9168 20.5627C24.0744 21.4189 26.8547 21.0268 29.9714 23.133C33.2996 25.3816 31.7618 29.4442 30.8203 30.5002Z' />
	</svg>
);
export default SkypeIcon;
