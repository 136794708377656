import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
	useCreatePaymentMutation,
	useGetPaymentsCredentialsQuery
} from 'store/services/personal-partner';
import { errorNotification, successNotification } from 'utils/toasts';

import { CloseIcon } from '../../../../assets/svg';
import { Button } from '../../Button/Button';
import Dropdown, { IDropdownOption } from '../../Dropdown/Dropdown';
import Input from '../../Input/Input';
import s from '../ModalLogin/ModalLogin.module.scss';

interface IAddWalletModal {
	showAddWalletModal: boolean;
	setShowAddWalletModal: (show: boolean) => void;
}

interface AddWalletFormData {
	paymentMethod: string;
	paymentAddress: string;

	// verificationMethod?: string;
	// confirmCode?: string;
}

const AddWalletModal: FC<IAddWalletModal> = ({
	showAddWalletModal,
	setShowAddWalletModal
}) => {
	const [createPayment] = useCreatePaymentMutation();
	const { refetch } = useGetPaymentsCredentialsQuery({});

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<AddWalletFormData>();

	const onSubmit = async (data: AddWalletFormData) => {
		const res = (await createPayment(data)) as any;

		if (res.error) {
			errorNotification('Error on creating payment method');
		}

		if (res?.data?.success) {
			successNotification('Payment created successfully');
			refetch();
			setShowAddWalletModal(false);
		}
	};

	const optionsPayment: IDropdownOption[] = [
		{ value: 'VISA', label: 'VISA' },
		{ value: 'MasterCard', label: 'MasterCard' },
		{ value: 'QIWI', label: 'QIWI' },
		{ value: 'WebMoney', label: 'WebMoney' },
		{ value: 'Interkassa', label: 'Interkassa' },
		{ value: 'Skrill', label: 'Skrill' },
		{ value: 'Neteller', label: 'Neteller' },
		{ value: 'Capitalist', label: 'Capitalist' },
		{ value: 'Bitcoin', label: 'Bitcoin' },
		{ value: 'USDT', label: 'USDT' },
		{ value: 'ZaleyCash', label: 'ZaleyCash' },
		{ value: 'UPI', label: 'UPI' }
	];

	return (
		<Modal
			show={showAddWalletModal}
			dialogClassName={s.modal}
			onHide={() => setShowAddWalletModal(false)}
			centered
		>
			<div className={s.inner}>
				<div className={s.close} onClick={() => setShowAddWalletModal(false)}>
					<CloseIcon />
				</div>
				<div className={s.wrapper}>
					<div className={s.authTabs}>
						<h2 className={s.title}>Addition of a wallet</h2>
					</div>
					<form className={s.form} onSubmit={handleSubmit(onSubmit)}>
						<div className={s.inputs}>
							<Dropdown
								onSelect={(v) => register('paymentMethod', { value: v })}
								className={s.dropdown}
								triggerClassName={s.dropdown__trigger}
								options={optionsPayment}
								placeholder='Payment Method'
							/>

							<Input
								placeholder='Payment Address/Card'
								name='paymentAddress'
								register={register}
								validationSchema={{
									required: 'Wallet number is required'
									// pattern: {
									// 	value: /^\d+$/,
									// 	message: 'Wallet number must contain only digits'
									// }
								}}
							/>
							{errors.paymentAddress && (
								<span>{errors.paymentAddress.message}</span>
							)}
							{/* <fieldset className={s.popup__field}>
								<legend>Confirmation method:</legend>
								<div className={s.inputs__wrapper}>
									<CheckboxCabinet
										radio
										className={s.verMethod}
										name='verificationMethod'
									>
										SMS
									</CheckboxCabinet>
									<CheckboxCabinet
										radio
										className={s.verMethod}
										name='verificationMethod'
									>
										Google 2FA
									</CheckboxCabinet>
								</div>
							</fieldset>

							<div className={s.inputs__wrapper}>
								<Input
									placeholder='Verification code'
									name='confirmCode'
									register={register}
									validationSchema={{
										required: 'Verification code is required',
										pattern: {
											value: /^\d+$/,
											message: 'Wallet number must contain only digits'
										}
									}}
								/>
								<Button small minor className={s.inputs__button}>
									Receive a code
								</Button>
							</div>
							{errors.confirmCode && <span>{errors.confirmCode.message}</span>} */}
						</div>
						<div className={s.button}>
							<Button type='submit'>Add</Button>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default AddWalletModal;
