import { configureStore } from '@reduxjs/toolkit';

import { offersApi } from './services/offers';
import { personalPartnerApi } from './services/personal-partner';
import { promosApi } from './services/promos';
import { usersApi } from './services/users';
import authReducer from './slices/auth';

export const store = configureStore({
	reducer: {
		[usersApi.reducerPath]: usersApi.reducer,
		[offersApi.reducerPath]: offersApi.reducer,
		[promosApi.reducerPath]: promosApi.reducer,
		[personalPartnerApi.reducerPath]: personalPartnerApi.reducer,
		auth: authReducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(usersApi.middleware)
			.concat(offersApi.middleware)
			.concat(promosApi.middleware)
			.concat(personalPartnerApi.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
