// import { Element } from 'react-scroll';
import { ParallaxProvider } from 'react-scroll-parallax';

import Hero from '../Info/Hero/Hero';

import Agreement from './Policy/Policy';

const PolicyPage = () => {
	return (
		<ParallaxProvider>
			<Hero />
			<Agreement />
		</ParallaxProvider>
	);
};

export default PolicyPage;
