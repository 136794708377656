import React from 'react';

import image from '../../../assets/images/app/image.png';
import { CabinetSvgSelector } from '../../../assets/images/icons/CabinetSvgSelector';
import BackButtonCabinet from '../../components/BackButtonCabinet/BackButtonCabinet';

const Application = () => {
	return (
		<div className='application'>
			<BackButtonCabinet />
			<div className='application__wrapper'>
				<div className='application__content'>
					<div className='application__inner'>
						<div className='application__title'>
							Now the conversion will be even better
						</div>
						<div className='application__text'>
							<p>
								<b>
									Free mobile applications on the Android and iOS platform for
									all publishers.{' '}
								</b>
							</p>
							<p>
								Applications are fully optimized for the product and run
								smoothly without errors.
							</p>
							<p>Pros of working with applications from binobi.partners</p>
							<ul>
								<li>Support for any GEO</li>
								<li>Translation of texts from carriers</li>
								<li>No moderation issues</li>
								<li>Optimized for betting and gambling</li>
								<li>Ability to work with almost any type of traffic</li>
								<li>Monitoring application bans via Telegram bot</li>
								<li>
									Push notifications including promotions and current news to
									increase player engagement
								</li>
								<li>Continuous support from managers</li>
								<li>No commission for using the application</li>
							</ul>
						</div>
						<div className='application__buttons'>
							<div className='application__button'>
								<a href='#' className='btn'>
									Connect App
								</a>
							</div>
							<div className='application__social'>
								<ul>
									<li>
										<a href='#'>
											<div className='icon'>
												<CabinetSvgSelector id='apple' />
											</div>
										</a>
									</li>
									<li>
										<a href='#'>
											<div className='icon'>
												<CabinetSvgSelector id='googleplay' />
											</div>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className='application__image'>
						<img src={image} alt='' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Application;
