import React from 'react';
import { SVGProps } from 'react';

const LogoutIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
		<g clipPath='url(#asclip0_301_4021)'>
			<path d='M19.935 8.848a.843.843 0 00-.18-.272l-2.5-2.499a.834.834 0 00-1.178 1.179l1.077 1.077H12.5a.833.833 0 100 1.667h4.655l-1.077 1.077a.832.832 0 101.178 1.178l2.5-2.499a.835.835 0 00.18-.908z' />
			<path d='M14.167 11.667a.833.833 0 00-.834.833v4.167H10V3.333a.834.834 0 00-.594-.798l-2.894-.868h6.821v4.166a.833.833 0 101.667 0v-5A.833.833 0 0014.167 0H.833C.803 0 .777.013.748.016a.835.835 0 00-.354.123C.376.151.354.152.336.165.329.17.326.179.32.185a.826.826 0 00-.222.263C.087.47.084.493.075.518.048.58.019.641.009.712.005.738.013.76.012.784.01.802 0 .817 0 .833V17.5c0 .398.28.74.67.817l8.333 1.666a.833.833 0 00.997-.817v-.833h4.167c.46 0 .833-.373.833-.833v-5a.833.833 0 00-.833-.833z' />
		</g>
		<defs>
			<clipPath id='asclip0_301_4021'>
				<path d='M0 0h20v20H0z' />
			</clipPath>
		</defs>
	</svg>
);
export default LogoutIcon;
