import { getMe } from 'api/requests/auth';
import { useAuthUser } from 'hooks/useAuth';
import Finance from 'pages/Cabinet/Finance/Finance';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setUser } from 'store/slices/auth';

import AgreementPage from './pages/Agreement/AgreementPage';
import Application from './pages/Cabinet/Application';
import Dashboard from './pages/Cabinet/Dashboard';
import News from './pages/Cabinet/News';
import Notifications from './pages/Cabinet/Notifications';
import Profile from './pages/Cabinet/Profile';
import CreatePromo from './pages/Cabinet/Promo/CreatePromo';
import MyPromo from './pages/Cabinet/Promo/MyPromo';
import MyPromoStatistic from './pages/Cabinet/Promo/MyPromoStatistic';
import PersonalPromo from './pages/Cabinet/Promo/PersonalPromo';
// import AffiliateAgreement from './pages/Cabinet/Settings/AffiliateAgreement';
// import Documentation from './pages/Cabinet/Settings/Documentation';
// import FaqSettings from './pages/Cabinet/Settings/Faq';
// import Logs from './pages/Cabinet/Settings/Logs';
// import Postback from './pages/Cabinet/Settings/Postback';
// import Profile from './pages/Cabinet/Settings/UserProfile';
import CohortAnalysis from './pages/Cabinet/Statistics/CohortAnalysis';
import DayStatisticts from './pages/Cabinet/Statistics/DayStatisticts';
import GeneralStatisticts from './pages/Cabinet/Statistics/GeneralStatisticts';
import NegativeCarryover from './pages/Cabinet/Statistics/NegativeCarryover';
import PlayersStatisticts from './pages/Cabinet/Statistics/PlayerStatistic/PlayersStatisticts';
import PromoStatisticts from './pages/Cabinet/Statistics/PromoStatisticts';
import ReferralStatisticts from './pages/Cabinet/Statistics/ReferralStatisticts';
import Support from './pages/Cabinet/Support';
import Faq from './pages/Faq/Faq';
import Home from './pages/Home/Home';
import InfoPage from './pages/Info/InfoPage';
import PolicyPage from './pages/Policy/PolicyPage';
import CabinetLayout from './pages/layouts/CabinetLayout/CabinetLayout';
import MainLayout from './pages/layouts/MainLayout/MainLayout';
import TabsLayout from './pages/layouts/TabsLayout/TabsLayout';
import { navigationStatisticsData } from './utils/navigationData';

function App(): JSX.Element {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const token = localStorage.getItem('token');
	const [mount, setMount] = useState(false);

	useEffect(() => {
		const handleUser = async () => {
			const user = await getMe();

			if (user) dispatch(setUser(user));
		};

		if (mount && token) handleUser();

		if (!token) navigate('/');
	}, [mount]);

	useEffect(() => setMount(true), []);

	const user = useAuthUser();

	return (
		<>
			<Routes>
				{!user && (
					<Route element={<MainLayout />}>
						<Route path='/' element={<Home />} />
						<Route path='/faq' element={<Faq />} />
						<Route path='/information' element={<InfoPage />} />
						<Route path='/agreement' element={<AgreementPage />} />
						<Route path='/policy' element={<PolicyPage />} />

						{/* <Route path="/testimonials" element={<TestimonialsPage />} /> */}
					</Route>
				)}

				{user && (
					<>
						<Route element={<CabinetLayout />}>
							<Route path='/dashboard' element={<Dashboard />} />
							<Route path='/application' element={<Application />} />
							<Route path='/support' element={<Support />} />
							<Route path='/promo/create' element={<CreatePromo />} />
							<Route path='/promo/my' element={<MyPromo />} />
							<Route
								path='/promo/my/statistic'
								element={<MyPromoStatistic />}
							/>
							<Route path='/promo/personal' element={<PersonalPromo />} />
							<Route path='/news' element={<News />} />
							<Route path='/notifications' element={<Notifications />} />

							<Route path='/profile' element={<Profile />} />
						</Route>

						<Route
							element={<TabsLayout navigation={navigationStatisticsData} />}
						>
							<Route
								path='/statistics/general'
								element={<GeneralStatisticts />}
							/>
							<Route path='/statistics/promo' element={<PromoStatisticts />} />
							<Route
								path='/statistics/referral'
								element={<ReferralStatisticts />}
							/>
							<Route
								path='/statistics/players'
								element={<PlayersStatisticts />}
							/>
							<Route path='/statistics/day' element={<DayStatisticts />} />
							<Route
								path='/statistics/negative-carryover'
								element={<NegativeCarryover />}
							/>
							<Route
								path='/statistics/cohort-analysis'
								element={<CohortAnalysis />}
							/>

							<Route path='/finance' element={<Finance />} />

							<Route path='*' element={<Dashboard />} />
						</Route>
					</>
				)}

				{/* {!user && <Route path='*' element={<Home />} />} */}
			</Routes>

			<ToastContainer />
		</>
	);
}

export default App;
