import React from 'react';
import { CabinetSvgSelector } from '../../../assets/images/icons/CabinetSvgSelector';
import InputCabinet from '../../components/InputCabinet/InputCabinet';
import MySelect from '../../components/MySelect';
import PaginationTableCabinet from '../../components/PaginationTableCabinet/PaginationTableCabinet';
import Datepicker from '../components/Datepicker';
import PeriodSorting from '../components/PeriodSorting';

const CohortAnalysis = () => {
  const optionsOffers = [
    { value: 'All', label: 'All' },
    { value: 'Detailed', label: 'Detailed' },
    { value: 'SportRS', label: 'Sport RS' },
    { value: 'CasinoRS', label: 'Casino RS' },
    { value: 'OUTCasinoCPA', label: 'OUT Casino CPA' },
  ];
  const optionsGeo = [
    { value: 'All', label: 'All' },
    { value: 'Detailed', label: 'Detailed' },
    { value: 'RURussia', label: 'RU Russia' },
    { value: 'UAUkraine', label: 'UA Ukraine' },
    { value: 'TRTurkey', label: 'TR Turkey' },
  ];
  const optionsSteam = [
    { value: 'All', label: 'All' },
    { value: 'Detailed', label: 'Detailed' },
  ];

  const optionsSubld = [
    { value: 'All', label: 'All' },
    { value: 'Detailed', label: 'Detailed' },
  ];

  return (
    <div className="statistics">
      <PeriodSorting className="statistics__period" />

      <div className="statistics__head">
        <div className="statistics__head-wrapper">
          <div className="statistics__control statistics-control">
            <div className="statistics-control__select select">
              <span>Offers</span>
              <MySelect options={optionsOffers} defaultValue={optionsOffers[0]} />
            </div>
            <div className="statistics-control__select select">
              <span>GEO</span>
              <MySelect options={optionsGeo} defaultValue={optionsGeo[0]} />
            </div>
            <div className="statistics-control__select select">
              <span>Steam</span>
              <MySelect options={optionsSteam} defaultValue={optionsSteam[0]} />
            </div>
            <div className="statistics-control__select select">
              <span>Subld</span>
              <MySelect options={optionsSubld} defaultValue={optionsSubld[0]} />
            </div>
            <InputCabinet className="statistics-control__select" placeholder="StreamID" small />
            <InputCabinet className="statistics-control__select" placeholder="SublD1" small />
          </div>
          <div className="statistics__head-buttons">
            <div className="statistics__head-button">
              <a href="#" className="btn">
                Create
              </a>
            </div>
            <div className="statistics__head-button">
              <a href="#" className="btn btn--silver">
                Download
              </a>
            </div>
          </div>
        </div>
        <div className="statistics__head-inner">
          <label className="promo-mail-head__select-wrapper">
            <span>First Deposit Period</span>

            <Datepicker />
          </label>
          <label className="promo-mail-head__select-wrapper">
            <span>Registration Period</span>
            <Datepicker />
          </label>
          <label className="promo-mail-head__select-wrapper">
            <span>Baseline completion period</span>
            <Datepicker />
          </label>

          <div className="statistics__head-inputs">
            <InputCabinet placeholder="StreamID" small />
            <InputCabinet placeholder="SublD1" small />
          </div>
          <div className="statistics__head-buttons statistics__head-buttons--hidden">
            <div className="statistics__head-button">
              <a href="#" className="btn">
                Create
              </a>
            </div>
            <div className="statistics__head-button">
              <a href="#" className="btn btn--silver">
                Download
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="statistics__body">
        <div className="table table--large" data-simplebar>
          <table>
            <thead>
              <tr>
                <td>
                  <span>
                    Period
                    <div className="f-arrows">
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_up" />
                      </button>
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_down" />
                      </button>
                    </div>
                  </span>
                </td>
                <td>Offer</td>
                <td>GEO</td>
                <td>Stream</td>
                <td>Subld</td>
                <td>PID</td>
                <td>
                  <span>
                    Clicks
                    <div className="f-arrows">
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_up" />
                      </button>
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_down" />
                      </button>
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    Hits
                    <div className="f-arrows">
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_up" />
                      </button>
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_down" />
                      </button>
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    Regs
                    <div className="f-arrows">
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_up" />
                      </button>
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_down" />
                      </button>
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    FD
                    <div className="f-arrows">
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_up" />
                      </button>
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_down" />
                      </button>
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    RD
                    <div className="f-arrows">
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_up" />
                      </button>
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_down" />
                      </button>
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    Bet Sum
                    <div className="f-arrows">
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_up" />
                      </button>
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_down" />
                      </button>
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    Win Sum
                    <div className="f-arrows">
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_up" />
                      </button>
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_down" />
                      </button>
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    Withdrawal Sum
                    <div className="f-arrows">
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_up" />
                      </button>
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_down" />
                      </button>
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    CPA
                    <div className="f-arrows">
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_up" />
                      </button>
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_down" />
                      </button>
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    Revenue Share
                    <div className="f-arrows">
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_up" />
                      </button>
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_down" />
                      </button>
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    Hybrid
                    <div className="f-arrows">
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_up" />
                      </button>
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_down" />
                      </button>
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    Combine + CPA
                    <div className="f-arrows">
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_up" />
                      </button>
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_down" />
                      </button>
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    Combine + RS
                    <div className="f-arrows">
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_up" />
                      </button>
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_down" />
                      </button>
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    Referral
                    <div className="f-arrows">
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_up" />
                      </button>
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_down" />
                      </button>
                    </div>
                  </span>
                </td>
                <td>
                  <span>
                    Revenue
                    <div className="f-arrows">
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_up" />
                      </button>
                      <button className="f-arrow icon">
                        <CabinetSvgSelector id="f_chevron_down" />
                      </button>
                    </div>
                  </span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>16.11.2022</td>
                <td>All</td>
                <td>All</td>
                <td>All</td>
                <td>All</td>
                <td>All</td>
                <td>86</td>
                <td>86</td>
                <td>86</td>
                <td>86</td>
                <td>86</td>
                <td>86</td>
                <td>86</td>
                <td>86</td>
                <td>59</td>
                <td>59</td>
                <td>59</td>
                <td>59</td>
                <td>59</td>
                <td>59</td>
                <td>59</td>
              </tr>
              <tr>
                <td>16.11.2022</td>
                <td>All</td>
                <td>All</td>
                <td>All</td>
                <td>All</td>
                <td>All</td>
                <td>86</td>
                <td>86</td>
                <td>86</td>
                <td>86</td>
                <td>86</td>
                <td>86</td>
                <td>86</td>
                <td>86</td>
                <td>59</td>
                <td>59</td>
                <td>59</td>
                <td>59</td>
                <td>59</td>
                <td>59</td>
                <td>59</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>Total</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>137</td>
                <td>35</td>
                <td>1</td>
                <td>217</td>
                <td>137</td>
                <td>35</td>
                <td>1</td>
                <td>0</td>
                <td>50</td>
                <td>217</td>
                <td>137</td>
                <td>35</td>
                <td>1</td>
                <td>0</td>
                <td>50</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <PaginationTableCabinet />
      </div>
    </div>
  );
};

export default CohortAnalysis;
