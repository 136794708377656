import allImage from 'assets/images/flags/all.svg';
import image1 from 'assets/images/promo/image1.jpg';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { TLanding, TOffer, offersApi } from 'store/services/offers';
import { countries } from 'utils/getLanguageIcon';

type TThirdtep = {
	selectedOffer: TOffer | undefined;
	setLanding: (v: TLanding) => void;
	selectedLanding: TLanding | undefined;
};

export const ThirdStep: FC<TThirdtep> = ({
	selectedOffer,
	setLanding,
	selectedLanding
}) => {
	const [selectedLanguage, setSelectedLanguage] = useState('All');

	const { data: landings } = offersApi.useGetLandingsQuery({
		...(selectedLanguage &&
			selectedLanguage !== 'All' && { geo: selectedLanguage }),

		...(selectedOffer?.landings && { landings: selectedOffer.landings })
	});

	return (
		<div className='create-promo-steps__section create-promo-section active'>
			<div className='create-promo-section__body'>
				<div className='promo-link'>
					<div className='promo-link__head'>
						<div className='promo-link__language'>
							<div className='promo-link__language-title'>Language Offer</div>
							<div className='promo-link__language-list'>
								<ul>
									<li
										className={selectedLanguage === 'All' ? 'active' : ''}
										onClick={() => setSelectedLanguage('All')}
									>
										<Link to='#'>
											<img src={allImage} alt='' />
											All
										</Link>
									</li>
									{selectedOffer?.geo && (
										<li
											className={
												selectedOffer?.geo === selectedLanguage ? 'active' : ''
											}
											onClick={() => setSelectedLanguage(selectedOffer?.geo)}
										>
											<Link to='#'>
												<img
													src={
														countries.find(
															({ value }) => selectedOffer?.geo === value
														)?.icon
													}
													alt=''
												/>
												{selectedOffer?.geo}
											</Link>
										</li>
									)}
								</ul>
							</div>
						</div>
						{/* <form method='post' className='promo-link__add'>
							<div className='promo-link__add-input input input--small'>
								<div className='input__field'>
									<input type='text' placeholder='SublD1' />
								</div>
							</div>
							<button type='submit' className='promo-link__add-button'>
								<div className='icon'>
									<CabinetSvgSelector id='plus' />
								</div>
							</button>
						</form> */}
					</div>

					<div className='promo-link__columns'>
						{landings &&
							landings.map(({ domain, imageUrl, ...rest }) => (
								<div
									id={domain}
									className='promo-link__column promo-link-column'
								>
									<div className='promo-link-column__title'>{domain}</div>
									<label className='promo-offer-item'>
										<div className='promo-offer-item__image'>
											<iframe
												title={domain}
												loading='lazy'
												src={imageUrl}
												style={{ backgroundImage: image1 }}
											/>
										</div>
										<input
											type='radio'
											name='promo-link'
											checked={selectedLanding?.id === rest.id}
											onClick={() => setLanding({ domain, imageUrl, ...rest })}
										/>
										<div className='promo-offer-item__selected'>
											<span>Select</span>
										</div>
									</label>
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};
