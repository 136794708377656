/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react';

import Datepicker from '../Datepicker';

type PeriodSortingProps = {
	className?: string;
	onlyTitle?: boolean;
	customTitle?: string;
	onlyDay?: boolean;
	hideDate?: boolean;
	setDates: (dates: any) => void;
	setDateType?: (type: any) => void;
};

const PeriodSorting: FC<PeriodSortingProps> = ({
	className,
	customTitle,
	onlyTitle,
	onlyDay,
	hideDate,
	setDates,
	setDateType
}) => {
	const [currentType, setCurrentType] = useState('week');

	useEffect(() => {
		if (setDateType) {
			setDateType(currentType);
		}
	}, [currentType]);

	return (
		<div className={`${className} promo-mail-period`}>
			<div className='promo-mail-period__shadow'></div>

			{!onlyTitle && (
				<div className='promo-mail-period__wrapper'>
					<div className='chart-control-period'>
						<div className='chart-control-period__title'>
							{customTitle ? customTitle : 'Period:'}
						</div>
						<Datepicker
							setDates={setDates}
							isSoloDate={currentType === 'day'}
							isWeekDate={currentType === 'week'}
							isDatePick={currentType === 'date'}
							className='chart-control-period__select'
						/>
						<div className='chart-control-period__buttons'>
							{onlyDay ? (
								<ul>
									<li
										className={currentType === 'day' ? 'active' : ''}
										onClick={() => setCurrentType('day')}
									>
										<a href='#'>Day</a>
									</li>

									{!hideDate && (
										<li
											className={currentType === 'date' ? 'active' : ''}
											onClick={() => setCurrentType('date')}
										>
											<a href='#'>Date</a>
										</li>
									)}
								</ul>
							) : (
								<ul>
									<li
										className={currentType === 'day' ? 'active' : ''}
										onClick={() => setCurrentType('day')}
									>
										<a href='#'>Day</a>
									</li>
									<li
										className={currentType === 'week' ? 'active' : ''}
										onClick={() => setCurrentType('week')}
									>
										<a href='#'>Week</a>
									</li>
									{!hideDate && (
										<li
											className={currentType === 'date' ? 'active' : ''}
											onClick={() => setCurrentType('date')}
										>
											<a href='#'>Date</a>
										</li>
									)}
								</ul>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default PeriodSorting;
