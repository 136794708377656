import { CabinetSvgSelector } from 'assets/images/icons/CabinetSvgSelector';
import newsImage1 from 'assets/images/profile/news/img1.jpg';

const News = () => (
	<div className='latest-news'>
		<div className='latest-news__content'>
			<div className='latest-news__head'>
				<div className='latest-news__title'>
					<div className='icon'>
						<CabinetSvgSelector id='news' />
					</div>
					Latest news
				</div>
				<div className='latest-news__button'>
					<a href='#' className='btn btn--rounded btn--silver'>
						More
					</a>
				</div>
			</div>
			<div data-simplebar className='latest-news__items'>
				<div className='latest-news__item latest-news-item'>
					<a href='#' className='latest-news-item__image'>
						<img src={newsImage1} alt='' />
					</a>
					<div className='latest-news-item__content'>
						<div className='latest-news-item__title'>Binobi Days Malta</div>
						<div className='latest-news-item__text'>
							Binobi Days Malta: a party for the TOPs of affliate ma...
						</div>
						<div className='latest-news-item__wrapper'>
							<div className='latest-news-item__date'>11.12.2022</div>
							<div className='a latest-news-item__button'>
								<a href='#' className='btn btn--rounded'>
									More
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className='latest-news__item latest-news-item'>
					<a href='#' className='latest-news-item__image'>
						<img src={newsImage1} alt='' />
					</a>
					<div className='latest-news-item__content'>
						<div className='latest-news-item__title'>Binobi Days Malta</div>
						<div className='latest-news-item__text'>
							Binobi Days Malta: a party for the TOPs of affliate ma...
						</div>
						<div className='latest-news-item__wrapper'>
							<div className='latest-news-item__date'>11.12.2022</div>
							<div className='a latest-news-item__button'>
								<a href='#' className='btn btn--rounded btn--silver'>
									More
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className='latest-news__item latest-news-item'>
					<a href='#' className='latest-news-item__image'>
						<img src={newsImage1} alt='' />
					</a>
					<div className='latest-news-item__content'>
						<div className='latest-news-item__title'>Binobi Days Malta</div>
						<div className='latest-news-item__text'>
							Binobi Days Malta: a party for the TOPs of affliate ma...
						</div>
						<div className='latest-news-item__wrapper'>
							<div className='latest-news-item__date'>11.12.2022</div>
							<div className='a latest-news-item__button'>
								<a href='#' className='btn btn--rounded btn--silver'>
									More
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className='latest-news__item latest-news-item'>
					<a href='#' className='latest-news-item__image'>
						<img src={newsImage1} alt='' />
					</a>
					<div className='latest-news-item__content'>
						<div className='latest-news-item__title'>Binobi Days Malta</div>
						<div className='latest-news-item__text'>
							Binobi Days Malta: a party for the TOPs of affliate ma...
						</div>
						<div className='latest-news-item__wrapper'>
							<div className='latest-news-item__date'>11.12.2022</div>
							<div className='a latest-news-item__button'>
								<a href='#' className='btn btn--rounded btn--silver'>
									More
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default News;
