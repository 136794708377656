export const navigationStatisticsData = [
  {
    title: `General`,
    link: `/statistics/general`,
  },
  {
    title: `Promo`,
    link: `/statistics/promo`,
  },
  {
    title: `Referral`,
    link: `/statistics/referral`,
  },
  {
    title: `Players statistics`,
    link: `/statistics/players`,
  },
  {
    title: `Negative carryover`,
    link: `/statistics/negative-carryover`,
  },
  {
    title: `Cohort analysis`,
    link: `/statistics/cohort-analysis`,
  },
  {
    title: `By day`,
    link: `/statistics/day`,
  },
];

export const navigationFinanceData = [
  {
    title: `Create payment`,
    link: `/finance/create-payment`,
  },
  {
    title: `Payments log`,
    link: `/finance/payments-log`,
  },
  {
    title: `Wallets`,
    link: `/finance/wallets`,
  },
  {
    title: `Payback`,
    link: `/finance/payback`,
  },
  {
    title: `Exchange rates`,
    link: `/finance/exchange-rates`,
  },
  {
    title: `Operations with bonuses`,
    link: `/finance/operations-with-bonuses`,
  },
];

export const navigationSettingsData = [
  {
    title: `Profile`,
    link: `/settings/profile`,
  },
  {
    title: `Postback settings`,
    link: `/settings/postback`,
  },
  {
    title: `Documentation`,
    link: `/settings/documentation`,
  },
  {
    title: `Affiliate agreement`,
    link: `/settings/affiliate-agreement`,
  },
  {
    title: `FAQ`,
    link: `/settings/faq`,
  },
  {
    title: `Logs of entries and changes of purses`,
    link: `/settings/logs`,
  },
];
