import { useAuthUser } from 'hooks/useAuth';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation } from 'react-router-dom';

import { CabinetSvgSelector } from '../../../assets/images/icons/CabinetSvgSelector';
import logo from '../../../assets/images/logos/binobi_partners.svg';
import { CloseIcon, UserIcon } from '../../../assets/svg';
import BurgerMenuIcon from '../../../assets/svg/BurgerMenuIcon';
import Language from '../Language/Language';
import NavbarCabinet from '../NavbarCabinet/NavbarCabinet';

import s from './HeaderCabinet.module.scss';
import BalanceDropdown from './balance-dropdown/BalanceDropdown';

interface IHeaderCabinet {
	toggleMenu: () => void;
	showNavbar: boolean;
	toggleNavbar: () => void;
	showMenu: boolean;
}

const HeaderCabinet: FC<IHeaderCabinet> = ({
	toggleNavbar,
	showNavbar,
	toggleMenu,
	showMenu
}) => {
	const location = useLocation();
	const user = useAuthUser();
	const { t } = useTranslation();

	const isMobile = useMediaQuery({ query: '(max-width: 991.98px)' });

	return (
		<header className={s.header}>
			<div className='container-full'>
				<div className={s.wrapper}>
					<div className={s.inner}>
						<div className={s.burger} onClick={toggleMenu}>
							{showMenu ? <CloseIcon /> : <BurgerMenuIcon />}
						</div>
						<Link to='/dashboard' className={s.logo}>
							<img src={logo} alt='Logo' />
						</Link>
					</div>

					<div className='header__user' onClick={toggleNavbar}>
						<div className='icon'>
							<CabinetSvgSelector id='user' />
						</div>
					</div>

					{/* {showMenu && (
						<MenuCabinet showMenu={showMenu} toggleMenu={toggleMenu} />
					)} */}

					{!isMobile && (
						<>
							<div className='header__control header-control'>
								{/* <Link to='/promo/my/' className='header-control__mail'>
									<div className='icon'>
										<CabinetSvgSelector id='mail' />

										<small></small>
									</div>
								</Link> */}

								<Link to='/profile' className='header-control__settings'>
									<div className='icon'>
										<UserIcon />
									</div>
									<div className='header-control__username'>{user?.email}</div>
								</Link>

								<BalanceDropdown className='header-control__balance' />
								{/* <div className='header-control__language language'>
									<Language isCabinet />
								</div> */}
							</div>
						</>
					)}
				</div>
			</div>

			<div
				className={showNavbar ? 'header__overlay active' : 'header__overlay'}
				onClick={toggleNavbar}
			></div>

			{isMobile && (
				<NavbarCabinet showNavbar={showNavbar} toggleNavbar={toggleNavbar} />
			)}
		</header>
	);
};

export default HeaderCabinet;
