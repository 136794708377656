import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import { GlobalSvgSelector } from '../../../assets/images/icons/GlobalSvgSelector';
import logo from '../../../assets/images/logo.svg';
import useScrollBlock from '../../../hooks/useScrollBlock';
import { Button } from '../Button/Button';
import Language from '../Language/Language';
import Navigation from '../Navigation/Navigation';
import Social from '../Social/Social';
import ModalLogin from '../modals/ModalLogin/ModalLogin';
import ModalRegistration from '../modals/ModalRegistration/ModalRegistration';
import ModalThanks from '../modals/ModalThanks/ModalThanks';

import s from './Header.module.scss';

const Header = () => {
	const { t } = useTranslation();
	const [blockScroll, allowScroll] = useScrollBlock();

	const isMobile = useMediaQuery({ query: '(max-width: 991.98px)' });
	const isLaptop = useMediaQuery({ query: '(min-width: 992px)' });

	const [menuVisible, setMenuVisible] = useState(false);
	const [showRegistrationModal, setShowRegistrationModal] = useState(false);
	const [showLoginModal, setShowLoginModal] = useState(false);
	const [showThanksModal, setShowThanksModal] = useState(false);

	const toggleMenu = () => {
		setMenuVisible(!menuVisible);
		if (isMobile) {
			!menuVisible ? blockScroll() : allowScroll();
		}
	};

	return (
		<>
			<header className={s.header}>
				<div className='container'>
					<div className={s.wrapper}>
						<div className={s.burger}>
							<div onClick={toggleMenu} className={s.burger__icon}>
								<GlobalSvgSelector id='menu' />
							</div>
						</div>
						{isMobile && (
							<div className={s.language}>
								<Language />
							</div>
						)}
						<Link to='/' className={s.logo}>
							<img src={logo} alt='Logo' />
						</Link>
						<div
							className={menuVisible ? classNames(s.menu, s.active) : s.menu}
						>
							<div onClick={toggleMenu} className={s.close}>
								<GlobalSvgSelector id='close' />
							</div>
							<Link to='/' className={s.logo}>
								<img src={logo} alt='Logo' />
							</Link>
							<div className={s.navigation}>
								<Navigation toggleMenu={toggleMenu} />
							</div>
							<div className={s.social}>
								<Social />
							</div>
						</div>
						<div className={s.buttons}>
							<div className={s.button}>
								<Button small minor onClick={() => setShowLoginModal(true)}>
									{t('button_sign')}
								</Button>
							</div>
							<div className={s.button}>
								<Button small onClick={() => setShowRegistrationModal(true)}>
									{t('button_reg')}
								</Button>
							</div>
						</div>

						{isLaptop && (
							<div className={s.language}>
								<Language />
							</div>
						)}

						<div
							onClick={() => setMenuVisible(false)}
							className={
								menuVisible ? classNames(s.overlay, s.active) : s.overlay
							}
						></div>
					</div>
				</div>
			</header>
			<ModalRegistration
				showRegistrationModal={showRegistrationModal}
				setShowRegistrationModal={setShowRegistrationModal}
				setShowLoginModal={setShowLoginModal}
				setShowThanksModal={setShowThanksModal}
			/>
			<ModalLogin
				showLoginModal={showLoginModal}
				setShowLoginModal={setShowLoginModal}
				setShowRegistrationModal={setShowRegistrationModal}
			/>
			<ModalThanks
				showThanksModal={showThanksModal}
				setShowThanksModal={setShowThanksModal}
			/>
		</>
	);
};

export default Header;
