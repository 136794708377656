import AOS from 'aos';
import 'aos/dist/aos.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { GlobalSvgSelector } from '../../../assets/images/icons/GlobalSvgSelector';
import iconLink from '../../../assets/images/info/icon.png';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

import s from './Info.module.scss';

AOS.init({
	once: true
});

const Info = () => {
	const { t } = useTranslation();

	const links = [
		{
			icon: iconLink,
			link: '/agreement',
			title: t('footer.navigation.link1')
		},
		{
			icon: iconLink,
			link: '/policy',
			title: t('footer.navigation.link2')
		}
	];

	return (
		<div className={s.faq}>
			<div className='container'>
				<div className={s.wrapper}>
					<Breadcrumbs name={t('navigation.link4')} />
					<div className={s.links}>
						<ul>
							{links &&
								links.map((item, index) => (
									<li data-aos='fade-up' data-aos-duration='700' key={index}>
										<Link to={item.link}>
											<div className={s.icon}>
												<img src={item.icon} alt='' />
											</div>
											{item.title}
											<div className={s.arrow}>
												<GlobalSvgSelector id='arrow_next' />
											</div>
										</Link>
									</li>
								))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Info;
