import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
	.use(LanguageDetector)
	.use(HttpBackend)
	.use(initReactI18next)
	.init({
		lng: 'en',
		fallbackLng: 'en',
		supportedLngs: ['en', 'ru', 'uk'],
		detection: {
			order: ['localStorage', 'queryString', 'cookie'],
			caches: ['cookie']
		},
		backend: {
			loadPath: '/locales/{{lng}}.json'
		}
	});

export default i18n;
