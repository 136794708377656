import React from 'react';
import PaginationTableCabinet from '../../components/PaginationTableCabinet/PaginationTableCabinet';
import PeriodSorting from '../components/PeriodSorting';

const DayStatisticts = () => {
  return (
    <div className="statistics">
      <PeriodSorting className="statistics__period" onlyDay />

      <div className="statistics__head">
        <div className="statistics__head-inner flex-end">
          <div className="statistics__head-buttons">
            <div className="statistics__head-button">
              <a href="#" className="btn">
                Create
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="statistics__body">
        <div className="table" data-simplebar>
          <table>
            <thead>
              <tr>
                <td>Date</td>
                <td>Clicks</td>
                <td>uClicks</td>
                <td>Registration</td>
                <td>First deposit</td>
                <td>Secondary deposits</td>
                <td>Bet sum</td>
                <td>Win Sum</td>
                <td>Win Sum</td>
                <td>Deposit Sum</td>
                <td>Betting RS</td>
                <td>Casino RS</td>
                <td>Net. Gaming</td>
                <td>Webmaster revenue</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>16.11.2022</td>
                <td>98</td>
                <td>98</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>2</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
                <td>7</td>
                <td>8</td>
              </tr>
              <tr>
                <td>16.11.2022</td>
                <td>98</td>
                <td>98</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>2</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
                <td>7</td>
                <td>8</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>Total</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
                <td>7</td>
                <td>8</td>
                <td>9</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <PaginationTableCabinet />
      </div>
    </div>
  );
};

export default DayStatisticts;
