import { Modal } from 'react-bootstrap';
import { useGetPlayersStatisticQuery } from 'store/services/promos';

import { CabinetSvgSelector } from '../../../../assets/images/icons/CabinetSvgSelector';

const ModalUserInfo = ({ showModalAndPlayerId, setShowModal }) => {
	const { data, isLoading } = useGetPlayersStatisticQuery({
		playerId: showModalAndPlayerId
	});

	if (isLoading) return null;

	if (!data) return null;

	const { campaign, userBalances, transactions, ...rest } = data;

	const entries = data ? Object.entries(rest) : ['No Data'];

	return (
		<Modal
			show={showModalAndPlayerId}
			dialogClassName='modal-promo'
			onHide={() => setShowModal(false)}
			centered
		>
			<div onClick={() => setShowModal(false)} className='modal__close icon'>
				<CabinetSvgSelector id='close' />
			</div>
			<div className='modal__title'>
				Player Id: {showModalAndPlayerId && showModalAndPlayerId.split('-')[0]}
				...
			</div>
			<div className='modal__form modal-form'>
				<div className='modal-form__content'>
					<div className='modal-form__inputs'>
						{entries.map(([name, value]) => {
							let renderValue = value;

							if (typeof value === 'boolean') {
								renderValue = 'true';
								if (value === false) renderValue = 'false';
							}

							return (
								<div>
									<span>
										<strong>{name}:</strong> <span>{renderValue}</span>
									</span>
								</div>
							);
						})}

						{campaign && (
							<div>
								<span>
									<strong>Campaign Id: </strong> <span>{campaign.nanoId}</span>
								</span>
							</div>
						)}

						<div>
							{userBalances &&
								userBalances?.map(({ amount, currency }) => (
									<div>
										<span>
											<strong>{currency}: </strong> <span>{amount}</span>
										</span>
									</div>
								))}
						</div>

						<div>
							{transactions && (
								<div>
									<div>
										<span>
											<strong>Deposits Count: </strong>
											<span>{transactions[1]}</span>
										</span>
									</div>

									<div>
										<span>
											<strong>Deposits Sum: </strong>
											<span>
												{transactions[0].reduce(function (acc, obj) {
													return acc + obj.amount;
												}, 0)}
											</span>
										</span>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ModalUserInfo;
