import dayjs from 'dayjs';
import ModalUserInfo from 'pages/components/modals/ModalUserInfo/ModalPromoEdit';
import { useState } from 'react';
import { useGetPlayersStatisticQuery } from 'store/services/promos';

import PaginationTableCabinet, {
	countPages
} from '../../../components/PaginationTableCabinet/PaginationTableCabinet';

import Header from './Header';
import { PlayersFilters } from './PlayersFilters';

const PlayersStatisticts = () => {
	const [filters, setFilters] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [showModalAndPlayerId, setShowModal] = useState<string | boolean>(
		false
	);

	const { data, isLoading } = useGetPlayersStatisticQuery({
		currentPage,
		...filters
	});

	const count = data?.count;
	const players = data?.users;

	return (
		<div className='statistics'>
			<div className='statistics__head'>
				<PlayersFilters customTitle={'Registration:'} setFilters={setFilters} />
			</div>
			<div className='statistics__body'>
				<div className='table table--large' data-simplebar>
					<table>
						<thead>
							<Header />
						</thead>

						<tbody>
							{players &&
								players.map((player: any) => {
									const lastSessionIndex = player?.sessions?.length - 1;

									const lastSession = player?.sessions[lastSessionIndex];
									const firstSession = player?.sessions[0];

									return (
										<tr>
											<td>
												<a href='#' onClick={() => setShowModal(player.id)}>
													{player.id && player.id.split('-')[0]}
												</a>
											</td>
											<td>{firstSession?.geo}</td>
											<td>{player?.campaign?.nanoId}</td>
											<td>{player.clickId}</td>
											<td>
												{dayjs(player.createdAt).format('YYYY-MM-DD HH:mm')}
											</td>
											<td>
												{dayjs(lastSession?.updatedAt).format(
													'YYYY-MM-DD HH:mm'
												)}
											</td>

											<td>{player?.userStats?.betCount || 0}</td>
											<td>{player?.userStats?.betAmount || 0}</td>

											<td>{player?.userStats?.winCount || 0}</td>
											<td>{player?.userStats?.winAmount || 0}</td>

											<td>{player?.userStats?.loseCount || 0}</td>
											<td>{player?.userStats?.loseAmount || 0}</td>
										</tr>
									);
								})}
						</tbody>
					</table>

					<PaginationTableCabinet
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
						pages={countPages(count || 0, 10)}
						className='main-settings-section__pagination'
					/>
				</div>
			</div>

			{showModalAndPlayerId && (
				<ModalUserInfo
					showModalAndPlayerId={showModalAndPlayerId}
					setShowModal={setShowModal}
				/>
			)}
		</div>
	);
};

export default PlayersStatisticts;
