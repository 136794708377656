import classNames from 'classnames';
import React, { ChangeEventHandler, MouseEventHandler } from 'react';

import { CabinetSvgSelector } from '../../../assets/images/icons/CabinetSvgSelector';
import s from '../Input/Input.module.scss';

interface InputCabinetProps {
	placeholder?: string;
	placeholderDouble?: string;
	required?: boolean;
	type?: string;
	onClick?: MouseEventHandler<HTMLButtonElement>;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	onChangeDouble?: ChangeEventHandler<HTMLInputElement>;
	disabled?: boolean;
	double?: boolean;
	error?: boolean;
	label?: string;
	icon?: string;
	iconBefore?: JSX.Element;
	value?: string;
	valueDouble?: string;
	small?: boolean;
	className?: string;
	button?: boolean;
}

const InputCabinet: React.FC<InputCabinetProps> = ({
	placeholder,
	placeholderDouble,
	required,
	type,
	onClick,
	onChange,
	onChangeDouble,
	disabled,
	double,
	error,
	label,
	icon,
	iconBefore,
	value,
	valueDouble,
	small,
	className,
	button
}) => {
	return (
		<label
			className={classNames('input', `${className}`, {
				'input--small': small,
				'input--icon': error
			})}
		>
			{label && <div className='input__label'>{label}</div>}

			<div className={classNames('input__field', double && s.double)}>
				{!button ? (
					<>
						{iconBefore && <span className={s.iconBefore}>{iconBefore}</span>}
						<input
							type={type || 'text'}
							onChange={onChange}
							disabled={disabled}
							placeholder={placeholder}
							defaultValue={value}
							required={required}
						/>
						{double && (
							<input
								type={type || 'text'}
								onChange={onChangeDouble}
								disabled={disabled}
								placeholder={placeholderDouble}
								defaultValue={valueDouble}
								required={required}
							/>
						)}
						{icon && (
							<button onClick={onClick} className='icon'>
								<CabinetSvgSelector id={icon} />
							</button>
						)}
					</>
				) : (
					<div className={classNames('input__wrapper', double && s.double)}>
						{iconBefore && <span className={s.iconBefore}>{iconBefore}</span>}
						<input
							type={type || 'text'}
							onChange={onChange}
							disabled={disabled}
							placeholder={placeholder}
							defaultValue={value}
							required={required}
						/>
						{double && (
							<input
								type={type || 'text'}
								onChange={onChangeDouble}
								disabled={disabled}
								placeholder={placeholderDouble}
								defaultValue={valueDouble}
								required={required}
							/>
						)}
						{icon && (
							<button onClick={onClick} className='input__button'>
								<CabinetSvgSelector id={icon} />
							</button>
						)}
					</div>
				)}
			</div>

			{error && <span className={s.error}>Please, fill out this field</span>}
		</label>
	);
};

export default InputCabinet;
