import React from 'react';
import { SVGProps } from 'react';

const EmailIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={15}
		height={15}
		viewBox='0 0 15 15'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path d='M7.9032 8.04976C7.78316 8.13776 7.64157 8.18172 7.50002 8.18172C7.35839 8.18172 7.21684 8.13776 7.0968 8.04976L1.36363 3.84546L4.54544e-05 2.8455L0 12.4999C4.54544e-05 12.8765 0.305272 13.1817 0.681816 13.1817L14.3182 13.1817C14.6948 13.1817 15 12.8764 15 12.4999V2.84546L13.6363 3.84546L7.9032 8.04976Z' />
		<path d='M7.50123 6.65446L14.096 1.81816L0.90625 1.81812L7.50123 6.65446Z' />
	</svg>
);
export default EmailIcon;
