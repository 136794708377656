import React from 'react';

import s from './Social.module.scss';

import telegram from '../../../assets/images/social/telegram.svg';
import instagram from '../../../assets/images/social/Instagram.svg';
import mail from '../../../assets/images/social/mail.svg';

const Social = () => {
  const social = [
    {
      blank: true,
      icon: telegram,
      link: '/',
    },
    {
      blank: true,
      icon: instagram,
      link: '/',
    },
    {
      blank: false,
      icon: mail,
      link: 'mailto:binobi@gmail.com',
    },
  ];

  return (
    <div className={s.social}>
      <ul>
        {social &&
          social.map((item, index) => (
            <li key={index}>
              {item.blank ? (
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  <img src={item.icon} alt="" />
                </a>
              ) : (
                <a href={item.link}>
                  <img src={item.icon} alt="" />
                </a>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Social;
