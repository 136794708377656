import classNames from 'classnames';
import React, { MouseEvent } from 'react';

import { GlobalSvgSelector } from '../../../assets/images/icons/GlobalSvgSelector';

import s from './Button.module.scss';

interface ButtonProps {
	children: React.ReactNode;
	href?: string;
	type?: 'button' | 'submit' | 'reset';
	minor?: boolean;
	onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
	disabled?: boolean;
	small?: boolean;
	icon?: string;
	iconBefore?: JSX.Element;
	className?: string;
}

export const Button: React.FC<ButtonProps> = ({
	children,
	className,
	href,
	type = 'button',
	minor,
	onClick,
	disabled,
	small,
	icon,
	iconBefore
}) => {
	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		if (disabled) {
			event.preventDefault();
			return;
		}
		if (onClick) {
			onClick(event);
		}
	};

	const buttonClassNames = classNames(s.button, className, {
		'button--disabled': disabled,
		'button--minor': minor,
		'button--small': small,
		'button--icon': icon
	});

	return (
		<>
			{!href ? (
				<button type={type} onClick={handleClick} className={buttonClassNames}>
					{iconBefore && iconBefore}
					<span>{children}</span>
					{icon && <GlobalSvgSelector id={icon} />}
				</button>
			) : (
				<a
					target='_blank'
					rel='noopener noreferrer'
					href={href}
					className={buttonClassNames}
				>
					{iconBefore && iconBefore}

					<span>{children}</span>
					{icon && <GlobalSvgSelector id={icon} />}
				</a>
			)}
		</>
	);
};
