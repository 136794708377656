// import { Element } from 'react-scroll';
import { ParallaxProvider } from 'react-scroll-parallax';

import Contacts from '../Home/Contacts/Contacts';
import Hero from '../Info/Hero/Hero';

import Agreement from './Agreement/Agreement';

const AgreementPage = () => {
	return (
		<ParallaxProvider>
			<Hero />
			<Agreement />
		</ParallaxProvider>
	);
};

export default AgreementPage;
