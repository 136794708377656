import React from 'react';
import { SVGProps } from 'react';

const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={800}
		height={800}
		viewBox='0 0 800 800'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M176.43 176.43C189.447 163.412 210.553 163.412 223.57 176.43L400 352.859L576.43 176.43C589.447 163.412 610.553 163.412 623.57 176.43C636.588 189.447 636.588 210.553 623.57 223.57L447.14 400L623.57 576.43C636.588 589.447 636.588 610.553 623.57 623.57C610.553 636.588 589.447 636.588 576.43 623.57L400 447.14L223.57 623.57C210.553 636.588 189.447 636.588 176.43 623.57C163.412 610.553 163.412 589.447 176.43 576.43L352.859 400L176.43 223.57C163.412 210.553 163.412 189.447 176.43 176.43Z'
		/>
	</svg>
);
export default CloseIcon;
