import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { store } from 'store';

import App from './App';
import i18n from './i18n';
import './styles/cabinet.scss';
import './styles/index.scss';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<Provider store={store}>
		<Suspense fallback=''>
			<I18nextProvider i18n={i18n}>
				<BrowserRouter>
					<App />
					<ToastContainer theme={'dark'} />
				</BrowserRouter>
			</I18nextProvider>
		</Suspense>
	</Provider>
);
