import binobiBet from 'assets/images/logos/binobi_bet.svg';
import {
	OfferDomainLabels,
	OfferPaymentModel,
	OfferPaymentModelLabels,
	TOffer
} from 'store/services/offers';
import { countriesObject } from 'utils/getLanguageIcon';

export const OfferCard = ({
	item,
	selectedOffer,
	setSelectedOffer
}: {
	item: TOffer;
	selectedOffer: TOffer | undefined;
	setSelectedOffer: (v: TOffer) => void;
}) => (
	<label key={item.id} className='promo-offer-column__item promo-offer-item'>
		<div className='promo-offer-item__content'>
			<div className='promo-offer-item__wrapper'>
				<div className='promo-offer-item__logo'>
					<img src={binobiBet} alt='' />
				</div>
				<div className='promo-offer-item__list'>
					<ul>
						<li>
							<span>Payment model:</span>&nbsp;
							<span>{OfferPaymentModelLabels[item.paymentModel]}</span>
						</li>
					</ul>

					<ul>
						<li>
							<span>Goal:</span>&nbsp;
							<span>{`${item.goal} ${
								item.paymentModel === OfferPaymentModel.CPA ? '$' : '%'
							}`}</span>
						</li>
					</ul>
				</div>
			</div>
			<div className='promo-offer-item__languages'>
				<ul>
					<li>
						<img
							style={{ width: '30px', height: '30px', borderRadius: '15px' }}
							//@ts-ignore
							src={countriesObject[item?.geo as any]?.icon}
							alt={item.geo}
							loading='eager'
						/>
					</li>
				</ul>

				{/* {item.geo} */}
			</div>
		</div>
		<input
			type='radio'
			name='promo-offer-betting'
			value={selectedOffer?.id === item.id ? 'a' : ''}
			onClick={() => setSelectedOffer(item)}
		/>
		<div className='promo-offer-item__selected'>
			<span>Select</span>
		</div>
	</label>
);

export const OfferColumn = ({
	domain,
	offers,
	selectedOffer,
	setSelectedOffer
}: {
	domain: string;
	offers: TOffer[];
	selectedOffer: TOffer | undefined;
	setSelectedOffer: (v: TOffer) => void;
}) => (
	<div className='promo-offer__column promo-offer-column'>
		<div className='promo-offer-column__title title-section'>
			{OfferDomainLabels[domain]}
		</div>
		<div className='promo-offer-column__items'>
			{offers?.map((item) => (
				<OfferCard
					item={item}
					selectedOffer={selectedOffer}
					setSelectedOffer={setSelectedOffer}
				/>
			))}
		</div>
	</div>
);
