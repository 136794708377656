import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../';

export type User = {
	email: string;
	avatarUrl: '';
	bans: [];
	createdAt: Date;
	declined: boolean;
	id: string;
	isPartner: boolean;
	isPartnerConfirmed: boolean;
	is_deleted: boolean;
	lastAuth: boolean;
	phoneNumber: string;
	role: 'user';
	secret: boolean;
	username: string;
	verification: string;
	commands: any[];
	name: string;
	messenger: string;
	messengerType: string;
};

type AuthState = {
	user: User | null;
};

const authSlice = createSlice({
	name: 'auth',
	initialState: { user: null } as AuthState,
	reducers: {
		setCredentials: (state, { payload }: PayloadAction<User>) => {
			state.user = payload;
		},
		setUser: (state, { payload }: PayloadAction<User>) => {
			state.user = payload;
		},
		clearUser: (state) => {
			localStorage.removeItem('token');
			state.user = null;
		}
	}
});

export const { setCredentials, setUser, clearUser } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
