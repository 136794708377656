import React from 'react';
import { Accordion } from 'react-bootstrap';

interface IFaqItem {
	title: string;
	text: string;
}

const FaqSettings = () => {
	const items: IFaqItem[] = [
		{
			title: ` What is Binobi Partners affiliate program?`,
			text: ` Binobi Partners Partners – an affiliate program of Binobi Partners Bet and Binobi Partners Casino products.
      Once registered, You will be able to easily start off with converting your traffic!
      A vast pool of our payment methods is ready to process any amount of your earned
      financials!`
		},
		{
			title: ` What traffic KPI’s do we rely on?`,
			text: `Binobi Partners Partners – an affiliate program of Binobi Partners Bet and Binobi Partners Casino products.
      Once registered, You will be able to easily start off with converting your traffic!
      A vast pool of our payment methods is ready to process any amount of your earned
      financials!`
		},
		{
			title: ` How often do we pay?`,
			text: `Binobi Partners Partners – an affiliate program of Binobi Partners Bet and Binobi Partners Casino products.
      Once registered, You will be able to easily start off with converting your traffic!
      A vast pool of our payment methods is ready to process any amount of your earned
      financials!`
		},
		{
			title: `What is Binobi Partners affiliate program?`,
			text: `Binobi Partners Partners – an affiliate program of Binobi Partners Bet and Binobi Partners Casino products.
      Once registered, You will be able to easily start off with converting your traffic!
      A vast pool of our payment methods is ready to process any amount of your earned
      financials!`
		},
		{
			title: ` What traffic KPI’s do we rely on?`,
			text: `Binobi Partners Partners – an affiliate program of Binobi Partners Bet and Binobi Partners Casino products.
      Once registered, You will be able to easily start off with converting your traffic!
      A vast pool of our payment methods is ready to process any amount of your earned
      financials!`
		},
		{
			title: `How often do we pay?`,
			text: `Binobi Partners Partners – an affiliate program of Binobi Partners Bet and Binobi Partners Casino products.
      Once registered, You will be able to easily start off with converting your traffic!
      A vast pool of our payment methods is ready to process any amount of your earned
      financials!`
		}
	];
	const itemsTwo: IFaqItem[] = [
		{
			title: ` What is Binobi Partners affiliate program?`,
			text: ` Binobi Partners Partners – an affiliate program of Binobi Partners Bet and Binobi Partners Casino products.
      Once registered, You will be able to easily start off with converting your traffic!
      A vast pool of our payment methods is ready to process any amount of your earned
      financials!`
		},
		{
			title: ` What traffic KPI’s do we rely on?`,
			text: `Binobi Partners Partners – an affiliate program of Binobi Partners Bet and Binobi Partners Casino products.
      Once registered, You will be able to easily start off with converting your traffic!
      A vast pool of our payment methods is ready to process any amount of your earned
      financials!`
		},
		{
			title: ` How often do we pay?`,
			text: `Binobi Partners Partners – an affiliate program of Binobi Partners Bet and Binobi Partners Casino products.
      Once registered, You will be able to easily start off with converting your traffic!
      A vast pool of our payment methods is ready to process any amount of your earned
      financials!`
		},
		{
			title: `What is Binobi Partners affiliate program?`,
			text: `Binobi Partners Partners – an affiliate program of Binobi Partners Bet and Binobi Partners Casino products.
      Once registered, You will be able to easily start off with converting your traffic!
      A vast pool of our payment methods is ready to process any amount of your earned
      financials!`
		},
		{
			title: ` What traffic KPI’s do we rely on?`,
			text: `Binobi Partners Partners – an affiliate program of Binobi Partners Bet and Binobi Partners Casino products.
      Once registered, You will be able to easily start off with converting your traffic!
      A vast pool of our payment methods is ready to process any amount of your earned
      financials!`
		},
		{
			title: `How often do we pay?`,
			text: `Binobi Partners Partners – an affiliate program of Binobi Partners Bet and Binobi Partners Casino products.
      Once registered, You will be able to easily start off with converting your traffic!
      A vast pool of our payment methods is ready to process any amount of your earned
      financials!`
		}
	];

	return (
		<div className='faq'>
			<div className='faq__title title-section'>FAQ</div>
			<div className='faq__wrapper'>
				<div className='faq__accordions-wrapper'>
					<Accordion
						className='faq__accordions accordions'
						defaultActiveKey={'0'}
					>
						{items &&
							items.map((item, index) => (
								<Accordion.Item
									className='accordion-custom'
									key={index}
									eventKey={index.toString()}
								>
									<Accordion.Header>
										<div className='trigger-icon'></div>
										{item.title}
									</Accordion.Header>
									<Accordion.Body>{item.text}</Accordion.Body>
								</Accordion.Item>
							))}
					</Accordion>
					<Accordion className='faq__accordions accordions'>
						{itemsTwo &&
							itemsTwo.map((item, index) => (
								<Accordion.Item
									className='accordion-custom'
									key={index}
									eventKey={index.toString()}
								>
									<Accordion.Header>
										<div className='trigger-icon'></div>
										{item.title}
									</Accordion.Header>
									<Accordion.Body>{item.text}</Accordion.Body>
								</Accordion.Item>
							))}
					</Accordion>
				</div>
			</div>
		</div>
	);
};

export default FaqSettings;
