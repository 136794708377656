import classNames from 'classnames';
import CreateTicketModal from 'pages/components/modals/CreateTicketModal/CreateTicketModal';
import React, { FC, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { ChevronDownIcon, MessageIcon } from '../../../../assets/svg';

import s from './SupportDropdown.module.scss';

interface ISupportDropdown {
	className?: string;
}

const SupportDropdown: FC<ISupportDropdown> = ({ className }) => {
	const [showCreateTicketModal, setShowCreateTicketModal] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const handleCopyClick = (text: string) => {
		toast.success('Copied!');
		navigator.clipboard.writeText(text);
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className={classNames(s.balance, className)} ref={dropdownRef}>
			<button
				className={classNames(s.trigger, isOpen && s.open)}
				onClick={() => setIsOpen(!isOpen)}
			>
				<MessageIcon />
				<span>Manager</span>
				<ChevronDownIcon className={classNames(s.arrow, isOpen && s.open)} />
			</button>
			{isOpen && (
				<div className={s.dropdown}>
					<div className='navbar__section navbar-section'>
						<div className='navbar-section__head'>
							<div className='navbar-section__title'>
								Your personal manager:
							</div>
						</div>
						<div className='navbar-section__body'>
							<div className='navbar-section__manager'>
								<ul>
									<li>
										<small>Name: </small>
										<span>Roman Tsyganov</span>
									</li>
									<li>
										<small>Telegram: </small>
										<button
											title='Copy telegram'
											onClick={() => handleCopyClick('@binobi_affiliates')}
										>
											@binobi_affiliates
										</button>
									</li>
									<li>
										<small>E-mail</small>
										<button
											title='Copy e-mail'
											onClick={() => handleCopyClick('affiliates@binobi.com')}
										>
											affiliates@binobi.com
										</button>
									</li>
								</ul>
							</div>
							<button
								className={classNames(s.create, 'btn-copy')}
								onClick={() => setShowCreateTicketModal(true)}
							>
								Create ticket
							</button>
						</div>
					</div>
				</div>
			)}
			<CreateTicketModal
				showCreateTicketModal={showCreateTicketModal}
				setShowCreateTicketModal={setShowCreateTicketModal}
			/>
		</div>
	);
};

export default SupportDropdown;
