import { getMe } from 'api/requests/auth';
import Dropdown from 'pages/components/Dropdown/Dropdown';
import { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useEditProfileMutation } from 'store/services/users';
import { User, setUser } from 'store/slices/auth';
import { Messengers } from 'types/auth';

import { CloseIcon } from '../../../../assets/svg';
import { Button } from '../../Button/Button';
import Input from '../../Input/Input';
import s from '../ModalLogin/ModalLogin.module.scss';
import { optionsSocials } from '../ModalRegistration/ModalRegistration';

interface IEditProfileModal {
	user: User | null;
	showEditProfileModal: boolean;
	setShowEditProfileModal: (show: boolean) => void;
}

interface EditProfileFormData {
	name?: string;
	email?: string;
	messenger?: string;
	messengerType?: string;
	phoneNumber?: string;
}

const EditProfileModal: FC<IEditProfileModal> = ({
	user,
	showEditProfileModal,
	setShowEditProfileModal
}) => {
	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors }
	} = useForm<EditProfileFormData>({
		defaultValues: {
			name: user?.name,
			email: user?.email,
			messenger: user?.messenger,
			phoneNumber: user?.phoneNumber,
			messengerType: user?.messengerType
		}
	});

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [editProfile] = useEditProfileMutation();

	const editProfileAsync = async (data: EditProfileFormData) => {
		await editProfile({ ...data });

		const user = await getMe();

		if (user) dispatch(setUser(user));
	};

	const onSubmit = (data: EditProfileFormData) => {
		console.log(data);

		editProfileAsync(data);
		setShowEditProfileModal(false);
	};

	return (
		<Modal
			show={showEditProfileModal}
			dialogClassName={s.modal}
			onHide={() => setShowEditProfileModal(false)}
			centered
		>
			<div className={s.inner}>
				<div className={s.close} onClick={() => setShowEditProfileModal(false)}>
					<CloseIcon />
				</div>
				<div className={s.wrapper}>
					<div className={s.authTabs}>
						<h2 className={s.title}> Editing profile</h2>
					</div>
					<form className={s.form} onSubmit={handleSubmit(onSubmit)}>
						<div className={s.inputs}>
							<Input
								placeholder='Name'
								name='name'
								register={register}
								validationSchema={{ value: user?.name }}
							/>
							<Input
								type='tel'
								placeholder={t('modals.modalReg.inputs.input3.label') as string}
								name='phoneNumber'
								register={register}
								validationSchema={{
									required: 'Phone is required',
									value: user?.phoneNumber
								}}
							/>
							{errors.phoneNumber && <span>This field is required</span>}

							<Input
								type='email'
								placeholder={t('modals.modalReg.inputs.input2.label') as string}
								name='email'
								register={register}
								validationSchema={{
									required: 'Email is required',
									value: user?.email,

									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
										message: 'Invalid email address'
									}
								}}
							/>
							{errors.email && <span>This field is required</span>}
							{/* <div className={s.subscription}>
								<label className='settings-section__checkbox checkbox'>
									<input type='checkbox' />
									<span>E-mail subscription</span>
								</label>
							</div> */}
							{/* <Datepicker className={s.datepicker} isSoloDate /> */}
							{/* <Input placeholder='Address' name='address' register={register} /> */}
							{/* <Input
								placeholder='Telegram'
								name='telegram'
								register={register}
							/> */}

							<div className={s.socials}>
								<Dropdown
									onSelect={(v) => setValue('messengerType', v as any)}
									className={s.dropdown}
									triggerClassName={s.dropdown__trigger}
									options={optionsSocials}
									defaultValue={user?.messengerType}
								/>
								<Input
									icon='link'
									placeholder={
										t('modals.modalReg.inputs.input5.placeholder') as string
									}
									name='messenger'
									register={register}
									validationSchema={{
										required: 'Messenger is required',
										value: user?.messenger
									}}
								/>
							</div>

							{/* <Input placeholder='Skype' name='skype' register={register} /> */}
						</div>
						<div className={s.button}>
							<Button type='submit'>Save</Button>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default EditProfileModal;
