import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

import { CabinetSvgSelector } from '../../../assets/images/icons/CabinetSvgSelector';
import {
	ChevronDownIcon,
	MobileIcon,
	QuestionCircle
} from '../../../assets/svg';
import useScrollBlock from '../../../hooks/useScrollBlock';

import s from './NavigationCabinet.module.scss';

interface NavigationItem {
	icon?: string;
	iconJsx?: JSX.Element;
	title: string;
	link?: string;
	ul?: SubNavigationItem[];
}
interface SubNavigationItem {
	icon: string;
	title: string;
	link: string;
}

interface DropdownMenuProps {
	item: NavigationItem;
}

interface INavigationCabinet {
	className?: string;
}

const NavigationCabinet: React.FC<INavigationCabinet> = ({ className }) => {
	const navigation: NavigationItem[] = [
		{
			icon: `video_marketing`,
			title: `Promo`,
			ul: [
				{
					icon: `video_marketing`,
					title: `Create new promo`,
					link: `/promo/create`
				},
				{
					icon: `person`,
					title: `My promo`,
					link: `/promo/my`
				}
				// {
				// 	icon: `person_add`,
				// 	title: `Personal promo`,
				// 	link: `/promo/personal`
				// }
			]
		},
		{
			iconJsx: <MobileIcon />,
			title: `Application`,
			link: `/application`
		},
		{
			icon: `statistics`,
			title: `Statistics`,
			ul: [
				// {
				// 	icon: `statistics`,
				// 	title: `General`,
				// 	link: `/statistics/general`
				// },
				{
					icon: `statistics_comp`,
					title: `Promo`,
					link: `/promo/my/statistic/`
				},
				// {
				// 	icon: `person`,
				// 	title: `Referral`,
				// 	link: `/statistics/referral`
				// },
				{
					icon: `statistics_list`,
					title: `Players statistics`,
					link: `/statistics/players`
				}
				// {
				// 	icon: `statistics_negative`,
				// 	title: `Negative carryover`,
				// 	link: `/statistics/negative-carryover`
				// },
				// {
				// 	icon: `statistics_analysis`,
				// 	title: `Cohort analysis`,
				// 	link: `/statistics/cohort-analysis`
				// },
				// {
				// 	icon: `calendar`,
				// 	title: `By day`,
				// 	link: `/statistics/day`
				// }
			]
		},
		{
			icon: `coins`,
			title: `Finance`,
			link: `/finance`
		},
		// {
		// 	icon: `news`,
		// 	title: `News`,
		// 	ul: [
		// 		{
		// 			icon: `news`,
		// 			title: `News`,
		// 			link: `/news`
		// 		},
		// 		{
		// 			icon: `bell`,
		// 			title: `Notifications`,
		// 			link: `/notifications`
		// 		}
		// 	]
		// },
		{
			iconJsx: <QuestionCircle />,
			title: `Support`,
			link: `/support`
		}
	];

	return (
		<div className={classNames(s.navigation, className)}>
			<nav aria-label='navigation'>
				<ul>
					{navigation.map((item, index) => (
						<React.Fragment key={`${item.title}_${index}`}>
							<DropdownMenu item={item} />
						</React.Fragment>
					))}
				</ul>
			</nav>
		</div>
	);
};

const DropdownMenu: React.FC<DropdownMenuProps> = ({ item }) => {
	const location = useLocation();
	const [blockScroll, allowScroll] = useScrollBlock();

	const [searchParams, setSearchParams] = useSearchParams();

	const removeErrorParam = () => {
		if (searchParams.has('error')) {
			searchParams.delete('error');
			setSearchParams(searchParams);
		}
	};
	const [showDropdownMenu, setShowDropdownMenu] = useState(false);
	const hideDropdownMenu = () => {
		setShowDropdownMenu(false);
	};
	const refDropdownMenu = useRef<HTMLLIElement>(null);

	const hideMenuAndDropdown = () => {
		removeErrorParam();
		hideDropdownMenu();
		allowScroll();
	};

	const handleOutsideClickDropdownMenu = (event: MouseEvent) => {
		if (
			refDropdownMenu.current &&
			!refDropdownMenu.current.contains(event.target as Node)
		) {
			setShowDropdownMenu(false);
			allowScroll();
		}
	};

	useEffect(() => {
		document.body.addEventListener('click', handleOutsideClickDropdownMenu);
		return () => {
			document.body.removeEventListener(
				'click',
				handleOutsideClickDropdownMenu
			);
		};
	}, []);

	return (
		<li
			onClick={() => setShowDropdownMenu(!showDropdownMenu)}
			className={location.pathname === item.link ? s.current : ''}
			ref={refDropdownMenu}
		>
			{item.ul ? (
				<span className={classNames(s.trigger, showDropdownMenu && s.open)}>
					{item.iconJsx ? (
						item.iconJsx
					) : item.icon ? (
						<CabinetSvgSelector id={item.icon} />
					) : (
						''
					)}
					{item.title}
					<ChevronDownIcon
						className={classNames(s.arrow, showDropdownMenu && s.open)}
					/>
				</span>
			) : (
				// @ts-ignore
				<Link to={item.link} onClick={hideMenuAndDropdown}>
					{item.iconJsx ? (
						item.iconJsx
					) : item.icon ? (
						<CabinetSvgSelector id={item.icon} />
					) : (
						''
					)}
					{item.title}
				</Link>
			)}
			{showDropdownMenu && item.ul && (
				<ul>
					{item.ul.map((subitem, index) => (
						<li
							key={`${subitem.title}_${index}`}
							className={location.pathname === subitem.link ? s.current : ''}
						>
							<Link to={subitem.link} onClick={hideMenuAndDropdown}>
								{subitem.title}
							</Link>
						</li>
					))}
				</ul>
			)}
		</li>
	);
};

export default NavigationCabinet;
