import dayjs from 'dayjs';
import { Button } from 'pages/components/Button/Button';
import CreateWithdrawModal from 'pages/components/modals/CreateWithdraw/CreateWithdrawModal';
import { FC, useState } from 'react';
import {
	useGetPartnerBalanceQuery,
	useGetPartnerPaymentsQuery
} from 'store/services/personal-partner';

import PaginationTableCabinet, {
	countPages
} from '../../components/PaginationTableCabinet/PaginationTableCabinet';

import s from './Finance.module.scss';

interface IFinance {}

const Finance: FC<IFinance> = () => {
	const [currentPage, setCurrentPage] = useState(1);

	const [showCreateWithdrawModal, setShowCreateWithdrawModal] = useState(false);

	const { data: partnerBalance } = useGetPartnerBalanceQuery({});
	const { data: partnerPayments } = useGetPartnerPaymentsQuery({ currentPage });

	return (
		<div className='finance'>
			<div className={s.balances}>
				<div className={s.balances__title}>Balances</div>
				<div className={s.balances__wrapper}>
					<div>
						<span>Total</span>
						<strong>${partnerBalance?.balance?.amount || 0}</strong>
					</div>

					<Button
						className={s.balances__wrapper__withdraw}
						onClick={() => setShowCreateWithdrawModal(true)}
					>
						Withdraw
					</Button>
				</div>
			</div>
			<div className={s.content}>
				<div className={s.subtitle}>Payment history</div>
				<div className='finance__head'></div>
				<div className='finance__body'>
					<div className='table' data-simplebar>
						<div className='table__empty'>No Data</div>
						<table>
							<thead>
								<tr>
									<td>ID</td>
									<td>Amount</td>
									<td>Status</td>
									<td>Comment</td>
									<td>Date</td>
								</tr>
							</thead>
							<tbody>
								{partnerPayments &&
									partnerPayments[0].map(
										({ amount, createdAt, id, status, comment }: any) => (
											<tr>
												<td>{id}</td>
												<td>{amount}</td>
												<td>{status}</td>
												<td>{comment}</td>
												<td>{dayjs(createdAt).format('YYYY-MM-DD HH-mm')}</td>
											</tr>
										)
									)}
								<tr>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>
					{partnerPayments && (
						<PaginationTableCabinet
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							pages={countPages(partnerPayments[1] || 0, 10)}
							className='main-settings-section__pagination'
						/>
					)}
				</div>
			</div>

			<CreateWithdrawModal
				showCreateWithdrawModal={showCreateWithdrawModal}
				setShowCreateWithdrawModal={setShowCreateWithdrawModal}
			/>
		</div>
	);
};

export default Finance;
