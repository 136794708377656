import React from 'react';
import { Modal } from 'react-bootstrap';

import { CabinetSvgSelector } from '../../../../assets/images/icons/CabinetSvgSelector';
import InputCabinet from '../../InputCabinet/InputCabinet';

const ModalPromoCreate = ({ showModalPromoCreate, setShowModalPromoCreate }) => {
  return (
    <Modal
      show={showModalPromoCreate}
      dialogClassName="modal-promo"
      onHide={() => setShowModalPromoCreate(false)}
      centered>
      <div onClick={() => setShowModalPromoCreate(false)} className="modal__close icon">
        <CabinetSvgSelector id="close" />
      </div>
      <div className="modal__title">Promo Create</div>
      <div className="modal__form modal-form">
        <form action="" method="post">
          <div className="modal-form__content">
            <div className="modal-form__inputs">
              <InputCabinet
                className="modal-form__input"
                label="Promo Name (Not necessary)"
                placeholder="Promo Name"
                value="Binobi.casino.CL"
                required
              />
              <InputCabinet
                className="modal-form__input"
                label="StreamID"
                placeholder="StreamID"
                value="edkns7kc"
                required
              />
              <InputCabinet
                className="modal-form__input"
                label="Type traffic source"
                placeholder="Type traffic source"
                value="FB to webview"
                required
              />
              <InputCabinet
                className="modal-form__input"
                placeholder="SublD1"
                button
                icon="add"
                required
              />
              <InputCabinet
                className="modal-form__input"
                label="Link to Promo"
                icon="link"
                placeholder="Link"
                value="https://2020pnpbit.com/edkns7kc/"
                onClick={() => navigator.clipboard.writeText('https://2020pnpbit.com/edkns7kc/')}
                required
              />
            </div>
            <div className="modal-form__button">
              <button onClick={() => setShowModalPromoCreate(false)} className="btn">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ModalPromoCreate;
