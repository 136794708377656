import React from 'react';
import { useNavigate } from 'react-router-dom';

import { CabinetSvgSelector } from '../../../assets/images/icons/CabinetSvgSelector';

interface BackButtonCabinetProps {
	text?: string;
	className?: string;
}

const BackButtonCabinet: React.FC<BackButtonCabinetProps> = ({
	text,
	className
}) => {
	const navigate = useNavigate();

	return (
		<button
			onClick={() => navigate(-1)}
			className={`application__back back-link ${className}`}
		>
			<div className='icon'>
				<CabinetSvgSelector id='arrow_prev' />
			</div>
			{text || 'Back'}
		</button>
	);
};

export default BackButtonCabinet;
