import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	Tooltip
} from 'chart.js';
import dayjs from 'dayjs';
import { Line } from 'react-chartjs-2';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: 'bottom'
		}
	}
};

export function Chart({ rangesPromo }) {
	let labels = [];

	if (!rangesPromo) return null;

	if (rangesPromo[0].length > rangesPromo[1].length) {
		labels = rangesPromo[0]?.map(({ date }, index) => {
			const firstDate = dayjs(date).format('MM.DD');

			const findArrayIndex = rangesPromo[1][index];

			const secondDate = findArrayIndex
				? `/${dayjs(findArrayIndex.date).format('MM.DD')}`
				: '';

			return `${firstDate}${`/${secondDate}`}`;
		});
	} else {
		labels = rangesPromo[1]?.map(({ date }, index) => {
			const firstDate = dayjs(date).format('MM.DD');

			const findArrayIndex = rangesPromo[0][index];

			const secondDate = findArrayIndex
				? `/${dayjs(findArrayIndex.date).format('MM.DD')}`
				: '';

			return `${firstDate}${`${secondDate}`}`;
		});
	}

	if (rangesPromo) {
		const data = {
			labels,
			datasets: [
				{
					id: 1,
					label: 'Current period',
					data: rangesPromo[0]?.map(({ sum }) => sum),
					borderColor: '#FCD535',
					backgroundColor: '#FCD535'
				},
				{
					id: 2,
					label: 'Compare to period',
					data: rangesPromo[1]?.map(({ sum }) => sum),
					borderColor: '#26AD56',
					backgroundColor: '#26AD56'
				}
			]
		};

		return <Line options={options} data={data} />;
	}
}
