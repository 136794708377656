import React from 'react';

import InputCabinet from '../../components/InputCabinet/InputCabinet';
import MySelect from '../../components/MySelect';
import PaginationTableCabinet from '../../components/PaginationTableCabinet/PaginationTableCabinet';
import PeriodSorting from '../components/PeriodSorting';

const ReferralStatisticts = () => {
  const optionsOffers = [
    { value: 'All', label: 'All' },
    { value: 'Detailed', label: 'Detailed' },
    { value: 'SportRS', label: 'Sport RS' },
    { value: 'CasinoRS', label: 'Casino RS' },
    { value: 'OUTCasinoCPA', label: 'OUT Casino CPA' },
  ];

  return (
    <div className="statistics">
      <PeriodSorting className="statistics__period" />

      <div className="statistics__info-text">
        If any affiliate comes from your link - you would earn for <b>3%</b> from his revenue!
      </div>
      <div className="statistics__head">
        <div className="statistics__head-inner">
          <div className="statistics__control statistics-control">
            <div className="statistics-control__select select">
              <span>Offers</span>
              <MySelect
                options={optionsOffers}
                className="statistics-control__select"
                defaultValue={optionsOffers[0]}
              />
            </div>
          </div>
          <InputCabinet
            className="statistics__head-referral"
            label="Referral link"
            placeholder="Referral link"
            icon="link"
            value="https://2020pnpbit.com/edkns7kc/"
            onClick={() => navigator.clipboard.writeText('https://2020pnpbit.com/edkns7kc/')}
            small
          />

          <div className="statistics__head-buttons">
            <div className="statistics__head-button">
              <a href="#" className="btn">
                Create
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="statistics__body">
        <div className="table" data-simplebar>
          <div className="table__empty">No Data</div>
          <table>
            <thead>
              <tr>
                <td>Landing name</td>
                <td>Prelanding name</td>
                <td>Stream</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <PaginationTableCabinet />
      </div>
    </div>
  );
};

export default ReferralStatisticts;
