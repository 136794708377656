import classNames from 'classnames';
import i18next from 'i18next';
import React, { useEffect, useRef, useState } from 'react';

import ru from '../../../assets/images/flags/russia.png';
import ua from '../../../assets/images/flags/ua_flag.svg';
import us from '../../../assets/images/flags/us_flag.svg';
import { GlobalSvgSelector } from '../../../assets/images/icons/GlobalSvgSelector';

import s from './Language.module.scss';

interface LanguageProps {
	reverse?: boolean;
	small?: boolean;
	isCabinet?: boolean;
}

interface LanguageOption {
	id: string;
	icon: string;
	label: string;
}

const languages: LanguageOption[] = [
	{ id: 'en', icon: us, label: 'English' },
	{ id: 'uk', icon: ua, label: 'Українська' },
	{ id: 'ru', icon: ru, label: 'Русский' }
];

const getSelectedLanguageFromLocalStorage = (): LanguageOption | undefined => {
	const storedLanguage = localStorage.getItem('selectedLanguage');
	if (storedLanguage) {
		return languages.find((language) => language.id === storedLanguage);
	}
	return undefined;
};

const Language: React.FC<LanguageProps> = ({ reverse, small, isCabinet }) => {
	const [languageDropdown, setLanguageDropdown] = useState(false);
	const [currentLanguage, setCurrentLanguage] = useState(
		getSelectedLanguageFromLocalStorage() || languages[0]
	);

	const refLanguageDropdown = useRef<HTMLDivElement>(null);

	const handleOutsideClicksetLanguageDropdown = (event: MouseEvent) => {
		if (
			refLanguageDropdown.current &&
			!refLanguageDropdown.current.contains(event.target as Node)
		) {
			setLanguageDropdown(false);
		}
	};

	useEffect(() => {
		document.body.addEventListener(
			'click',
			handleOutsideClicksetLanguageDropdown
		);
		return () => {
			document.body.removeEventListener(
				'click',
				handleOutsideClicksetLanguageDropdown
			);
		};
	}, []);

	useEffect(() => {
		i18next.changeLanguage(currentLanguage.id);
		localStorage.setItem('selectedLanguage', currentLanguage.id);
	}, [currentLanguage]);

	const changeLanguage = (languageId: string) => {
		const selectedLanguage =
			languages.find((language) => language.id === languageId) || languages[0];
		setCurrentLanguage(selectedLanguage);
		setLanguageDropdown(false);
	};

	return (
		<div
			ref={refLanguageDropdown}
			className={classNames(s.language, {
				'language--small': small,
				'language--reverse': reverse,
				'language--cabinet': isCabinet
			})}
		>
			<div
				className={s.trigger}
				onClick={() => setLanguageDropdown(!languageDropdown)}
			>
				<div className={s.trigger__icon}>
					<img src={currentLanguage.icon} alt='' />
				</div>
				<div
					className={
						languageDropdown
							? classNames(s.trigger__arrow, s.active)
							: s.trigger__arrow
					}
				>
					<GlobalSvgSelector id='chevron_down' />
				</div>
			</div>
			{languageDropdown && (
				<div className={s.dropdown}>
					<ul>
						{languages.map((language) => (
							<li
								key={language.id}
								className={currentLanguage.id === language.id ? s.active : ''}
								onClick={() => changeLanguage(language.id)}
							>
								<div className={s.item}>
									<div className={s.trigger__icon}>
										<img src={language.icon} alt='' />
									</div>
									{language.label}
								</div>
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default Language;
