import React from 'react';
import { SVGProps } from 'react';

const BurgerMenuIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={800}
		height={800}
		viewBox='0 0 800 800'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M100 600C100 581.59 114.924 566.667 133.333 566.667H666.667C685.076 566.667 700 581.59 700 600C700 618.409 685.076 633.333 666.667 633.333H133.333C114.924 633.333 100 618.409 100 600Z'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M100 400C100 381.59 114.924 366.667 133.333 366.667H666.667C685.076 366.667 700 381.59 700 400C700 418.409 685.076 433.333 666.667 433.333H133.333C114.924 433.333 100 418.409 100 400Z'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M100 200C100 181.59 114.924 166.667 133.333 166.667H666.667C685.076 166.667 700 181.59 700 200C700 218.409 685.076 233.333 666.667 233.333H133.333C114.924 233.333 100 218.409 100 200Z'
		/>
	</svg>
);
export default BurgerMenuIcon;
