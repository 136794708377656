import classNames from 'classnames';
import AddWalletModal from 'pages/components/modals/AddWalletModal/AddWalletModal';
import { FC, useState } from 'react';
import { Tab, TabList, Tabs } from 'react-tabs';
import { useGetPaymentsCredentialsQuery } from 'store/services/personal-partner';

import usdt from '../../../../../assets/images/profile/wallets/USDT-logo.svg';
import visa from '../../../../../assets/images/profile/wallets/VISA-logo.svg';
import { CheckCircleIcon, StopIcon } from '../../../../../assets/svg';
import { Button } from '../../../../components/Button/Button';

import s from './ProfileWallets.module.scss';
import WalletItem from './WalletItem/WalletItem';

interface IProfileWallets {
	className?: string;
}

const getMethodImage = (paymentMethod: string) => {
	const methodIcons = {
		VISA: visa,
		USDT: usdt,
		MasterCard: visa
	} as any;

	return methodIcons[paymentMethod] || usdt;
};

const ProfileWallets: FC<IProfileWallets> = ({ className }) => {
	const [showAddWalletModal, setShowAddWalletModal] = useState(false);

	const [activeFilter, setActiveFilter] = useState<boolean>();

	const { data: paymentsCredentials, isLoading } =
		useGetPaymentsCredentialsQuery({
			...(activeFilter !== undefined && { active: activeFilter })
		});

	return (
		<div className={classNames(s['finance-wallets__content'], className)}>
			<div className={s['finance-wallets__title']}>
				<h2 className={s.title}>Payments</h2>
				<Button onClick={() => setShowAddWalletModal(true)} small>
					Add a wallet/card
				</Button>
				<AddWalletModal
					showAddWalletModal={showAddWalletModal}
					setShowAddWalletModal={setShowAddWalletModal}
				/>
			</div>
			<div className={s['finance-wallets__cards']}>
				<Tabs className={s.tabs}>
					<div className={classNames(s.nav, 'settings-nav')}>
						<TabList className={s.tablist}>
							<Tab className={s.tab}>
								<button onClick={() => setActiveFilter(undefined)}>All</button>
							</Tab>
							<Tab className={s.tab}>
								<button onClick={() => setActiveFilter(true)}>Active</button>
							</Tab>
							<Tab className={s.tab}>
								<button onClick={() => setActiveFilter(false)}>Inactive</button>
							</Tab>
						</TabList>
					</div>

					<div className={s.cards}>
						{isLoading
							? 'LOADING'
							: paymentsCredentials?.map((payment) => (
									<WalletItem
										paymentId={payment.id}
										className={s.card}
										key={payment.id}
										logoSrc={getMethodImage(payment.paymentMethod)}
										checkText={payment.paymentAddress}
										statusIcon={
											payment.active ? <CheckCircleIcon /> : <StopIcon />
										}
										statusText={payment.active ? 'active' : 'inactive'}
									/>
							  ))}
					</div>
				</Tabs>
			</div>
		</div>
	);
};

export default ProfileWallets;
