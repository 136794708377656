import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import instagram from '../../../assets/images/social/Instagram.svg';
import telegram from '../../../assets/images/social/telegram.svg';
import { EmailIcon } from '../../../assets/svg';

import s from './Footer.module.scss';

interface IFooter {
	isCabinet?: boolean;
}
interface Contact {
	href: string;
	label: string;
}

interface Social {
	href: string;
	ariaLabel: string;
	icon: string;
	rel: string;
	className: string;
	target?: string;
}

interface Term {
	href: string;
	label: string;
}

const Footer: React.FC<IFooter> = ({ isCabinet }) => {
	const { t } = useTranslation();

	const contacts: Contact[] = [
		{
			href: 'mailto:affiliates@binobi.com',
			label: 'affiliates@binobi.com'
		}
	];

	const socials: Social[] = [
		{
			href: 'https://t.me/BINOBI_24H_SUPPORT_BOT',
			ariaLabel: 'Telegram',
			icon: telegram,
			rel: 'nofollow',
			className: s.socialsItem,
			target: '_blank'
		},
	];

	const terms: Term[] = [
		{
			href: '/agreement',
			label: t('footer.navigation.link1')
		},

		{
			href: '/policy',
			label: t('footer.navigation.link2')
		}
	];

	return (
		<footer className={isCabinet ? classNames(s.footer, 'footer') : s.footer}>
			<div className='container'>
				<div className={s.header}>
					<div className={s.title}> {t('pages.home.contact.title')}</div>
				</div>
				<div className={s.content}>
					<div className={s.contacts}>
						{contacts.map((contact, index) => (
							<div className={s.contactsItem} key={index}>
								<a href={contact.href}>
									<EmailIcon />
									{contact.label}
								</a>
							</div>
						))}
					</div>
					<div className={s.socials}>
						{socials.map((social, index) => (
							<a
								href={social.href}
								aria-label={social.ariaLabel}
								rel={social.rel}
								className={social.className}
								target={social.target}
								key={index}
							>
								<img src={social.icon} alt={social.ariaLabel} />
							</a>
						))}
					</div>

					<div className={s.terms}>
						{terms.map((term, index) => (
							<Link to={term.href} key={index}>
								{term.label}
							</Link>
						))}
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
