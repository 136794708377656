import React from 'react';

import s from './Best.module.scss';

import imageMain1 from '../../../assets/images/best/image1.png';
import imageMain1Mobile from '../../../assets/images/best/image1_mobile.png';
import imageMain2 from '../../../assets/images/best/image2.png';
import money from '../../../assets/images/best/money_mobile.png';
import image from '../../../assets/images/best/items/image1.svg';
import image2 from '../../../assets/images/best/items/image2.svg';
import image3 from '../../../assets/images/best/items/image3.svg';
import image4 from '../../../assets/images/best/items/image4.svg';
import image5 from '../../../assets/images/best/items/image5.svg';
import image6 from '../../../assets/images/best/items/image6.svg';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
AOS.init({
  once: true,
});

const Best = () => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery({ query: '(max-width: 991.98px)' });

  const items = [
    {
      image: image,
      text: t('pages.home.best.list.item1'),
      aos: 'zoom-in',
    },
    {
      image: image2,
      text: t('pages.home.best.list.item2'),
      aos: 'zoom-in',
    },
    {
      image: image3,
      text: t('pages.home.best.list.item3'),
      aos: 'zoom-in',
    },
    {
      image: image4,
      text: t('pages.home.best.list.item4'),
      aos: 'zoom-in',
    },
    {
      image: image5,
      text: t('pages.home.best.list.item5'),
      aos: 'zoom-in',
    },
    {
      image: image6,
      text: t('pages.home.best.list.item6'),
      aos: 'zoom-in',
    },
  ];

  return (
    <div className={s.best}>
      {isMobile && (
        <div className={s.money}>
          <img src={money} alt="" />
        </div>
      )}
      <div className="container">
        <div className={s.wrapper}>
          {!isMobile ? (
            <div
              data-aos="fade-right"
              data-aos-duration="700"
              className={classNames(s.image__left, 'iresponsive')}>
              <img src={imageMain2} alt="" />
            </div>
          ) : (
            <div
              data-aos="fade-up"
              data-aos-duration="700"
              className={classNames(s.image__left, 'iresponsive')}>
              <img src={imageMain1Mobile} alt="" />
            </div>
          )}

          <div className={s.content}>
            <h2 data-aos="fade-up" data-aos-duration="700" className={classNames(s.title, 'h2')}>
              {t('pages.home.best.title')}
            </h2>
            <div className={s.items}>
              <ul>
                {items &&
                  items.map((item, index) => (
                    <li key={index} data-aos={item.aos} data-aos-duration="700">
                      <div className={s.items__image}>
                        <img src={item.image} alt={item.text} />
                      </div>
                      <span>{item.text}</span>
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          <div
            data-aos="fade-left"
            data-aos-duration="700"
            className={classNames(s.image__right, 'iresponsive')}>
            <img src={imageMain1} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Best;
