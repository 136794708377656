export interface IAuthContext {
	token: string | null;
	isInitial: boolean;
	setIsInitial: (state: boolean) => void;
	login: (token: string | null) => void;
	logout: () => void;
}

export enum Messengers {
	Telegram = 'telegram',
	Skype = 'skype'
}

export type TUser = {
	id: string;
	email: string;
	password: string;
	seed: string;
	role: string;
	secret: boolean;
	avatarUrl: string;
	createdAt: string | null;
};

export type TSignIn = {
	email: string;
	password: string;
	code?: string;
};

export type TInitProfile = {
	readonly commandName: string;
	readonly email: string;
	readonly messengerType: Messengers;
	readonly messenger: string;
	readonly phoneNumber: string;
	readonly password: string;
};
