// import { Element } from 'react-scroll';
import { ParallaxProvider } from 'react-scroll-parallax';

import FaqList from './FaqList/FaqList';
import Hero from './Hero/Hero';

const Faq = () => {
	return (
		<ParallaxProvider>
			<Hero />
			<FaqList />
		</ParallaxProvider>
	);
};

export default Faq;
