import classNames from 'classnames';
import React, { useState } from 'react';

interface CheckboxCabinetProps {
	children: React.ReactNode;
	rounded?: boolean;
	radio?: boolean;
	name?: string;
	checked?: boolean;
	required?: boolean;
	className?: string;
}

const CheckboxCabinet: React.FC<CheckboxCabinetProps> = ({
	children,
	rounded,
	radio,
	name,
	checked,
	required,
	className
}) => {
	const [isChecked, setIsChecked] = useState(checked || false);

	const handleChange = () => {
		setIsChecked(!isChecked);
	};

	return (
		<label
			className={
				rounded
					? classNames(`${className} checkbox checkbox--rounded`)
					: `${className} checkbox`
			}
		>
			{radio ? (
				<input
					type='radio'
					name={name}
					required={required}
					checked={isChecked}
					onChange={handleChange}
				/>
			) : (
				<input
					type='checkbox'
					checked={isChecked}
					onChange={handleChange}
					required={required}
				/>
			)}

			<span>{children}</span>
		</label>
	);
};

export default CheckboxCabinet;
