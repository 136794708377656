import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import s from './Breadcrumbs.module.scss';

interface BreadcrumbsProps {
	name: string;
	category?: string;
	categoryLink?: string;
	top?: boolean;
	subCategory?: string;
	subCategoryLink?: string;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
	name,
	category,
	categoryLink,
	top,
	subCategory,
	subCategoryLink
}) => {
	const { t } = useTranslation();

	return (
		<div className={top ? classNames(s.breadcrumb, s.top) : s.breadcrumb}>
			<nav aria-label='breadcrumb'>
				{category && !subCategory ? (
					<ol>
						<li>
							<Link to='/'>{t('navigation.link1')}</Link>
						</li>
						<li>
							<Link to={categoryLink!}>{category}</Link>
						</li>
						<li>{name}</li>
					</ol>
				) : category && subCategory ? (
					<ol>
						<li>
							<Link to='/'>{t('navigation.link1')}</Link>
						</li>
						<li>
							<Link to={categoryLink!}>{category}</Link>
						</li>
						<li>
							<Link to={subCategoryLink!}>{subCategory}</Link>
						</li>
						<li>{name}</li>
					</ol>
				) : (
					<ol>
						<li>
							<Link to='/'>{t('navigation.link1')}</Link>
						</li>
						<li>{name}</li>
					</ol>
				)}
			</nav>
		</div>
	);
};

export default Breadcrumbs;
