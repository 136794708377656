import binobiBet from 'assets/images/logos/binobi_bet.svg';
import image1 from 'assets/images/promo/image1.jpg';
import { useAuthUser } from 'hooks/useAuth';
import MySelect from 'pages/components/MySelect';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	OfferPaymentModel,
	OfferPaymentModelLabels,
	TLanding,
	TOffer
} from 'store/services/offers';
import { useCreatePromoMutation } from 'store/services/promos';
import { OfferActionTypeOptions, OptionsPaymentModel } from 'types/options';

import { TOfferType } from '../Steps/SecondStep';

// const optionsOffers = [
// 	{ value: 'All', label: 'All' },
// 	{ value: 'SportRS', label: 'Sport RS' },
// 	{ value: 'SportCPAIndiv', label: 'Sport CPA Indiv...' },
// 	{ value: 'SportCPAKZ', label: 'Sport CPA KZ li...' },
// 	{ value: 'CPAByingSport', label: 'CPA Bying Sport' },
// 	{ value: 'CPAMediaSport', label: 'CPA (Media) Sport' },
// 	{ value: 'SportCPAnew', label: 'Sport CPA new' },
// 	{ value: 'SportRSplus', label: 'Sport RS' },
// 	{ value: 'CasinoRS', label: 'Casino RS' }
// ];

// const optionsTraffic = [
// 	{ value: 'All', label: 'All' },
// 	{ value: 'ASOcross', label: 'ASO cross/gene...' },
// 	{ value: 'ContextBrand', label: 'Context brand' },
// 	{ value: 'Contextcross', label: 'Context cross/...' },
// 	{ value: 'SEObrand', label: 'SEO brand' },
// 	{ value: 'SEOreview', label: 'SEO review/cross' },
// 	{ value: 'FBtowebview', label: 'FB to webview' },
// 	{ value: 'FBtonative', label: 'FB to native' },
// 	{ value: 'UACtowebview', label: 'UAC to webview' },
// 	{ value: 'TTtowebview', label: 'TT to webview' },
// 	{ value: 'TTtonative', label: 'TT to native' },
// 	{ value: 'InnApp', label: 'InnApp' },
// 	{ value: 'Schemeswitho', label: 'Schemes witho...' },
// 	{ value: 'Schemeswiths', label: 'Schemes with s...' },
// 	{ value: 'InfluencersSoci', label: 'Influencers/soci..' },
// 	{ value: 'PushClickunder', label: 'Push/clickunder...' },
// 	{ value: 'MaillingSmsM', label: 'Mailling (sms/m...)' },
// 	{ value: 'AlStreamersTTYl', label: 'Streamers (TT, Y...' },
// 	{ value: 'Other', label: 'Other' }
// ];

type OfferSelectionProps = {
	step: number;
	onNext: () => void;
	onPrevious: () => void;
	setPaymentModelFilter: (v: any) => void;
	offer: TOffer | undefined;
	selectedOfferType: TOfferType | undefined;
	selectedLanding: TLanding | undefined;
};

export const OfferSelection: FC<OfferSelectionProps> = ({
	step,
	offer,
	onNext,
	onPrevious,
	selectedLanding,
	selectedOfferType,
	setPaymentModelFilter
}) => {
	const navigate = useNavigate();

	const [createOffer] = useCreatePromoMutation();
	const user = useAuthUser();

	const commandId = user && user?.commands?.length && user?.commands[0]?.id;

	const processOffer = async () => {
		const res = await createOffer({
			offer,
			landing: selectedLanding,
			promoType: selectedOfferType,
			...(commandId && { commandId })
		});

		if (res) navigate('/promo/my');
	};

	const getLinkDisabled = () => {
		if (selectedOfferType?.value === 'directLink') return false;

		if (selectedLanding && selectedOfferType?.value !== 'directLink')
			return false;

		return true;
	};

	return (
		<div className='create-promo-steps__wrapper'>
			<div className='create-promo-steps__triggers'>
				<div
					className={`create-promo-steps__trigger create-promo-trigger  ${
						step >= 0 ? 'active' : ''
					}   ${step === 0 ? 'current' : ''} `}
				>
					<div className='create-promo-trigger__head'>
						<div className='create-promo-trigger__count'>Step 1</div>
						<div className='create-promo-trigger__title'>Offer selection</div>
					</div>
					<div className='create-promo-trigger__body'>
						<div className='create-promo-trigger__offers create-promo-offers'>
							<div className='create-promo-offers__selects'>
								<div className='create-promo-offers__select'>
									<span>Payment model:</span>
									<MySelect
										options={OptionsPaymentModel}
										defaultValue={OptionsPaymentModel[0]}
										onChange={(v: any) =>
											setPaymentModelFilter(
												v.value === 'all' ? undefined : v.value
											)
										}
									/>
								</div>
							</div>

							{offer && (
								<div className='create-promo-offers__wrapper'>
									<div className='create-promo-offers__logo'>
										<img src={binobiBet} alt='' />
									</div>
									<div className='create-promo-offers__list'>
										<ul>
											<li>
												<span>Payment model:</span>&nbsp;
												<span>
													{OfferPaymentModelLabels[offer?.paymentModel]}
												</span>
											</li>
											<li>
												<span>Goal:</span>&nbsp;
												<span>{`${offer?.goal} ${offer?.paymentModel === OfferPaymentModel.CPA ? '$' : '%'}`}</span>
											</li>
										</ul>
									</div>
								</div>
							)}
						</div>
						<div className='create-promo-trigger__buttons'>
							<div className='create-promo-trigger__button'>
								<button
									onClick={onNext}
									className='btn next-btn'
									disabled={!offer}
								>
									Next Step
								</button>
							</div>
						</div>
					</div>
				</div>

				<div
					className={`create-promo-steps__trigger create-promo-trigger  ${
						step >= 1 ? 'active' : ''
					}   ${step === 1 ? 'current' : ''} `}
				>
					<div className='create-promo-trigger__head'>
						<div className='create-promo-trigger__count'>Step 2</div>
						<div className='create-promo-trigger__title'>Offer type</div>
					</div>
					<div className='create-promo-trigger__body'>
						{selectedOfferType && (
							<div className='create-promo-trigger__promo-type'>
								Offer type:&nbsp;<span>{selectedOfferType.label}</span>
							</div>
						)}

						<div className='create-promo-trigger__buttons'>
							<div className='create-promo-trigger__button'>
								<button
									onClick={onPrevious}
									className='btn btn--minor prev-btn'
									disabled={step === 0}
								>
									Back
								</button>
							</div>
							<div className='create-promo-trigger__button'>
								<button
									onClick={onNext}
									className='btn next-btn'
									disabled={!selectedOfferType}
								>
									Next Step
								</button>
							</div>
						</div>
					</div>
				</div>
				<div
					className={`create-promo-steps__trigger create-promo-trigger  ${
						step >= 2 ? 'active' : ''
					}   ${step === 2 ? 'current' : ''} `}
				>
					<div className='create-promo-trigger__head'>
						<div className='create-promo-trigger__count'>Step 3</div>
						<div className='create-promo-trigger__title'>Get link</div>
					</div>
					<div className='create-promo-trigger__body'>
						{selectedLanding && (
							<div className='create-promo-trigger__get-link create-promo-get-link'>
								<div className='create-promo-get-link__wrapper'>
									<div className='create-promo-get-link__title'>
										{selectedLanding.domain}
									</div>
									<div className='create-promo-get-link__image'>
										<iframe
											title={selectedLanding.domain}
											loading='lazy'
											src={selectedLanding.imageUrl}
											style={{ backgroundImage: image1 }}
										/>
									</div>
								</div>
								{/* <div className='create-promo-get-link__selects'>
								<div className='create-promo-get-link__select'>
									<span>Type traffic source:</span>
									<MySelect
										options={optionsTraffic}
										defaultValue={optionsTraffic[0]}
									/>
								</div>
							</div> */}
							</div>
						)}

						<div className='create-promo-trigger__buttons'>
							<div className='create-promo-trigger__button'>
								<button
									onClick={onPrevious}
									className='btn btn--minor prev-btn'
									disabled={step === 0}
								>
									Back
								</button>
							</div>
							<div className='create-promo-trigger__button'>
								<button
									className='btn'
									onClick={() => processOffer()}
									disabled={getLinkDisabled()}
								>
									Get Link
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
