import AOS from 'aos';
import 'aos/dist/aos.css';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import backgroundMoney from '../../../assets/images/faq/hero/back_line.svg';
import backgroundMoneyMobile from '../../../assets/images/hero/background_mobile.png';
import backgroundMobile from '../../../assets/images/hero/background_mobile.svg';
import background from '../../../assets/images/info/hero/back_money.png';
import image from '../../../assets/images/info/hero/image.png';
import imageMobile from '../../../assets/images/info/hero/image_mobile.png';
import logo from '../../../assets/images/logo.svg';
import s from '../../Faq/Hero/Hero.module.scss';
import { Button } from '../../components/Button/Button';
import ModalLogin from '../../components/modals/ModalLogin/ModalLogin';
import ModalRegistration from '../../components/modals/ModalRegistration/ModalRegistration';
import ModalThanks from '../../components/modals/ModalThanks/ModalThanks';

AOS.init({
	once: true
});

const Hero = () => {
	const { t } = useTranslation();

	const [showRegistrationModal, setShowRegistrationModal] = useState(false);
	const [showLoginModal, setShowLoginModal] = useState(false);
	const [showThanksModal, setShowThanksModal] = useState(false);

	const isMobile = useMediaQuery({ query: '(max-width: 767.98px)' });

	return (
		<>
			<div className={s.hero}>
				<div className={s.background}>
					{!isMobile ? (
						<div className={s.background__image}>
							<img src={background} alt='' />
						</div>
					) : (
						<div className={s.background__image}>
							<img src={backgroundMoneyMobile} alt='' />
						</div>
					)}
					{!isMobile ? (
						<div className={s.background__money}>
							<img src={backgroundMoney} alt='' />
						</div>
					) : (
						<div className={s.background__money}>
							<img src={backgroundMobile} alt='' />
						</div>
					)}
				</div>
				<div className='container'>
					<div className={s.wrapper}>
						<div className={s.inner}>
							<div
								data-aos='fade-up'
								data-aos-duration='900'
								className={s.logo}
							>
								<div className={s.logo__image}>
									<img src={logo} alt='' />
								</div>
								<span>Partners</span>
							</div>
							<div
								data-aos='fade-up'
								data-aos-duration='900'
								data-aos-delay='200'
								className={s.text}
							>
								{t('pages.home.hero.title')}
							</div>
							<div
								data-aos='fade-up'
								data-aos-duration='900'
								data-aos-delay='400'
								className={s.button}
							>
								<Button onClick={() => setShowLoginModal(true)}>
									{t('buttonJoin')}
								</Button>
							</div>
							<div
								data-aos='fade-up'
								data-aos-duration='1000'
								data-aos-delay='200'
								className={s.title}
							>
								{t('navigation.link4')}
							</div>
							{isMobile ? (
								<div
									data-aos='fade-up'
									data-aos-duration='900'
									data-aos-delay='600'
									className={classNames(s.image, 'iresponsive')}
								>
									<img src={imageMobile} alt='' />
								</div>
							) : (
								<div
									data-aos='fade-up'
									data-aos-duration='900'
									data-aos-delay='600'
									className={classNames(s.image, 'iresponsive')}
								>
									<img src={image} alt='' />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<ModalRegistration
				showRegistrationModal={showRegistrationModal}
				setShowRegistrationModal={setShowRegistrationModal}
				setShowLoginModal={setShowLoginModal}
				setShowThanksModal={setShowThanksModal}
			/>
			<ModalLogin
				showLoginModal={showLoginModal}
				setShowLoginModal={setShowLoginModal}
				setShowRegistrationModal={setShowRegistrationModal}
			/>
			<ModalThanks
				showThanksModal={showThanksModal}
				setShowThanksModal={setShowThanksModal}
			/>
		</>
	);
};

export default Hero;
