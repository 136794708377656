/* eslint-disable jsx-a11y/anchor-is-valid */
import dayjs from 'dayjs';
import { useAuthUser } from 'hooks/useAuth';
import { useState } from 'react';
import {
	useGetRangeStatisticOnPromoQuery,
	useGetTodayStatisticOnCommandQuery
} from 'store/services/promos';

import { CabinetSvgSelector } from '../../../assets/images/icons/CabinetSvgSelector';
import banner from '../../../assets/images/profile/banner1.png';
import MySelect from '../../components/MySelect';
import { Chart } from '../components/Chart/Chart';
import PeriodSorting from '../components/PeriodSorting';

import s from './Dashboard.module.scss';
import News from './News';

const optionsChartSelect = [
	{ value: 'clicks', label: 'Clicks' },
	{ value: 'fdp', label: 'First Deposits' },
	{ value: 'registrations', label: 'Registrations' }
];

const returnDates = (dates: any, type: string) => {
	if (type === 'day') {
		return [
			dayjs((dates as any)[0])
				.subtract(1, 'days')
				.startOf('day')
				.toDate(),
			dayjs((dates as any)[1])
				.subtract(1, 'day')
				.endOf('day')
				.toDate()
		];
	}

	if (type === 'week') {
		return [
			dayjs((dates as any)[0])
				.subtract(7, 'days')
				.toDate(),
			dayjs((dates as any)[1])
				.subtract(7, 'days')
				.toDate()
		];
	}

	if (type === 'month') {
		return [
			dayjs((dates as any)[0])
				.subtract(30, 'days')
				.toDate(),
			dayjs((dates as any)[1])
				.subtract(30, 'days')
				.toDate()
		];
	}
};

const Dashboard = () => {
	const today = dayjs();
	const user = useAuthUser();
	const [dateType, setDateType] = useState('week');
	const [type, setType] = useState('registrations');
	const weekAgo = today.subtract(7, 'days').toDate();
	const [dates, setDates] = useState([weekAgo, today.toDate()]);
	const commandId = user && user?.commands?.length && user?.commands[0]?.id;
	const { data, isLoading } = useGetTodayStatisticOnCommandQuery(commandId);

	const { data: rangesPromo, isLoading: isLoadingRanges } =
		useGetRangeStatisticOnPromoQuery({
			...(dates && {
				dateRange: dates,
				dateRangeToCompare: returnDates(dates, dateType)
			}),
			...(type && { field: type })
		});

	return (
		<>
			<div className='information'>
				<div className='information__label'>
					Statistics for today
					<div className='icon'>
						<CabinetSvgSelector id='chevron_down' />
					</div>
				</div>
				<div className='information__list'>
					<ul>
						<li>
							<div className='icon'>
								<CabinetSvgSelector id='calendar' />
							</div>
							<small>{dayjs().format('DD.MM.YYYY')}</small>
						</li>
						<li>
							<div className='icon'>
								<CabinetSvgSelector id='click' />
							</div>
							<span>{data?.total.clicks}</span>
							<small>Clicks</small>
						</li>
						<li>
							<div className='icon'>
								<CabinetSvgSelector id='form' />
							</div>
							<span>{data?.total.registrations}</span>
							<small>Registrations</small>
						</li>
						<li>
							<div className='icon'>
								<CabinetSvgSelector id='wallet' />
							</div>
							<span>{data?.total.fdp}</span>
							<small>FD</small>
						</li>
						<li>
							<div className='icon'>
								<CabinetSvgSelector id='money' />
							</div>
							<span>{data?.total?.income}</span>
							<small>Income Today</small>
						</li>
					</ul>
				</div>
			</div>

			<div className='banner'>
				<div className={s.image}>
					<img src={banner} alt='' />
				</div>
			</div>

			<div className='content__wrapper'>
				<div className='profile-chart'>
					<div className='profile-chart__modal' style={{ display: 'none' }}>
						<div className='profile-chart__modal-wrapper'>
							<b>Thanks for the registration</b>
							<span>
								Your stats will be displayed here. You have not performed any
								actions yet
							</span>
						</div>
					</div>
					<div className='profile-chart__content'>
						<div className='profile-chart__control chart-control'>
							<div className='chart-control__select'>
								<MySelect
									options={optionsChartSelect}
									defaultValue={optionsChartSelect[2]}
									onChange={(v) => setType((v as any).value)}
								/>
							</div>
							{/* <div className='chart-control__wrapper'>
								<div className='chart-control__period chart-control-period'>
									<div className='chart-control-period__title'>Period:</div>
									<Datepicker className='chart-control-period__select' />
									<div className='chart-control-period__buttons'>
										<div className='chart-control-period__buttons-select'>
											<MySelect
												defaultValue={optionsPeriod[0]}
												options={optionsPeriod}
											/>
										</div>
									</div>
								</div>
							</div> */}
							<PeriodSorting
								hideDate
								setDateType={setDateType}
								setDates={setDates}
							/>
						</div>
						<Chart rangesPromo={rangesPromo} />
					</div>
				</div>
				<News />
			</div>
		</>
	);
};

export default Dashboard;
