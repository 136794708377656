import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/slices/auth';

import { useUser } from './useUser';

export const useAuth = () => {
	const { token, addToken, removeToken, isInitial, setIsInitial } = useUser();

	const login = (token: string | null) => {
		addToken(token);
	};

	const logout = () => {
		removeToken();
	};

	return { login, logout, token, isInitial, setIsInitial };
};

export const useAuthUser = () => {
	const user = useSelector(selectCurrentUser);

	return useMemo(() => user, [user]);
};
