import React, { FC } from 'react';

export interface BaseTableProps {
	headers: string[];
	rows: string[][];
	isEmpty?: boolean;
}

const BaseTable: FC<BaseTableProps> = ({ headers, rows, isEmpty }) => {
	return (
		<>
			{isEmpty && <div className='table__empty'>No Data</div>}

			<table>
				<thead>
					<tr>
						{headers.map((header, index) => (
							<th key={index}>{header}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{rows.map((row, rowIndex) => (
						<tr key={rowIndex}>
							{row.map((cell, cellIndex) => (
								<td key={cellIndex}>{cell}</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</>
	);
};

export default BaseTable;
