import PeriodSorting from 'pages/Cabinet/components/PeriodSorting';
import MySelect from 'pages/components/MySelect';
import ModalUserInfo from 'pages/components/modals/ModalUserInfo/ModalPromoEdit';
import ModalPromoEdit from 'pages/components/modals/ModalUserInfo/ModalPromoEdit';
import { useEffect, useState } from 'react';
import { OptionsPaymentModel } from 'types/options';
import countries from 'utils/countries';

const geoOptions = countries
	.sort(function (a, b) {
		if (a.value < b.value) {
			return -1;
		}
		if (a.value > b.value) {
			return 1;
		}
		return 0;
	})
	.map(({ value, label }) => ({ label: `${label}: ${value}`, value }));

export const PlayersFilters = ({
	setFilters,
	customTitle
}: {
	setFilters: (v: any) => void;
	customTitle?: string;
}) => {
	const [dateRange, setDates] = useState<any>();
	const [geo, setSelectedGeo] = useState<any>();

	useEffect(() => {
		setFilters({
			...(geo && { geo }),
			...(dateRange && { dateRange })
		});
	}, [dateRange, geo]);

	return (
		<>
			<PeriodSorting
				customTitle={customTitle}
				setDates={setDates}
				className='my-promo-mail__period'
			/>

			<div className='my-promo-mail__control promo-mail-control'>
				<div className='promo-mail-control__select select'>
					<span>GEO</span>
					<MySelect
						onChange={(v) => setSelectedGeo((v as any).value)}
						options={[{ value: 'all', label: 'All' }, ...geoOptions]}
						defaultValue={{ value: 'all', label: 'All' }}
					/>
				</div>
			</div>
		</>
	);
};
