import { logout } from 'api/requests/auth';
import dayjs from 'dayjs';
import { useAuthUser } from 'hooks/useAuth';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearUser } from 'store/slices/auth';

import skype from '../../../../../assets/images/social/skype.svg';
import telegram from '../../../../../assets/images/social/telegram.svg';
import { EditIcon, LogoutIcon, UserIcon } from '../../../../../assets/svg';
import { Button } from '../../../../components/Button/Button';
import EditProfileModal from '../../../../components/modals/EditProfileModal/EditProfileModal';

import s from './UserProfile.module.scss';

interface UserProfileListItem {
	title: string;
	text: string;
}

interface SocialButton {
	icon: string;
	messengerNickname: string;
}

const UserProfile: FC = () => {
	const user = useAuthUser();

	console.log('user', user);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [showEditProfileModal, setShowEditProfileModal] = useState(false);

	const userProfileList: UserProfileListItem[] = [
		{ title: 'Name', text: user?.name || user?.username || '' },
		{ title: 'Phone', text: user?.phoneNumber || '' },
		{ title: 'E-mail', text: user?.email || '' }
	];

	const socialButton: SocialButton = {
		icon: user?.messengerType === 'telegram' ? telegram : skype,
		messengerNickname: user?.messenger || ''
	};

	const handleLogout = async () => {
		const token = localStorage.getItem('token');
		if (token) {
			await logout(token);
			dispatch(clearUser());

			navigate('/');
		}
	};

	return (
		<div className={s['user-profile']}>
			<div className={s['user-profile__header']}>
				<div className={s['user-profile__img']}>
					<UserIcon />
				</div>
				<a className={s['user-profile__mail']} href='#"'>
					{user?.name || user?.username || user?.email || 'Noname'}
				</a>
				<span className={s['user-profile__description']}>
					In the Binobi Partners since{' '}
					{dayjs(user?.createdAt).format('YYYY.MM.DD')}
				</span>
			</div>
			<div className={s['user-profile__main']}>
				<input
					className={s['user-profile__input']}
					type='checkbox'
					id='checkbox-toggle'
				/>
				<label className={s['user-profile__label']} htmlFor='checkbox-toggle'>
					<span>Show more</span>
					<span>Collapse</span>
					<div className={s['user-profile__arrow']}></div>
				</label>
				<div className={s['user-profile__wrapper']}>
					<Button
						onClick={() => setShowEditProfileModal(true)}
						className={s['user-profile__button-edit']}
						iconBefore={<EditIcon />}
						small
						minor
					>
						Edit profile
					</Button>
					<EditProfileModal
						user={user}
						showEditProfileModal={showEditProfileModal}
						setShowEditProfileModal={setShowEditProfileModal}
					/>
					<ul className={s['user-profile-list']}>
						{userProfileList.map((item, index) => (
							<li key={index} className={s['user-profile-list__item']}>
								<span className={s['user-profile-list__item-title']}>
									{item.title}
								</span>
								<span className={s['user-profile-list__item-text']}>
									{item.text}
								</span>
							</li>
						))}
					</ul>
					<div className={s['user-profile__social']}>
						<div className={s['user-profile__social-btn']}>
							<img
								src={socialButton.icon}
								alt={socialButton.messengerNickname}
							/>
							@{socialButton.messengerNickname}
						</div>
					</div>
					<Button
						className={s['user-profile__logout-btn']}
						small
						minor
						onClick={() => handleLogout()}
						iconBefore={<LogoutIcon />}
					>
						Log out of account
					</Button>
				</div>
			</div>
		</div>
	);
};

export default UserProfile;
