export const OptionsPaymentModel = [
	{ value: 'all', label: 'All' },
	{ value: 'revshare', label: 'RevShare' },
	{ value: 'cpa', label: 'CPA' }
];

export const OfferActionTypeOptions = [
	{ value: 'all', label: 'All', imageId: 'link' },
	{
		value: 'directLink',
		label: 'Direct Link',
		imageId: 'link'
	},
	{
		value: 'landings',
		label: 'Landings',
		imageId: 'website'
	}
	// {
	// 	value: 'themedLandings',
	// 	label: 'Themed landings',
	// 	imageId: 'search_engine'
	// },
	// {
	// 	value: 'banners',
	// 	label: 'Banners',
	// 	imageId: 'layout_banner'
	// },
	// {
	// 	value: 'videos',
	// 	label: 'Videos',
	// 	imageId: 'video'
	// },
	// {
	// 	value: 'eventLandingPages',
	// 	label: 'Event Landing Pages',
	// 	imageId: 'web_grid'
	// }
];
