import { FC } from 'react';
import { TOffer, TOfferResponse } from 'store/services/offers';

import { OfferColumn } from '../OfferColumn';

type TFirstStep = {
	offers: TOfferResponse | undefined;
	selectedOffer: TOffer | undefined;
	setSelectedOffer: (v: TOffer) => void;
};

export const FirstStep: FC<TFirstStep> = ({
	offers,
	selectedOffer,
	setSelectedOffer
}) => {
	const newOffers = offers || {};

	const keys = Object.keys(newOffers);

	return (
		<div className='create-promo-steps__section create-promo-section active'>
			<div className='create-promo-section__body'>
				<div className='promo-offer'>
					<div className='promo-offer__columns'>
						<OfferColumn
							domain={''}
							offers={[]}
							selectedOffer={selectedOffer}
							setSelectedOffer={setSelectedOffer}
						/>
						{offers &&
							keys.map((k, i) => (
								<OfferColumn
									key={i}
									domain={k}
									offers={offers[k]}
									selectedOffer={selectedOffer}
									setSelectedOffer={setSelectedOffer}
								/>
							))}

						<OfferColumn
							domain={''}
							offers={[]}
							selectedOffer={selectedOffer}
							setSelectedOffer={setSelectedOffer}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
