import { useDebouncedState } from '@mantine/hooks';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { OfferPaymentModel } from 'store/services/offers';
import { promosApi } from 'store/services/promos';
import { OfferActionTypeOptions, OptionsPaymentModel } from 'types/options';

import BackButtonCabinet from '../../components/BackButtonCabinet/BackButtonCabinet';
import InputCabinet from '../../components/InputCabinet/InputCabinet';
import MySelect from '../../components/MySelect';
import PaginationTableCabinet, {
	countPages
} from '../../components/PaginationTableCabinet/PaginationTableCabinet';
import ModalPromoCreate from '../../components/modals/ModalPromoCreate/ModalPromoCreate';
import ModalPromoEdit from '../../components/modals/ModalPromoEdit/ModalPromoEdit';

import { TableRecord } from './Table/Record';

const MyPromo = () => {
	const [searchParams] = useSearchParams();
	const promoId = searchParams.get('promoId');

	const [currentPage, setCurrentPage] = useState(1);
	const [paymentModel, setPaymentModel] = useState<OfferPaymentModel>();
	const [offerType, setOfferType] = useState<any>();
	const [debouncePromoId, setDebouncePromoId] = useDebouncedState<any>(
		undefined,
		800
	);
	const [showModalPromoEdit, setShowModalPromoEdit] = useState(false);
	const [showModalPromoCreate, setShowModalPromoCreate] = useState(false);

	const { data, isLoading } = promosApi.useGetPromosQuery({
		currentPage,

		...(offerType && { offerType }),
		...(paymentModel && { paymentModel }),
		...((promoId || debouncePromoId) && { promoId: promoId || debouncePromoId })
	});

	return (
		<>
			<div className='my-promo'>
				<div className='my-promo__head'>
					<div className='create-promo-steps__head'>
						<BackButtonCabinet className='create-promo-steps__back' />
						<div className='create-promo-steps__title title'>My promo</div>
					</div>
					<div className='my-promo__control my-promo-control'>
						<div className='my-promo-control__select select'>
							<span>Payment Model:</span>
							<MySelect
								options={OptionsPaymentModel}
								defaultValue={OptionsPaymentModel[0]}
								onChange={(v: any) =>
									setPaymentModel(v.value === 'all' ? undefined : v.value)
								}
							/>
						</div>
						<div className='my-promo-control__select select'>
							<span>Offer Action:</span>
							<MySelect
								isMulti={false}
								options={OfferActionTypeOptions}
								defaultValue={OfferActionTypeOptions[0]}
								onChange={(v: any) =>
									setOfferType(v.value === 'all' ? undefined : v.value)
								}
							/>
						</div>
						<InputCabinet
							className='my-promo-control__search'
							placeholder='Search for Promo...'
							icon='search'
							small
							onChange={({ target }) => setDebouncePromoId(target.value)}
						/>
					</div>
				</div>
				<div className='my-promo__body'>
					<div className='main-settings-section__table table' data-simplebar>
						<table>
							<thead>
								<tr>
									<td>Promo Id</td>
									<td>Promo Name</td>
									<td>Offer Type</td>
									<td>Approved</td>
									<td>CPA</td>
									<td>Actions</td>
									<td>Additional Postback Params</td>
									<td>Promo</td>
									<td>Date</td>
									<td>Statistic</td>
									{/* <td>Settings</td> */}
								</tr>
							</thead>
							<tbody>
								{isLoading ? (
									<>
										<tr>LOADING</tr>
										<tr>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
										</tr>
										<tr>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
										</tr>
										<tr>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
										</tr>
									</>
								) : (
									<>
										{data?.promos && (
											<>
												{data.promos.map((promo) => (
													<TableRecord promo={promo} />
												))}

												{data.promos.length < 3 && (
													<>
														<tr>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
														</tr>
														<tr>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
														</tr>
													</>
												)}
											</>
										)}
									</>
								)}
							</tbody>
						</table>
					</div>
					<PaginationTableCabinet
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
						pages={countPages(data?.count || 0, 10)}
						className='main-settings-section__pagination'
					/>
				</div>
			</div>
			<ModalPromoCreate
				showModalPromoCreate={showModalPromoCreate}
				setShowModalPromoCreate={setShowModalPromoCreate}
			/>
			<ModalPromoEdit
				showModalPromoEdit={showModalPromoEdit}
				setShowModalPromoEdit={setShowModalPromoEdit}
			/>
		</>
	);
};

export default MyPromo;
