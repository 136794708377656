import AOS from 'aos';
import 'aos/dist/aos.css';
import React from 'react';
import { useTranslation } from 'react-i18next';

import s from '../../Agreement/Agreement/Agreement.module.scss';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

AOS.init({
	once: true
});

const Agreement = () => {
	const { t } = useTranslation();

	return (
		<div className={s.faq}>
			<div className='container'>
				<div className={s.wrapper}>
					<Breadcrumbs
						category={t('navigation.link4') as string}
						categoryLink='/information'
						name={t('footer.navigation.link2')}
					/>
					<div
						className={s.text}
						dangerouslySetInnerHTML={{
							__html: t('pages.policy.text') as string
						}}
					></div>
				</div>
			</div>
		</div>
	);
};

export default Agreement;
