// import { Element } from 'react-scroll';
import { ParallaxProvider } from 'react-scroll-parallax';

import Best from './Best/Best';
import FaqHome from './Faq/FaqHome';
import Hero from './Hero/Hero';
import Methods from './Methods/Methods';
import Stats from './Stats/Stats';

const Home = () => {
	return (
		<ParallaxProvider>
			<Hero />
			<Stats />
			<Best />
			{/* <Methods /> */}
			<FaqHome />
			{/* <Contacts /> */}
			{/* <Element name="skills">
        <Skills />
      </Element> */}
		</ParallaxProvider>
	);
};

export default Home;
