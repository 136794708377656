import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';

const MainLayout = () => {
  return (
    <ScrollToTop>
      <Header />
      <main>
        <Suspense fallback="...">
          <Outlet />
        </Suspense>
      </main>
      <Footer />
    </ScrollToTop>
  );
};

export default MainLayout;
