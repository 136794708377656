import React from 'react';
import { SVGProps } from 'react';

const ChevronDownIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={800}
		height={800}
		viewBox='0 0 800 800'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M143.096 276.43C156.114 263.412 177.219 263.412 190.237 276.43L400 486.193L609.763 276.43C622.78 263.412 643.886 263.412 656.903 276.43C669.921 289.447 669.921 310.553 656.903 323.57L423.57 556.903C410.552 569.921 389.447 569.921 376.429 556.903L143.096 323.57C130.079 310.553 130.079 289.447 143.096 276.43Z'
		/>
	</svg>
);
export default ChevronDownIcon;
