import React from 'react';
import { CabinetSvgSelector } from '../../../assets/images/icons/CabinetSvgSelector';
import BackButtonCabinet from '../../components/BackButtonCabinet/BackButtonCabinet';
import PaginationTableCabinet from '../../components/PaginationTableCabinet/PaginationTableCabinet';

const Support = () => {
  return (
    <>
      <div className="support">
        <div className="support__top top">
          <BackButtonCabinet />
          <div className="top__wrapper">
            <div className="top__title title">Support</div>
            <div className="support__button">
              <a href="#modal-create-dialogue" data-modal-open className="btn">
                Create a dialogue
              </a>
            </div>
          </div>
        </div>
        <div className="support__wrapper">
          <div className="support__table table" data-simplebar>
            <table>
              <thead>
                <tr>
                  <td>Subject</td>
                  <td>Date</td>
                  <td>Actions</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <PaginationTableCabinet />
        </div>
      </div>
      <div className="modal" data-modal="#modal-create-dialogue">
        <div data-modal-close className="modal__area"></div>
        <div className="modal__body">
          <div className="modal__content">
            <div data-modal-close className="modal__close icon">
              <CabinetSvgSelector id="close" />
            </div>
            <div className="modal__title">Create a dialogue</div>
            <div className="modal__form modal-form">
              <form action="" method="post">
                <div className="modal-form__content">
                  <div className="modal-form__inputs">
                    <label className="modal-form__input input select">
                      <div className="input__label">Category</div>
                      <select className="select-select2" name="create-dialogue-category">
                        <option selected disabled value="">
                          Choose a category
                        </option>
                        <option value="All">All</option>
                        <option value="Generalquestions questionsAInd">General questions</option>
                        <option value="Offerenablingrequest">Offer enabling request</option>
                        <option value="Technicalerro">Technical erro</option>
                        <option value="Feedback">Feedback</option>
                      </select>
                    </label>
                    <label className="modal-form__input input">
                      <div className="input__label">Subject</div>
                      <div className="input__field">
                        <input type="text" placeholder="Enter the subject of discussion" required />
                      </div>
                    </label>
                    <label className="modal-form__input input">
                      <div className="input__label">Message</div>
                      <div className="input__field">
                        <input type="text" placeholder="Your message" required />
                      </div>
                    </label>
                  </div>
                  <div className="modal-form__button">
                    <button type="submit" className="btn">
                      Create
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
