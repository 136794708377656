export const API_ROUTES = {
	// REGISTER / LOGIN
	LOGIN: 'partners/login',
	BINOBI_PARTNERS: 'partners',
	INIT_PROFILE: 'partners/profile/init',
	EDIT_PROFILE: 'partners/profile/edit',

	// PROMOS-PARTNER
	CREATE_PROMO: 'partners/create-promo',
	GET_ALL_PROMO: 'partners/get-all-promo',
	GET_STATISTIC_ON_PROMO: 'partners/get-statistic-on-promo',
	GET_PLAYER_STATISTIC: 'partners/player-statistic',
	GET_TODAY_STATISTIC_ON_PROMO: 'partners/get-today-statistic-on-promo',

	EDIT_POSTBACKS: 'partners/edit-command-postbacks',
	GET_ALL_POSTBACKS: 'partners/get-all-postbacks',
	EDIT_PROMO: 'partners/edit-promo',
	GET_RANGE_STATISTIC_ON_PROMO: 'partners/get-range-statistic-on-promo',

	// OFFERS-TRAFFIC
	GET_OFFERS: 'traffic/offers/partner',
	GET_LANDINGS: 'traffic/landings',

	// PERSONAL PARTNER
	GET_PAYMENTS_CREDENTIALS: 'partners/get-payments',
	CREATE_PAYMENT: 'partners/create-payment',
	CREATE_WITHDRAW: 'partners/create-withdraw',
	SET_ACTIVE_PAYMENT: 'partners/set-active-payment',
	DELETE_PAYMENT: 'partners/delete-payment',
	GET_PARTNER_BALANCE: 'partners/balance',
	GET_PARTNER_PAYMENTS: 'partners/get-all-partner-payments',

	//AUTH
	LOGOUT: 'auth/logout',
	REFRESH: 'auth/refresh'
};

export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
