import React from 'react';
import { SVGProps } from 'react';

const StopIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={379}
		height={379}
		viewBox='0 0 379 379'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M109.55 83.6453C135.48 64.0553 167.699 54.6654 200.093 57.2575C232.487 59.8496 262.803 74.2432 285.288 97.7072C307.772 121.171 320.861 152.073 322.071 184.548C323.28 217.023 312.526 248.813 291.849 273.884L109.55 83.6453ZM82.8685 110.554C64.2219 135.714 55.0776 166.659 57.0543 197.912C59.0309 229.165 72.001 258.712 93.6688 281.321C115.337 303.931 144.305 318.145 175.446 321.449C206.587 324.753 237.893 316.932 263.822 299.372L82.8685 110.592V110.554ZM189.5 18.95C144.268 18.95 100.887 36.9186 68.9031 68.903C36.9188 100.887 18.9502 144.267 18.9502 189.5C18.9502 234.733 36.9188 278.113 68.9031 310.097C100.887 342.081 144.268 360.05 189.5 360.05C234.733 360.05 278.113 342.081 310.097 310.097C342.082 278.113 360.05 234.733 360.05 189.5C360.05 144.267 342.082 100.887 310.097 68.903C278.113 36.9186 234.733 18.95 189.5 18.95Z'
		/>
	</svg>
);
export default StopIcon;
