import classNames from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useGetPartnerBalanceQuery } from 'store/services/personal-partner';

import s from './BalanceDropdown.module.scss';

interface IBalanceDropdown {
	className?: string;
}

const BalanceDropdown: FC<IBalanceDropdown> = ({ className }) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const { data: partnerBalance } = useGetPartnerBalanceQuery({});

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className={classNames(s.balance, className)} ref={dropdownRef}>
			<button className={s.trigger} onClick={() => setIsOpen(!isOpen)}>
				{partnerBalance && (partnerBalance?.balance?.amount || 0)} USD
			</button>
			{isOpen && (
				<div className={s.dropdown}>
					{partnerBalance && (
						<ul>
							{/* <li>
								<span>Total</span>
								<b>
									{partnerBalance?.processing + partnerBalance?.inPayment} USD
								</b>
							</li> */}
							<li>
								<span>In processing</span>
								<b>{partnerBalance.processing} USD</b>
							</li>

							<li>
								<span>In payment</span>
								<b>{partnerBalance.inPayment} USD</b>
							</li>

							<li>
								<span>Declined</span>
								<b>{partnerBalance.declined} USD</b>
							</li>

							<li>
								<span>Paid</span>
								<b>{partnerBalance.paid} USD</b>
							</li>
						</ul>
					)}
				</div>
			)}
		</div>
	);
};

export default BalanceDropdown;
