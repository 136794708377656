import { initProfile } from 'api/requests/auth';
import ErrorMessage from 'pages/components/ErrorMessage/ErrorMessage';
import React, { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import { Link } from 'react-router-dom';
import { Messengers } from 'types/auth';

import banner from '../../../../assets/images/modals/banner.png';
import {
	CaseIcon,
	CloseIcon,
	SkypeIcon,
	TelegramIcon
} from '../../../../assets/svg';
import { Button } from '../../Button/Button';
import Dropdown, { IDropdownOption } from '../../Dropdown/Dropdown';
import Input from '../../Input/Input';
import s from '../ModalLogin/ModalLogin.module.scss';

interface IModalRegistration {
	showRegistrationModal: boolean;
	setShowLoginModal: (show: boolean) => void;
	setShowRegistrationModal: (show: boolean) => void;
	setShowThanksModal: (show: boolean) => void;
}

interface RegistrationFormData {
	commandName: string;

	email: string;
	phoneNumber: string;

	messenger: string;
	messengerType: Messengers;

	password: string;
	confirmPassword: string;
}

export const optionsSocials: IDropdownOption[] = [
	{
		value: 'telegram',
		icon: <TelegramIcon />,
		label: 'Telegram'
	},
	{
		value: 'skype',
		icon: <SkypeIcon />,
		label: 'Skype'
	}
];

const ModalRegistration: FC<IModalRegistration> = ({
	showRegistrationModal,
	setShowRegistrationModal,
	setShowLoginModal,
	setShowThanksModal
}) => {
	const [messengerType, setMessengerType] = useState<Messengers>(
		Messengers.Telegram
	);
	const { t } = useTranslation();
	const {
		register,
		handleSubmit,
		control,
		formState: { errors }
	} = useForm<RegistrationFormData>();

	const showLogin = () => {
		setShowLoginModal(true);
		setShowRegistrationModal(false);
	};

	const showModalThanks = () => {
		setShowThanksModal(true);
		setShowRegistrationModal(false);
	};

	const onSubmit = async (data: RegistrationFormData) => {
		if (data.password !== data.confirmPassword) {
			alert("Passwords don't match");
			return;
		}

		if (messengerType) {
			const initProfileData = {
				...data,
				messengerType
			};

			const res = await initProfile(initProfileData);

			if (res && !res?.error) {
				showModalThanks();
			}
		}
	};

	return (
		<Modal
			show={showRegistrationModal}
			dialogClassName={s.modal}
			onHide={() => setShowRegistrationModal(false)}
			centered
		>
			<div className={s.inner}>
				<div
					className={s.close}
					onClick={() => setShowRegistrationModal(false)}
				>
					<CloseIcon />
				</div>
				<div className={s.wrapper}>
					<div className={s.authTabs}>
						<h2 className={s.title}> {t('modals.modalReg.title')}</h2>
					</div>
					<form className={s.form} onSubmit={handleSubmit(onSubmit)}>
						<div className={s.inputs}>
							<Input
								icon='email'
								type='email'
								placeholder={t('modals.modalReg.inputs.input2.label') as string}
								name='email'
								register={register}
								validationSchema={{
									required: 'Email is required',
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
										message: 'Invalid email address'
									},
									minLength: {
										value: 6,
										message: 'Min 6 length'
									}
								}}
							/>
							{errors.email && (
								<ErrorMessage>{errors.email.message}</ErrorMessage>
							)}
							<Controller
								name='phoneNumber'
								control={control}
								rules={{ required: true }}
								render={({ field: { onChange, value } }) => (
									<Input
										placeholder='+1(800)698 5581'
										countryCode={
											<PhoneInput
												placeholder='+1(800)698 5581'
												value={value}
												onChange={onChange}
												defaultCountry='US'
												id='phoneNumber'
											/>
										}
									/>
								)}
							/>

							{errors.phoneNumber && (
								<ErrorMessage>This field is required</ErrorMessage>
							)}

							<div className={s.socials}>
								<Dropdown
									onSelect={(v) => setMessengerType(v as Messengers)}
									className={s.dropdown}
									triggerClassName={s.dropdown__trigger}
									options={optionsSocials}
								/>
								<Input
									icon='link'
									placeholder={
										t('modals.modalReg.inputs.input5.placeholder') as string
									}
									name='messenger'
									register={register}
									validationSchema={{
										required: 'Messenger is required'
									}}
								/>
							</div>
							{errors.messenger && (
								<ErrorMessage>{errors.messenger.message}</ErrorMessage>
							)}
							<Input
								iconJsx={<CaseIcon />}
								placeholder='Company name'
								name='commandName'
								register={register}
								validationSchema={{
									required: 'Company name is required',
									maxLength: {
										value: 15,
										message: 'Max 15 length'
									},
									minLength: {
										value: 6,
										message: 'Min 6 length'
									}
								}}
							/>
							{errors.commandName && (
								<ErrorMessage>{errors.commandName.message}</ErrorMessage>
							)}

							<Input
								password
								icon='key'
								type='password'
								placeholder='Password'
								name='password'
								register={register}
								validationSchema={{
									required: 'Password is required',
									maxLength: {
										value: 15,
										message: 'Max 15 length'
									},
									minLength: {
										value: 6,
										message: 'Min 6 length'
									}
								}}
							/>
							{errors.password && (
								<ErrorMessage>{errors.password.message}</ErrorMessage>
							)}

							<Input
								password
								icon='key'
								type='password'
								placeholder='Confirm password'
								name='confirmPassword'
								register={register}
								validationSchema={{
									required: 'Confirm password is required',
									maxLength: {
										value: 15,
										message: 'Max 15 length'
									},
									minLength: {
										value: 6,
										message: 'Min 6 length'
									}
								}}
							/>
							{errors.confirmPassword && (
								<ErrorMessage>{errors.confirmPassword.message}</ErrorMessage>
							)}
						</div>
						<div className={s.button}>
							<Button type='submit'>{t('modals.modalReg.buttonSubmit')}</Button>
						</div>
					</form>
					<div className={s.agree}>
						{t('modals.modalReg.agree.text1')}{' '}
						<Link to='/agreement'>{t('modals.modalReg.agree.link1')}</Link>{' '}
						{t('modals.modalReg.agree.text2')}{' '}
						<Link to='/policy'>{t('modals.modalReg.agree.link2')}</Link>
					</div>

					<div className={s.authHelp}>
						{t('modals.modalReg.auth.title')}&nbsp;
						<button onClick={showLogin} className={s.authHelpBtn}>
							{t('modals.modalReg.auth.button')}
						</button>
					</div>
					<div className={s.authBanner}>
						<img className={s.authBannerImg} src={banner} alt='' />
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ModalRegistration;
