import classNames from 'classnames';
import React, { forwardRef, useState } from 'react';
import PhoneInput from 'react-phone-number-input';

import { GlobalSvgSelector } from '../../../assets/images/icons/GlobalSvgSelector';

import s from './Input.module.scss';

interface InputProps {
	className?: string;
	placeholder?: string;
	type?: string;
	disabled?: boolean;
	error?: boolean;
	password?: boolean;
	required?: boolean;
	label?: string;
	icon?: string;
	iconJsx?: JSX.Element;
	validationSchema?: any;
	register?: any;
	name?: string;
	countryCode?: JSX.Element;
	textarea?: JSX.Element;
}

const Input: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
	{
		className,
		placeholder,
		type,
		disabled,
		error,
		password,
		required,
		label,
		icon,
		iconJsx,
		validationSchema,
		register,
		name,
		countryCode,
		textarea
	},
	ref
) => {
	const [inputValue, setInputValue] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		const value = event.target.value;
		setInputValue(value);
	};

	const togglePasswordVisibility = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};

	const inputType = password && !showPassword ? 'password' : 'text';
	const inputClasses = classNames(s.input, {
		'input--error': error
	});

	return (
		<label className={error ? classNames(s.error__input, s.label) : s.label}>
			{label && <span>{label}</span>}
			<div className={classNames(s.input__wrapper, className)}>
				{icon && <GlobalSvgSelector id={icon} />}
				{iconJsx && iconJsx}
				{countryCode && countryCode}
				{textarea && textarea}
				{!countryCode && !textarea && (
					<input
						ref={ref}
						type={inputType}
						className={inputClasses}
						disabled={disabled}
						placeholder={placeholder}
						value={inputValue}
						required={required}
						name={name}
						{...(register && register(name, validationSchema))}
						onChange={handleChange}
					/>
				)}
				{password && (
					<div className={s.input__icon} onClick={togglePasswordVisibility}>
						<GlobalSvgSelector id={showPassword ? 'eye_hide' : 'eye'} />{' '}
					</div>
				)}
			</div>
			{error && <span className={s.error}>Please, fill out this field</span>}
		</label>
	);
};

export default forwardRef(Input);
