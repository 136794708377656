import React from 'react';

import s from './Navigation.module.scss';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Navigation = ({ toggleMenu }) => {
  const { t } = useTranslation();

  const navigation = [
    {
      title: t('navigation.link1'),
      link: '/',
    },

    {
      title: t('navigation.link3'),
      link: '/faq',
    },
    {
      title: t('navigation.link4'),
      link: '/information',
    },
    // {
    //   title: t('navigation.link5'),
    //   link: '/testimonials',
    // },
  ];

  return (
    <div className={s.navigation}>
      <nav aria-label="navigation">
        <ul>
          {navigation &&
            navigation.map((item, index) => (
              <li key={index} onClick={toggleMenu}>
                <Link to={item.link}>{item.title}</Link>
              </li>
            ))}
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
