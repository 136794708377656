import React from 'react';
import { SVGProps } from 'react';

const TelegramIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={24}
		height={24}
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path d='M19.2002 4.40021L2.90022 10.7002C1.80022 11.1002 1.80022 11.8002 2.70022 12.0002L6.80022 13.3002L8.40022 18.1002C8.60022 18.6002 8.50022 18.8002 9.00022 18.8002C9.40022 18.8002 9.60022 18.6002 9.80022 18.4002C9.90022 18.3002 10.8002 17.4002 11.8002 16.4002L16.0002 19.5002C16.8002 19.9002 17.3002 19.7002 17.5002 18.8002L20.3002 5.70021C20.6002 4.60021 19.9002 4.00021 19.2002 4.40021ZM17.1002 7.40021L9.30022 14.5002L9.00022 17.8002L7.40022 13.0002L16.6002 7.20021C17.0002 6.90021 17.4002 7.10021 17.1002 7.40021Z' />
	</svg>
);
export default TelegramIcon;
