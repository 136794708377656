import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { API_ROUTES } from 'api/routes';

import { baseQueryWithReauth } from '../interceptor';

import { OfferPaymentModel } from './offers';

export type TPromo = {
	date: Date;
	offerType: OfferPaymentModel;
	action: string;
	promoId: string;
	approved: boolean;
	cpa: number;
	promoLink: string;
	name?: string;
	additionalPostbackParams?: string;
};

export type TTraffic = {
	id: string;
	clicks: number;
	registrations: number;
	fdp: number;
	rdp: number;
	depositsSum: number;
	betSum: number;
	winSum: number;
	loseSum: number;
	cpa: number;
	date: string;
	created_at: Date;
	updatedAt: Date;
	campaignId: string;
	campaignName?: string;
	nanoId?: string;
};

export type TTotalTrafficStatistic = {
	fdp: number;
	rdp: number;
	clicks: number;
	winSum: number;
	betSum: number;
	loseSum: number;
	depositsSum: number;
	registrations: number;
	income?: number;
};

export type TGetStatisticPromo = {
	total: TTotalTrafficStatistic;
	traffics: TTraffic[];

	campaigns?: any[];
	groupedByCampaignId?: {
		[key: string]: TTraffic[];
	};
};

export type TGetPromos = {
	count: number;
	promos: TPromo[];
};

export const promosApi = createApi({
	reducerPath: 'promosApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['Promos'],
	endpoints: (builder) => ({
		createPromo: builder.mutation<any, any>({
			query: (params) => ({
				url: `${API_ROUTES.CREATE_PROMO}`,
				body: params,
				method: 'POST'
			}),
			invalidatesTags: ['Promos']
		}),

		getPromos: builder.query<TGetPromos, any>({
			query: (params) => ({
				url: API_ROUTES.GET_ALL_PROMO,
				params
			}),
			providesTags: ['Promos']
		}),

		getStatisticOnPromo: builder.query<TGetStatisticPromo, any>({
			query: (params: any) => ({
				url: `${API_ROUTES.GET_STATISTIC_ON_PROMO}/${params.promoId}`,
				body: params,
				method: 'POST'
			}),
			providesTags: ['Promos']
		}),

		getPlayersStatistic: builder.query<any, any>({
			query: (params: any) => ({
				url: `${API_ROUTES.GET_PLAYER_STATISTIC}`,
				body: params,
				method: 'POST'
			}),
			providesTags: ['Promos']
		}),

		getTodayStatisticOnCommand: builder.query<TGetStatisticPromo, any>({
			query: (commandId: string) => ({
				url: `${API_ROUTES.GET_TODAY_STATISTIC_ON_PROMO}/${commandId}`
			}),
			providesTags: ['Promos']
		}),

		editPostback: builder.mutation<any, any>({
			query: (params) => ({
				url: `${API_ROUTES.EDIT_POSTBACKS}`,
				body: params,
				method: 'POST'
			}),
			invalidatesTags: ['Promos']
		}),

		editPromo: builder.mutation<any, any>({
			query: (params) => ({
				url: `${API_ROUTES.EDIT_PROMO}`,
				body: params,
				method: 'POST'
			}),
			invalidatesTags: ['Promos']
		}),

		getPostback: builder.query<any, any>({
			query: (params) => ({
				url: API_ROUTES.GET_ALL_POSTBACKS,
				params
			}),
			providesTags: ['Promos']
		}),

		getRangeStatisticOnPromo: builder.query<any, any>({
			query: (params) => ({
				url: API_ROUTES.GET_RANGE_STATISTIC_ON_PROMO,
				body: params,
				method: 'POST'
			}),
			providesTags: ['Promos']
		})
	})
});

export const {
	useGetPostbackQuery,
	useEditPromoMutation,
	useCreatePromoMutation,
	useEditPostbackMutation,
	useGetPlayersStatisticQuery,
	useGetStatisticOnPromoQuery,
	useGetRangeStatisticOnPromoQuery,
	useGetTodayStatisticOnCommandQuery
} = promosApi;
