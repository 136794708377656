const Header = () => (
	<tr>
		<td>ID</td>
		<td>GEO</td>
		<td>Stream</td>
		<td>Click Id</td>
		<td>Registrations date</td>
		<td>Last login</td>
		<td>Bet Count</td>
		<td>Bet Sum</td>
		<td>Win Count</td>
		<td>Win Sum</td>
		<td>Lose Count</td>
		<td>Lose Sum</td>
	</tr>
);

export default Header;
