import { $api } from 'api';
import { API_ROUTES, BASE_URL } from 'api/routes';
import axios from 'axios';
import local from 'hooks/local';
import { TInitProfile, TSignIn } from 'types/auth';
import { errorNotification } from 'utils/toasts';

export const initProfile = async (params: TInitProfile) => {
	const { data } = await $api.post(
		`${BASE_URL}/${API_ROUTES.INIT_PROFILE}`,
		params
	);

	if (data?.response?.error || data?.error) {
		errorNotification(data?.response?.message || data?.message[0]);
	}

	return data;
};

export const signIn = async (params: TSignIn) => {
	const ipChecker = await axios.get('https://lumtest.com/echo.json');

	const { data } = await $api.post(`${BASE_URL}/${API_ROUTES.LOGIN}`, {
		...params,
		ip: ipChecker.data.ip
	});

	const storage = local;
	const storageToken = storage.local<string>('token', { stringify: false });

	if (data && !data?.error && data && 'token' in data) {
		storageToken.set(data.token);
	}

	return data;
};

export const getUserSessions = async () => {
	const { data } = await $api.get(`${BASE_URL}/auth/sessions?total=10`);

	return data;
};

export const logout = async (currentToken: string) => {
	const response = await $api.post(`${BASE_URL}/auth/logout`, {
		currentToken
	});

	return response.data;
};

export const getMe = async () => {
	const response = await $api.get(`${BASE_URL}/auth/me`);

	return response.data;
};
