import React from 'react';
import { SVGProps } from 'react';

const CaseIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={800}
		height={800}
		viewBox='0 0 800 800'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<g clipPath='url(#clip0_2190_23495)'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M733.333 800H66.6667C48.9856 800 32.0296 792.976 19.5272 780.474C7.02476 767.971 0 751.014 0 733.333V200C0 182.319 7.02476 165.362 19.5272 152.859C32.0296 140.357 48.9856 133.333 66.6667 133.333H233.333V66.6667C233.333 48.9856 240.358 32.0286 252.861 19.5262C265.363 7.02374 282.319 0 300 0H500C517.681 0 534.637 7.02374 547.139 19.5262C559.642 32.0286 566.667 48.9856 566.667 66.6667V133.333H733.333C751.014 133.333 767.97 140.357 780.473 152.859C792.975 165.362 800 182.319 800 200V733.333C800 751.014 792.975 767.971 780.473 780.474C767.97 792.976 751.014 800 733.333 800ZM500 66.6667H300V133.333H500V66.6667ZM733.333 500H566.667C566.667 517.681 559.642 534.638 547.139 547.141C534.637 559.643 517.681 566.667 500 566.667H300C282.319 566.667 265.363 559.643 252.861 547.141C240.358 534.638 233.333 517.681 233.333 500H66.6667V433.333H233.333C233.333 415.652 240.358 398.695 252.861 386.193C265.363 373.69 282.319 366.667 300 366.667H500C517.681 366.667 534.637 373.69 547.139 386.193C559.642 398.695 566.667 415.652 566.667 433.333H733.333V200H66.6667V733.333H733.333V500ZM500 466.667C500 457.826 496.49 449.348 490.238 443.096C483.987 436.845 475.507 433.333 466.667 433.333H333.333C324.493 433.333 316.013 436.845 309.762 443.096C303.51 449.348 300 457.826 300 466.667C300 475.507 303.51 483.986 309.762 490.237C316.013 496.488 324.493 500 333.333 500H466.667C475.507 500 483.987 496.488 490.238 490.237C496.49 483.986 500 475.507 500 466.667Z'
			/>
		</g>
		<defs>
			<clipPath id='clip0_2190_23495'>
				<rect width={800} height={800} fill='white' />
			</clipPath>
		</defs>
	</svg>
);
export default CaseIcon;
