import { BASE_URL } from 'api/routes';
import axios from 'axios';
import { errorNotification } from 'utils/toasts';

import * as auth from './requests/auth';

const $api = axios.create({
	baseURL: BASE_URL
});

$api.interceptors.request.use((config: any) => {
	if (config.headers) {
		config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
	}

	return config;
});

$api.interceptors.response.use(
	(response: any) => {
		if (response?.data?.error && response?.data?.message) {
			const message = response?.data?.message;

			errorNotification(message);
		}

		return response;
	},
	async (error: any) => {
		const originalRequest = error.config;

		if (localStorage.getItem('token')) {
			if (
				error?.response?.status === 401 &&
				error?.config &&
				!error?.config.isRetry
			) {
				originalRequest.isRetry = true;
				try {
					const response = await axios.get(`${BASE_URL}/auth/refresh`, {
						headers: error.config.headers
					});

					localStorage.setItem('token', response?.data?.token);

					return $api.request(originalRequest);
				} catch (e) {
					localStorage.removeItem('token');
					window.location.href = '/';
				}
			}
		}

		errorNotification(error.message || error.message[0] || error);

		return error;
	}
);

export { auth, $api };
