/* eslint-disable no-sparse-arrays */
import { Button } from 'pages/components/Button/Button';
import React, { useState } from 'react';
import {
	useEditPostbackMutation,
	useGetPostbackQuery
} from 'store/services/promos';

import BaseTable from '../../../components/BaseTable';
import InputCabinet from '../../../components/InputCabinet/InputCabinet';

const headers = ['#', 'Promo', 'Additional Params', 'Postback action'];
const Postback = () => {
	const [registerPostback, setRegisterPostback] = useState<string>();
	const [firstDepositPostback, setFirstDepositPostback] = useState<string>();

	const [editPostback] = useEditPostbackMutation();
	const { data, refetch, isLoading } = useGetPostbackQuery({});

	const savePostbacks = async () => {
		await editPostback({ registerPostback, firstDepositPostback });

		refetch();
	};

	const postbacks = data?.postbacks;
	const campaignsAdditionalParams = data?.campaignsAdditionalParams || [];

	let quantity = 1;

	console.log(campaignsAdditionalParams);

	return (
		<div className='main-settings'>
			<div className='main-settings__sections'>
				<div className='main-settings__section main-settings-section'>
					<div className='main-settings-section__title'>Global Postbacks</div>
					<div className='main-settings-section__content'>
						<div className='main-settings-section__inputs'>
							<InputCabinet
								onChange={(e) => setRegisterPostback(e.target.value)}
								className='main-settings-section__input'
								label='Register'
								placeholder='Register'
								value={postbacks?.registerPostback || ''}
								small
								required
							/>
							<InputCabinet
								onChange={(e) => setFirstDepositPostback(e.target.value)}
								className='main-settings-section__input'
								label='First deposit'
								placeholder='First deposit'
								value={postbacks?.firstDepositPostback || ''}
								small
								required
							/>

							<Button
								className='main-settings-section__save-button'
								onClick={() => savePostbacks()}
							>
								Save
							</Button>
						</div>
					</div>
				</div>
				<div className='main-settings__section main-settings-section'>
					<div className='main-settings-section__title'>
						Detailed <small>by streams</small>
					</div>
					<div className='main-settings-section__content'>
						<div className='main-settings-section__table table' data-simplebar>
							{campaignsAdditionalParams &&
								campaignsAdditionalParams?.length > 0 && (
									<BaseTable
										headers={headers}
										rows={campaignsAdditionalParams
											.map(({ nanoId, additionalPostbackParams }: any) => {
												const current = quantity;
												quantity = quantity + 2;

												return [
													[
														current,
														nanoId,
														additionalPostbackParams || '',
														'REGISTRATION'
													],
													[
														current + 1,
														nanoId,
														additionalPostbackParams || '',
														'FIRST DEPOSIT'
													]
												];
											})
											.flat()}
										isEmpty={(campaignsAdditionalParams?.length || 0) === 0}
									/>
								)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Postback;
