import { CabinetSvgSelector } from 'assets/images/icons/CabinetSvgSelector';
import dayjs from 'dayjs';
import ModalPromoEdit from 'pages/components/modals/ModalPromoEdit/ModalPromoEdit';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { OfferPaymentModel } from 'store/services/offers';
import { TPromo } from 'store/services/promos';

export const TableRecord = ({ promo }: { promo: TPromo }) => {
	const [showModal, setShowModal] = useState<any | boolean>(false);

	return (
		<tr>
			{/* <td>Binobi. casino CL</td> */}
			<td>
				<span>
					{promo.promoId}
					<button
						onClick={() =>
							setShowModal({
								name: promo.name,
								campaignId: promo.promoId,
								additionalPostbackParams: promo.additionalPostbackParams
							})
						}
						className='icon'
					>
						<CabinetSvgSelector id='add' />
					</button>
				</span>
			</td>

			<td>{promo.name}</td>

			<td>
				{promo?.offerType || (
					<span style={{ color: 'red' }}> Offer not set</span>
				)}
			</td>
			<td>
				{promo.approved ? (
					<div style={{ color: 'green' }}>Approved</div>
				) : (
					<div style={{ color: 'red' }}>Needs Approval</div>
				)}
			</td>
			<td>
				{!promo?.offerType ? (
					<span style={{ color: 'red' }}> Offer not set</span>
				) : (
					<>
						{promo.cpa &&
						!promo?.offerType?.includes(OfferPaymentModel.REVSHARE) ? (
							promo.cpa
						) : (
							<>
								{promo.offerType.includes(OfferPaymentModel.REVSHARE) ? (
									<div style={{ color: 'green' }}>RevShare</div>
								) : (
									<div style={{ color: 'red' }}>Expects cpa</div>
								)}
							</>
						)}
					</>
				)}
			</td>
			<td>{promo.action}</td>

			<td>{promo.additionalPostbackParams}</td>
			<td>
				<a target='_blank' rel='noreferrer' href={promo.promoLink}>
					{promo.promoLink}
				</a>
			</td>
			<td>{dayjs(promo.date).format('YYYY-MM-DD HH:mm')}</td>
			<td>
				{!promo?.offerType ? (
					<div className='icons'>
						<span style={{ color: 'red' }}> Offer not set</span>
						<Link
							to={`/promo/my/statistic/?promoId=${promo.promoId}`}
							className='icon'
						>
							<CabinetSvgSelector id='chart' />
						</Link>
					</div>
				) : (
					<>
						{promo.approved &&
							(promo.cpa ||
								promo.offerType.includes(OfferPaymentModel.REVSHARE)) && (
								<div className='icons'>
									{/* <button
					onClick={() => navigator.clipboard.writeText(promo.promoLink)}
					className='icon'
				>
					<CabinetSvgSelector id='link' />
				</button>
				<button className='icon'>
					<CabinetSvgSelector id='yey' />
				</button>
				<button onClick={() => setShowModalPromoEdit(true)} className='icon'>
					<CabinetSvgSelector id='settings' />
				</button> */}
									<Link
										to={`/promo/my/statistic/?promoId=${promo.promoId}`}
										className='icon'
									>
										<CabinetSvgSelector id='chart' />
									</Link>
								</div>
							)}
					</>
				)}
			</td>

			{showModal && (
				<ModalPromoEdit
					showModalPromoEdit={showModal}
					setShowModalPromoEdit={setShowModal}
				/>
			)}
		</tr>
	);
};
