import RUflag from 'assets/images/flags/RUflag.png';
import UAflag from 'assets/images/flags/UAflag.png';
import usaFlag from 'assets/images/flags/usaFlag.png';

export const getLanguageIcon = {
	RU: RUflag,
	US: usaFlag,
	UA: UAflag
} as { [k: string]: string };

export const getCountryIcon = (search: string | undefined) => {
	return countries.find(({ value }) => search === value)?.icon;
};

export const countries = [
	{
		label: 'Albania',
		value: 'AL',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/36/Flag_of_Albania.svg'
	},
	{
		label: 'Åland Islands',
		value: 'AX',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/52/Flag_of_%C3%85land.svg'
	},
	{
		label: 'Algeria',
		value: 'DZ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Algeria.svg'
	},
	{
		label: 'American Samoa',
		value: 'AS',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/87/Flag_of_American_Samoa.svg'
	},
	{
		label: 'Andorra',
		value: 'AD',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Andorra.svg'
	},
	{
		label: 'Angola',
		value: 'AO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Angola.svg'
	},
	{
		label: 'Anguilla',
		value: 'AI',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Anguilla.svg'
	},
	{
		label: 'Antarctica',
		value: 'AQ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_the_Antarctic_Treaty.svg'
	},
	{
		label: 'Antigua and Barbuda',
		value: 'AG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Antigua_and_Barbuda.svg'
	},
	{
		label: 'Argentina',
		value: 'AR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg'
	},
	{
		label: 'Armenia',
		value: 'AM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/2f/Flag_of_Armenia.svg'
	},
	{
		label: 'Aruba',
		value: 'AW',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Aruba.svg'
	},
	{
		label: 'Australia',
		value: 'AU',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_%28converted%29.svg'
	},
	{
		label: 'Austria',
		value: 'AT',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg'
	},
	{
		label: 'Azerbaijan',
		value: 'AZ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Azerbaijan.svg'
	},
	{
		label: 'Bahamas (the)',
		value: 'BS',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/93/Flag_of_the_Bahamas.svg'
	},
	{
		label: 'Bahrain',
		value: 'BH',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Bahrain.svg'
	},
	{
		label: 'Bangladesh',
		value: 'BD',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f9/Flag_of_Bangladesh.svg'
	},
	{
		label: 'Barbados',
		value: 'BB',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ef/Flag_of_Barbados.svg'
	},
	{
		label: 'Belarus',
		value: 'BY',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/85/Flag_of_Belarus.svg'
	},
	{
		label: 'Belgium',
		value: 'BE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Belgium.svg'
	},
	{
		label: 'Belize',
		value: 'BZ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e7/Flag_of_Belize.svg'
	},
	{
		label: 'Benin',
		value: 'BJ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Benin.svg'
	},
	{
		label: 'Bermuda',
		value: 'BM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bermuda.svg'
	},
	{
		label: 'Bhutan',
		value: 'BT',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/91/Flag_of_Bhutan.svg'
	},
	{
		label: 'Bolivia (Plurinational State of)',
		value: 'BO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/de/Flag_of_Bolivia_%28state%29.svg'
	},
	{
		label: 'Bonaire, Sint Eustatius and Saba',
		value: 'BQ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg'
	},
	{
		label: 'Bosnia and Herzegovina',
		value: 'BA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bosnia_and_Herzegovina.svg'
	},
	{
		label: 'Botswana',
		value: 'BW',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Botswana.svg'
	},
	{
		label: 'Bouvet Island',
		value: 'BV',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg'
	},
	{
		label: 'Brazil',
		value: 'BR',
		icon: 'https://upload.wikimedia.org/wikipedia/en/0/05/Flag_of_Brazil.svg'
	},
	{
		label: 'British Indian Ocean Territory (the)',
		value: 'IO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/6e/Flag_of_the_British_Indian_Ocean_Territo ry.svg'
	},
	{
		label: 'Brunei Darussalam',
		value: 'BN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Brunei.svg'
	},
	{
		label: 'Bulgaria',
		value: 'BG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Bulgaria.svg'
	},
	{
		label: 'Burkina Faso',
		value: 'BF',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Burkina_Faso.svg'
	},
	{
		label: 'Burundi',
		value: 'BI',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/50/Flag_of_Burundi.svg'
	},
	{
		label: 'Cabo Verde',
		value: 'CV',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Cape_Verde.svg'
	},
	{
		label: 'Cambodia',
		value: 'KH',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Cambodia.svg'
	},
	{
		label: 'Cameroon',
		value: 'CM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Cameroon.svg'
	},
	{
		label: 'Canada',
		value: 'CA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Canada_%28Pantone%29.svg'
	},
	{
		label: 'Cayman Islands (the)',
		value: 'KY',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_the_Cayman_Islands.svg'
	},
	{
		label: 'Central African Republic (the)',
		value: 'CF',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/6f/Flag_of_the_Central_African_Republic.svg '
	},
	{
		label: 'Chad',
		value: 'TD',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4b/Flag_of_Chad.svg'
	},
	{
		label: 'Chile',
		value: 'CL',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/78/Flag_of_Chile.svg'
	},
	{
		label: 'China',
		value: 'CN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_Chin a.svg'
	},
	{
		label: 'Christmas Island',
		value: 'CX',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/67/Flag_of_Christmas_Island.svg'
	},
	{
		label: 'Cocos (Keeling) Islands (the)',
		value: 'CC',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Cocos_%28Keeling%29_Island s.svg'
	},
	{
		label: 'Colombia',
		value: 'CO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Colombia.svg'
	},
	{
		label: 'Comoros (the)',
		value: 'KM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/94/Flag_of_the_Comoros.svg'
	},

	{
		label: 'Congo (the Democratic Republic of the)',
		value: 'CD',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/6f/Flag_of_the_Democratic_Republic_of_the _Congo.svg'
	},
	{
		label: 'Congo (the)',
		value: 'CG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_the_Republic_of_the_Congo.svg'
	},
	{
		label: 'Cook Islands (the)',
		value: 'CK',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/35/Flag_of_the_Cook_Islands.svg'
	},
	{
		label: 'Costa Rica',
		value: 'CR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Costa_Rica_%28state%29.svg'
	},
	{
		label: 'Croatia',
		value: 'HR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg'
	},
	{
		label: 'Cuba',
		value: 'CU',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bd/Flag_of_Cuba.svg'
	},
	{
		label: 'Curaçao',
		value: 'CW',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/b1/Flag_of_Cura%C3%A7ao.svg'
	},
	{
		label: 'Cyprus',
		value: 'CY',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Cyprus.svg'
	},
	{
		label: 'Czechia',
		value: 'CZ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_Czech_Republic.svg'
	},
	{
		label: 'Côte dIvoire',
		value: 'CI',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_C%C3%B4te_d%27Ivoire.svg'
	},
	{
		label: 'Denmark',
		value: 'DK',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg'
	},
	{
		label: 'Djibouti',
		value: 'DJ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_Djibouti.svg'
	},
	{
		label: 'Dominica',
		value: 'DM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c4/Flag_of_Dominica.svg'
	},
	{
		label: 'Dominican Republic (the)',
		value: 'DO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_the_Dominican_Republic.svg'
	},
	{
		label: 'Ecuador',
		value: 'EC',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e8/Flag_of_Ecuador.svg'
	},
	{
		label: 'Egypt',
		value: 'EG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Egypt.svg'
	},
	{
		label: 'El Salvador',
		value: 'SV',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_El_Salvador.svg'
	},
	{
		label: 'Equatorial Guinea',
		value: 'GQ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Equatorial_Guinea.svg'
	},
	{
		label: 'Eritrea',
		value: 'ER',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_Eritrea.svg'
	},
	{
		label: 'Estonia',
		value: 'EE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/8f/Flag_of_Estonia.svg'
	},
	{
		label: 'Eswatini',
		value: 'SZ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1e/Flag_of_Eswatini.svg'
	},
	{
		label: 'Ethiopia',
		value: 'ET',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/71/Flag_of_Ethiopia.svg'
	},
	{
		label: 'Falkland Islands (the) [Malvinas]',
		value: 'FK',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_Falkland_Islands.svg'
	},
	{
		label: 'Faroe Islands (the)',
		value: 'FO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/Flag_of_the_Faroe_Islands.svg'
	},
	{
		label: 'Fiji',
		value: 'FJ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Fiji.svg'
	},

	{
		label: 'Finland',
		value: 'FI',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Finland.svg'
	},
	{
		label: 'France',
		value: 'FR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg'
	},
	{
		label: 'French Guiana',
		value: 'GF',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_French_Guiana.svg'
	},
	{
		label: 'French Polynesia',
		value: 'PF',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/db/Flag_of_French_Polynesia.svg'
	},
	{
		label: 'French Southern Territories (the)',
		value: 'TF',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a7/Flag_of_the_French_Southern_and_Antar ctic_Lands.svg'
	},
	{
		label: 'Gabon',
		value: 'GA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/04/Flag_of_Gabon.svg'
	},
	{
		label: 'Gambia (the)',
		value: 'GM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_The_Gambia.svg'
	},
	{
		label: 'Georgia',
		value: 'GE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Georgia.svg'
	},
	{
		label: 'Germany',
		value: 'DE',
		icon: 'https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg'
	},
	{
		label: 'Ghana',
		value: 'GH',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg'
	},
	{
		label: 'Gibraltar',
		value: 'GI',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/02/Flag_of_Gibraltar.svg'
	},
	{
		label: 'Greece',
		value: 'GR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Greece.svg'
	},
	{
		label: 'Greenland',
		value: 'GL',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_Greenland.svg'
	},
	{
		label: 'Grenada',
		value: 'GD',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Grenada.svg'
	},
	{
		label: 'Guadeloupe',
		value: 'GP',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/04/Flag_of_Guadeloupe_%28local%29.svg'
	},
	{
		label: 'Guam',
		value: 'GU',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/07/Flag_of_Guam.svg'
	},
	{
		label: 'Guatemala',
		value: 'GT',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ec/Flag_of_Guatemala.svg'
	},
	{
		label: 'Guernsey',
		value: 'GG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Guernsey.svg'
	},
	{
		label: 'Guinea',
		value: 'GN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_Guinea.svg'
	},
	{
		label: 'Guinea-Bissau',
		value: 'GW',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Guinea-Bissau.svg'
	},
	{
		label: 'Guyana',
		value: 'GY',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_Guyana.svg'
	},
	{
		label: 'Haiti',
		value: 'HT',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Haiti.svg'
	},
	{
		label: 'Heard Island and McDonald Islands',
		value: 'HM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Antarctica.svg'
	},
	{
		label: 'Holy See (the)',
		value: 'VA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_the_Vatican_City.svg'
	},
	{
		label: 'Honduras',
		value: 'HN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/82/Flag_of_Honduras.svg'
	},

	{
		label: 'Hong Kong',
		value: 'HK',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Hong_Kong.svg'
	},
	{
		label: 'Hungary',
		value: 'HU',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Hungary.svg'
	},
	{
		label: 'Iceland',
		value: 'IS',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Iceland.svg'
	},
	{
		label: 'India',
		value: 'IN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg'
	},
	{
		label: 'Indonesia',
		value: 'ID',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg'
	},
	{
		label: 'Iran (Islamic Republic of)',
		value: 'IR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/ca/Flag_of_Iran.svg'
	},
	{
		label: 'Iraq',
		value: 'IQ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Iraq.svg'
	},
	{
		label: 'Ireland',
		value: 'IE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/45/Flag_of_Ireland.svg'
	},
	{
		label: 'Isle of Man',
		value: 'IM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_the_Isle_of_Man.svg'
	},
	{
		label: 'Israel',
		value: 'IL',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg'
	},
	{
		label: 'Italy',
		value: 'IT',
		icon: 'https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg'
	},
	{
		label: 'Jamaica',
		value: 'JM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Jamaica.svg'
	},
	{
		label: 'Japan',
		value: 'JP',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9e/Flag_of_Japan.svg'
	},
	{
		label: 'Jersey',
		value: 'JE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1c/Flag_of_Jersey.svg'
	},
	{
		label: 'Jordan',
		value: 'JO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c0/Flag_of_Jordan.svg'
	},
	{
		label: 'Kazakhstan',
		value: 'KZ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kazakhstan.svg'
	},
	{
		label: 'Kenya',
		value: 'KE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Kenya.svg'
	},
	{
		label: 'Kiribati',
		value: 'KI',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kiribati.svg'
	},
	{
		label: "Korea (the Democratic People's Republic of)",
		value: 'KP',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Flag_of_North_Korea.svg'
	},
	{
		label: 'Korea (the Republic of)',
		value: 'KR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_South_Korea.svg'
	},
	{
		label: 'Kuwait',
		value: 'KW',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Kuwait.svg'
	},
	{
		label: 'Kyrgyzstan',
		value: 'KG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c7/Flag_of_Kyrgyzstan.svg'
	},
	{
		label: "Lao People's Democratic Republic (the)",
		value: 'LA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Laos.svg'
	},
	{
		label: 'Latvia',
		value: 'LV',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Latvia.svg'
	},
	{
		label: 'Lebanon',
		value: 'LB',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/59/Flag_of_Lebanon.svg'
	},
	{
		label: 'Lesotho',
		value: 'LS',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Flag_of_Lesotho.svg'
	},

	{
		label: 'Liberia',
		value: 'LR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/b8/Flag_of_Liberia.svg'
	},
	{
		label: 'Libya',
		value: 'LY',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_Libya.svg'
	},
	{
		label: 'Liechtenstein',
		value: 'LI',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/47/Flag_of_Liechtenstein.svg'
	},
	{
		label: 'Lithuania',
		value: 'LT',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Lithuania.svg'
	},
	{
		label: 'Luxembourg',
		value: 'LU',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/da/Flag_of_Luxembourg.svg'
	},
	{
		label: 'Macao',
		value: 'MO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/63/Flag_of_Macau.svg'
	},
	{
		label: 'Madagascar',
		value: 'MG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Madagascar.svg'
	},
	{
		label: 'Malawi',
		value: 'MW',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d1/Flag_of_Malawi.svg'
	},
	{
		label: 'Malaysia',
		value: 'MY',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_Malaysia.svg'
	},
	{
		label: 'Maldives',
		value: 'MV',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Maldives.svg'
	},
	{
		label: 'Mali',
		value: 'ML',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_Mali.svg'
	},
	{
		label: 'Malta',
		value: 'MT',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Malta.svg'
	},
	{
		label: 'Marshall Islands (the)',
		value: 'MH',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/2e/Flag_of_the_Marshall_Islands.svg'
	},
	{
		label: 'Martinique',
		value: 'MQ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/52/Flag_of_Martinique.svg'
	},
	{
		label: 'Mauritania',
		value: 'MR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/43/Flag_of_Mauritania.svg'
	},
	{
		label: 'Mauritius',
		value: 'MU',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Mauritius.svg'
	},
	{
		label: 'Mayotte',
		value: 'YT',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Flag_of_Mayotte_%28local%29.svg'
	},
	{
		label: 'Mexico',
		value: 'MX',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.svg'
	},
	{
		label: 'Micronesia (Federated States of)',
		value: 'FM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e4/Flag_of_the_Federated_States_of_Micron esia.svg'
	},
	{
		label: 'Moldova (the Republic of)',
		value: 'MD',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Moldova.svg'
	},
	{
		label: 'Monaco',
		value: 'MC',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ea/Flag_of_Monaco.svg'
	},
	{
		label: 'Mongolia',
		value: 'MN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Mongolia.svg'
	},
	{
		label: 'Montenegro',
		value: 'ME',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Montenegro.svg'
	},
	{
		label: 'Montserrat',
		value: 'MS',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Montserrat.svg'
	},
	{
		label: 'Morocco',
		value: 'MA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Morocco.svg'
	},

	{
		label: 'Mozambique',
		value: 'MZ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Mozambique.svg'
	},
	{
		label: 'Myanmar',
		value: 'MM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/8c/Flag_of_Myanmar.svg'
	},
	{
		label: 'Namibia',
		value: 'NA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Namibia.svg'
	},
	{
		label: 'Nauru',
		value: 'NR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/30/Flag_of_Nauru.svg'
	},
	{
		label: 'Nepal',
		value: 'NP',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9b/Flag_of_Nepal.svg'
	},
	{
		label: 'Netherlands (the)',
		value: 'NL',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg'
	},
	{
		label: 'New Caledonia',
		value: 'NC',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_New_Caledonia.svg'
	},
	{
		label: 'New Zealand',
		value: 'NZ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/3e/Flag_of_New_Zealand.svg'
	},
	{
		label: 'Nicaragua',
		value: 'NI',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Nicaragua.svg'
	},
	{
		label: 'Niger (the)',
		value: 'NE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f4/Flag_of_Niger.svg'
	},
	{
		label: 'Nigeria',
		value: 'NG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_Nigeria.svg'
	},
	{
		label: 'Niue',
		value: 'NU',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Niue.svg'
	},
	{
		label: 'Norfolk Island',
		value: 'NF',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Norfolk_Island.svg'
	},
	{
		label: 'Northern Mariana Islands (the)',
		value: 'MP',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e0/Flag_of_the_Northern_Mariana_Islands.sv g'
	},
	{
		label: 'Norway',
		value: 'NO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg'
	},
	{
		label: 'Oman',
		value: 'OM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Oman.svg'
	},
	{
		label: 'Pakistan',
		value: 'PK',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg'
	},
	{
		label: 'Palau',
		value: 'PW',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Palau.svg'
	},
	{
		label: 'Palestine, State of',
		value: 'PS',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Palestine.svg'
	},
	{
		label: 'Panama',
		value: 'PA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/a/ab/Flag_of_Panama.svg'
	},
	{
		label: 'Papua New Guinea',
		value: 'PG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e3/Flag_of_Papua_New_Guinea.svg'
	},
	{
		label: 'Paraguay',
		value: 'PY',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Paraguay.svg'
	},
	{
		label: 'Peru',
		value: 'PE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Peru.svg'
	},
	{
		label: 'Philippines (the)',
		value: 'PH',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_the_Philippines.svg'
	},
	{
		label: 'Pitcairn',
		value: 'PN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_the_Pitcairn_Islands.svg'
	},

	{
		label: 'Poland',
		value: 'PL',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/12/Flag_of_Poland.svg'
	},
	{
		label: 'Portugal',
		value: 'PT',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg'
	},
	{
		label: 'Puerto Rico',
		value: 'PR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/28/Flag_of_Puerto_Rico.svg'
	},
	{
		label: 'Qatar',
		value: 'QA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Qatar.svg'
	},
	{
		label: 'Republic of North Macedonia',
		value: 'MK',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_North_Macedonia.svg'
	},
	{
		label: 'Romania',
		value: 'RO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Romania.svg'
	},
	{
		label: 'Russian Federation (the)',
		value: 'RU',
		icon: 'https://upload.wikimedia.org/wikipedia/en/f/f3/Flag_of_Russia.svg'
	},
	{
		label: 'Rwanda',
		value: 'RW',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Rwanda.svg'
	},
	{
		label: 'Réunion',
		value: 'RE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_R%C3%A9union.svg'
	},
	{
		label: 'Saint Barthélemy',
		value: 'BL',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/df/Flag_of_Saint_Barth%C3%A9lemy.svg'
	},
	{
		label: 'Saint Helena, Ascension and Tristan da Cunha',
		value: 'SH',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Saint_Helena.svg'
	},
	{
		label: 'Saint Kitts and Nevis',
		value: 'KN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Saint_Kitts_and_Nevis.svg'
	},
	{
		label: 'Saint Lucia',
		value: 'LC',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Saint_Lucia.svg'
	},
	{
		label: 'Saint Martin (French part)',
		value: 'MF',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_Saint_Martin.svg'
	},
	{
		label: 'Saint Pierre and Miquelon',
		value: 'PM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_Saint-Pierre_and_Miquelon.svg'
	},
	{
		label: 'Saint Vincent and the Grenadines',
		value: 'VC',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/6d/Flag_of_Saint_Vincent_and_the_Grenadin es.svg'
	},
	{
		label: 'Samoa',
		value: 'WS',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Samoa.svg'
	},
	{
		label: 'San Marino',
		value: 'SM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/b1/Flag_of_San_Marino.svg'
	},
	{
		label: 'Sao Tome and Principe',
		value: 'ST',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Sao_Tome_and_Principe.svg'
	},
	{
		label: 'Saudi Arabia',
		value: 'SA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg'
	},
	{
		label: 'Senegal',
		value: 'SN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fd/Flag_of_Senegal.svg'
	},
	{
		label: 'Serbia',
		value: 'RS',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/ff/Flag_of_Serbia.svg'
	},
	{
		label: 'Seychelles',
		value: 'SC',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Seychelles.svg'
	},
	{
		label: 'Sierra Leone',
		value: 'SL',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Sierra_Leone.svg'
	},
	{
		label: 'Singapore',
		value: 'SG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Singapore.svg'
	},

	{
		label: 'Sint Maarten (Dutch part)',
		value: 'SX',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Sint_Maarten.svg'
	},
	{
		label: 'Slovakia',
		value: 'SK',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e6/Flag_of_Slovakia.svg'
	},
	{
		label: 'Slovenia',
		value: 'SI',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f0/Flag_of_Slovenia.svg'
	},
	{
		label: 'Solomon Islands',
		value: 'SB',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Solomon_Islands.svg'
	},
	{
		label: 'Somalia',
		value: 'SO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_Somalia.svg'
	},
	{
		label: 'South Africa',
		value: 'ZA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a5/Flag_of_South_Africa.svg'
	},
	{
		label: 'South Georgia and the South Sandwich Islands',
		value: 'GS',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_South_Georgia_and_the_South_S andwich_Islands.svg'
	},
	{
		label: 'South Sudan',
		value: 'SS',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/7a/Flag_of_South_Sudan.svg'
	},
	{
		label: 'Spain',
		value: 'ES',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Spain.svg'
	},
	{
		label: 'Sri Lanka',
		value: 'LK',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg'
	},
	{
		label: 'Sudan (the)',
		value: 'SD',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Sudan.svg'
	},
	{
		label: 'Suriname',
		value: 'SR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/60/Flag_of_Suriname.svg'
	},
	{
		label: 'Svalbard and Jan Mayen',
		value: 'SJ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg'
	},
	{
		label: 'Sweden',
		value: 'SE',
		icon: 'https://upload.wikimedia.org/wikipedia/en/4/4c/Flag_of_Sweden.svg'
	},
	{
		label: 'Switzerland',
		value: 'CH',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Switzerland.svg'
	},
	{
		label: 'Syrian Arab Republic',
		value: 'SY',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/53/Flag_of_Syria.svg'
	},
	{
		label: 'Taiwan (Province of China)',
		value: 'TW',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/72/Flag_of_the_Republic_of_China.svg'
	},
	{
		label: 'Tajikistan',
		value: 'TJ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Tajikistan.svg'
	},
	{
		label: 'Tanzania, United Republic of',
		value: 'TZ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tanzania.svg'
	},
	{
		label: 'Thailand',
		value: 'TH',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_Thailand.svg'
	},
	{
		label: 'Timor-Leste',
		value: 'TL',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/26/Flag_of_East_Timor.svg'
	},
	{
		label: 'Togo',
		value: 'TG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/68/Flag_of_Togo.svg'
	},
	{
		label: 'Tokelau',
		value: 'TK',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/8e/Flag_of_Tokelau.svg'
	},
	{
		label: 'Tonga',
		value: 'TO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Tonga.svg'
	},
	{
		label: 'Trinidad and Tobago',
		value: 'TT',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Trinidad_and_Tobago.svg'
	},
	{
		label: 'Tunisia',
		value: 'TN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Tunisia.svg'
	},
	{
		label: 'Turkey',
		value: 'TR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg'
	},
	{
		label: 'Turkmenistan',
		value: 'TM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Turkmenistan.svg'
	},
	{
		label: 'Turks and Caicos Islands (the)',
		value: 'TC',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_the_Turks_and_Caicos_Islands.sv g'
	},
	{
		label: 'Tuvalu',
		value: 'TV',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tuvalu.svg'
	},
	{
		label: 'Uganda',
		value: 'UG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4e/Flag_of_Uganda.svg'
	},
	{
		label: 'Ukraine',
		value: 'UA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Ukraine.svg'
	},
	{
		label: 'United Arab Emirates (the)',
		value: 'AE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg'
	},
	{
		label: 'United Kingdom of Great Britain and Northern Ireland (the)',
		value: 'GB',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/a/ae/Flag_of_the_United_Kingdom.svg'
	},
	{
		label: 'United States Minor Outlying Islands (the)',
		value: 'UM',
		icon: 'https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg'
	},
	{
		label: 'United States of America (the)',
		value: 'US',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg'
	},
	{
		label: 'Uruguay',
		value: 'UY',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Uruguay.svg'
	},
	{
		label: 'Uzbekistan',
		value: 'UZ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Uzbekistan.svg'
	},
	{
		label: 'Vanuatu',
		value: 'VU',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Vanuatu.svg'
	},
	{
		label: 'Venezuela (Bolivarian Republic of)',
		value: 'VE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/7b/Flag_of_Venezuela_%28state%29.svg'
	},
	{
		label: 'Viet Nam',
		value: 'VN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Vietnam.svg'
	},
	{
		label: 'Virgin Islands (British)',
		value: 'VG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/42/Flag_of_the_British_Virgin_Islands.svg'
	},
	{
		label: 'Virgin Islands (U.S.)',
		value: 'VI',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f8/Flag_of_the_United_States_Virgin_Islands. svg'
	},
	{
		label: 'Wallis and Futuna',
		value: 'WF',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d2/Flag_of_Wallis_and_Futuna.svg'
	},
	{
		label: 'Western Sahara',
		value: 'EH',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/26/Flag_of_the_Sahrawi_Arab_Democratic_R epublic.svg'
	},
	{
		label: 'Yemen',
		value: 'YE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Yemen.svg'
	},
	{
		label: 'Zambia',
		value: 'ZM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/06/Flag_of_Zambia.svg'
	},
	{
		label: 'Zimbabwe',
		value: 'ZW',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/6a/Flag_of_Zimbabwe.svg'
	}
];

export const countriesObject = {
	AL: {
		label: 'Albania',
		value: 'AL',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/36/Flag_of_Albania.svg'
	},
	AX: {
		label: 'Åland Islands',
		value: 'AX',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/52/Flag_of_%C3%85land.svg'
	},
	DZ: {
		label: 'Algeria',
		value: 'DZ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Algeria.svg'
	},
	AS: {
		label: 'American Samoa',
		value: 'AS',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/87/Flag_of_American_Samoa.svg'
	},
	AD: {
		label: 'Andorra',
		value: 'AD',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Andorra.svg'
	},
	AO: {
		label: 'Angola',
		value: 'AO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Angola.svg'
	},
	AI: {
		label: 'Anguilla',
		value: 'AI',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Anguilla.svg'
	},
	AQ: {
		label: 'Antarctica',
		value: 'AQ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_the_Antarctic_Treaty.svg'
	},
	AG: {
		label: 'Antigua and Barbuda',
		value: 'AG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Antigua_and_Barbuda.svg'
	},
	AR: {
		label: 'Argentina',
		value: 'AR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg'
	},
	AM: {
		label: 'Armenia',
		value: 'AM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/2f/Flag_of_Armenia.svg'
	},
	AW: {
		label: 'Aruba',
		value: 'AW',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Aruba.svg'
	},
	AU: {
		label: 'Australia',
		value: 'AU',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_%28converted%29.svg'
	},
	AT: {
		label: 'Austria',
		value: 'AT',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg'
	},
	AZ: {
		label: 'Azerbaijan',
		value: 'AZ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Azerbaijan.svg'
	},
	BS: {
		label: 'Bahamas (the)',
		value: 'BS',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/93/Flag_of_the_Bahamas.svg'
	},
	BH: {
		label: 'Bahrain',
		value: 'BH',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Bahrain.svg'
	},
	BD: {
		label: 'Bangladesh',
		value: 'BD',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f9/Flag_of_Bangladesh.svg'
	},
	BB: {
		label: 'Barbados',
		value: 'BB',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ef/Flag_of_Barbados.svg'
	},
	BY: {
		label: 'Belarus',
		value: 'BY',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/85/Flag_of_Belarus.svg'
	},
	BE: {
		label: 'Belgium',
		value: 'BE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Belgium.svg'
	},
	BZ: {
		label: 'Belize',
		value: 'BZ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e7/Flag_of_Belize.svg'
	},
	BJ: {
		label: 'Benin',
		value: 'BJ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Benin.svg'
	},
	BM: {
		label: 'Bermuda',
		value: 'BM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bermuda.svg'
	},
	BT: {
		label: 'Bhutan',
		value: 'BT',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/91/Flag_of_Bhutan.svg'
	},
	BO: {
		label: 'Bolivia (Plurinational State of)',
		value: 'BO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/de/Flag_of_Bolivia_%28state%29.svg'
	},
	BQ: {
		label: 'Bonaire, Sint Eustatius and Saba',
		value: 'BQ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg'
	},
	BA: {
		label: 'Bosnia and Herzegovina',
		value: 'BA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bosnia_and_Herzegovina.svg'
	},
	BW: {
		label: 'Botswana',
		value: 'BW',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Botswana.svg'
	},
	BV: {
		label: 'Bouvet Island',
		value: 'BV',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg'
	},
	BR: {
		label: 'Brazil',
		value: 'BR',
		icon: 'https://upload.wikimedia.org/wikipedia/en/0/05/Flag_of_Brazil.svg'
	},
	IO: {
		label: 'British Indian Ocean Territory (the)',
		value: 'IO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/6e/Flag_of_the_British_Indian_Ocean_Territo ry.svg'
	},
	BN: {
		label: 'Brunei Darussalam',
		value: 'BN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Brunei.svg'
	},
	BG: {
		label: 'Bulgaria',
		value: 'BG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Bulgaria.svg'
	},
	BF: {
		label: 'Burkina Faso',
		value: 'BF',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Burkina_Faso.svg'
	},
	BI: {
		label: 'Burundi',
		value: 'BI',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/50/Flag_of_Burundi.svg'
	},
	CV: {
		label: 'Cabo Verde',
		value: 'CV',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Cape_Verde.svg'
	},
	KH: {
		label: 'Cambodia',
		value: 'KH',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Cambodia.svg'
	},
	CM: {
		label: 'Cameroon',
		value: 'CM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Cameroon.svg'
	},
	CA: {
		label: 'Canada',
		value: 'CA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Canada_%28Pantone%29.svg'
	},
	KY: {
		label: 'Cayman Islands (the)',
		value: 'KY',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_the_Cayman_Islands.svg'
	},
	CF: {
		label: 'Central African Republic (the)',
		value: 'CF',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/6f/Flag_of_the_Central_African_Republic.svg '
	},
	TD: {
		label: 'Chad',
		value: 'TD',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4b/Flag_of_Chad.svg'
	},
	CL: {
		label: 'Chile',
		value: 'CL',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/78/Flag_of_Chile.svg'
	},
	CN: {
		label: 'China',
		value: 'CN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_Chin a.svg'
	},
	CX: {
		label: 'Christmas Island',
		value: 'CX',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/67/Flag_of_Christmas_Island.svg'
	},
	CC: {
		label: 'Cocos (Keeling) Islands (the)',
		value: 'CC',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Cocos_%28Keeling%29_Island s.svg'
	},
	CO: {
		label: 'Colombia',
		value: 'CO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Colombia.svg'
	},
	KM: {
		label: 'Comoros (the)',
		value: 'KM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/94/Flag_of_the_Comoros.svg'
	},

	CD: {
		label: 'Congo (the Democratic Republic of the)',
		value: 'CD',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/6f/Flag_of_the_Democratic_Republic_of_the _Congo.svg'
	},
	CG: {
		label: 'Congo (the)',
		value: 'CG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_the_Republic_of_the_Congo.svg'
	},
	CK: {
		label: 'Cook Islands (the)',
		value: 'CK',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/35/Flag_of_the_Cook_Islands.svg'
	},
	CR: {
		label: 'Costa Rica',
		value: 'CR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Costa_Rica_%28state%29.svg'
	},
	HR: {
		label: 'Croatia',
		value: 'HR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg'
	},
	CU: {
		label: 'Cuba',
		value: 'CU',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bd/Flag_of_Cuba.svg'
	},
	CW: {
		label: 'Curaçao',
		value: 'CW',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/b1/Flag_of_Cura%C3%A7ao.svg'
	},
	CY: {
		label: 'Cyprus',
		value: 'CY',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Cyprus.svg'
	},
	CZ: {
		label: 'Czechia',
		value: 'CZ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_Czech_Republic.svg'
	},
	CI: {
		label: 'Côte dIvoire',
		value: 'CI',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_C%C3%B4te_d%27Ivoire.svg'
	},
	DK: {
		label: 'Denmark',
		value: 'DK',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg'
	},
	DJ: {
		label: 'Djibouti',
		value: 'DJ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_Djibouti.svg'
	},
	DM: {
		label: 'Dominica',
		value: 'DM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c4/Flag_of_Dominica.svg'
	},
	DO: {
		label: 'Dominican Republic (the)',
		value: 'DO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_the_Dominican_Republic.svg'
	},
	EC: {
		label: 'Ecuador',
		value: 'EC',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e8/Flag_of_Ecuador.svg'
	},
	EG: {
		label: 'Egypt',
		value: 'EG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Egypt.svg'
	},
	SV: {
		label: 'El Salvador',
		value: 'SV',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_El_Salvador.svg'
	},
	GQ: {
		label: 'Equatorial Guinea',
		value: 'GQ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Equatorial_Guinea.svg'
	},
	ER: {
		label: 'Eritrea',
		value: 'ER',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_Eritrea.svg'
	},
	EE: {
		label: 'Estonia',
		value: 'EE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/8f/Flag_of_Estonia.svg'
	},
	SZ: {
		label: 'Eswatini',
		value: 'SZ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1e/Flag_of_Eswatini.svg'
	},
	ET: {
		label: 'Ethiopia',
		value: 'ET',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/71/Flag_of_Ethiopia.svg'
	},
	FK: {
		label: 'Falkland Islands (the) [Malvinas]',
		value: 'FK',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_Falkland_Islands.svg'
	},
	FO: {
		label: 'Faroe Islands (the)',
		value: 'FO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/Flag_of_the_Faroe_Islands.svg'
	},
	FJ: {
		label: 'Fiji',
		value: 'FJ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Fiji.svg'
	},

	FI: {
		label: 'Finland',
		value: 'FI',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Finland.svg'
	},
	FR: {
		label: 'France',
		value: 'FR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg'
	},
	GF: {
		label: 'French Guiana',
		value: 'GF',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_French_Guiana.svg'
	},
	PF: {
		label: 'French Polynesia',
		value: 'PF',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/db/Flag_of_French_Polynesia.svg'
	},
	TF: {
		label: 'French Southern Territories (the)',
		value: 'TF',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a7/Flag_of_the_French_Southern_and_Antar ctic_Lands.svg'
	},
	GA: {
		label: 'Gabon',
		value: 'GA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/04/Flag_of_Gabon.svg'
	},
	GM: {
		label: 'Gambia (the)',
		value: 'GM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_The_Gambia.svg'
	},
	GE: {
		label: 'Georgia',
		value: 'GE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Georgia.svg'
	},
	DE: {
		label: 'Germany',
		value: 'DE',
		icon: 'https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg'
	},
	GH: {
		label: 'Ghana',
		value: 'GH',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg'
	},
	GI: {
		label: 'Gibraltar',
		value: 'GI',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/02/Flag_of_Gibraltar.svg'
	},
	GR: {
		label: 'Greece',
		value: 'GR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Greece.svg'
	},
	GL: {
		label: 'Greenland',
		value: 'GL',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_Greenland.svg'
	},
	GD: {
		label: 'Grenada',
		value: 'GD',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Grenada.svg'
	},
	GP: {
		label: 'Guadeloupe',
		value: 'GP',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/04/Flag_of_Guadeloupe_%28local%29.svg'
	},
	GU: {
		label: 'Guam',
		value: 'GU',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/07/Flag_of_Guam.svg'
	},
	GT: {
		label: 'Guatemala',
		value: 'GT',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ec/Flag_of_Guatemala.svg'
	},
	GG: {
		label: 'Guernsey',
		value: 'GG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Guernsey.svg'
	},
	GN: {
		label: 'Guinea',
		value: 'GN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_Guinea.svg'
	},
	GW: {
		label: 'Guinea-Bissau',
		value: 'GW',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Guinea-Bissau.svg'
	},
	GY: {
		label: 'Guyana',
		value: 'GY',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_Guyana.svg'
	},
	HT: {
		label: 'Haiti',
		value: 'HT',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Haiti.svg'
	},
	HM: {
		label: 'Heard Island and McDonald Islands',
		value: 'HM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Antarctica.svg'
	},
	VA: {
		label: 'Holy See (the)',
		value: 'VA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_the_Vatican_City.svg'
	},
	HN: {
		label: 'Honduras',
		value: 'HN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/82/Flag_of_Honduras.svg'
	},

	HK: {
		label: 'Hong Kong',
		value: 'HK',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Hong_Kong.svg'
	},
	HU: {
		label: 'Hungary',
		value: 'HU',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Hungary.svg'
	},
	IS: {
		label: 'Iceland',
		value: 'IS',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Iceland.svg'
	},
	IN: {
		label: 'India',
		value: 'IN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg'
	},
	ID: {
		label: 'Indonesia',
		value: 'ID',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg'
	},
	IR: {
		label: 'Iran (Islamic Republic of)',
		value: 'IR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/ca/Flag_of_Iran.svg'
	},
	IQ: {
		label: 'Iraq',
		value: 'IQ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Iraq.svg'
	},
	IE: {
		label: 'Ireland',
		value: 'IE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/45/Flag_of_Ireland.svg'
	},
	IM: {
		label: 'Isle of Man',
		value: 'IM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_the_Isle_of_Man.svg'
	},
	IL: {
		label: 'Israel',
		value: 'IL',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg'
	},
	IT: {
		label: 'Italy',
		value: 'IT',
		icon: 'https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg'
	},
	JM: {
		label: 'Jamaica',
		value: 'JM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Jamaica.svg'
	},
	JP: {
		label: 'Japan',
		value: 'JP',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9e/Flag_of_Japan.svg'
	},
	JE: {
		label: 'Jersey',
		value: 'JE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1c/Flag_of_Jersey.svg'
	},
	JO: {
		label: 'Jordan',
		value: 'JO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c0/Flag_of_Jordan.svg'
	},
	KZ: {
		label: 'Kazakhstan',
		value: 'KZ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kazakhstan.svg'
	},
	KE: {
		label: 'Kenya',
		value: 'KE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Kenya.svg'
	},
	KI: {
		label: 'Kiribati',
		value: 'KI',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kiribati.svg'
	},
	KP: {
		label: "Korea (the Democratic People's Republic of)",
		value: 'KP',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Flag_of_North_Korea.svg'
	},
	KR: {
		label: 'Korea (the Republic of)',
		value: 'KR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_South_Korea.svg'
	},
	KW: {
		label: 'Kuwait',
		value: 'KW',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Kuwait.svg'
	},
	KG: {
		label: 'Kyrgyzstan',
		value: 'KG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c7/Flag_of_Kyrgyzstan.svg'
	},
	LA: {
		label: "Lao People's Democratic Republic (the)",
		value: 'LA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Laos.svg'
	},
	LV: {
		label: 'Latvia',
		value: 'LV',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Latvia.svg'
	},
	LB: {
		label: 'Lebanon',
		value: 'LB',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/59/Flag_of_Lebanon.svg'
	},
	LS: {
		label: 'Lesotho',
		value: 'LS',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Flag_of_Lesotho.svg'
	},

	LR: {
		label: 'Liberia',
		value: 'LR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/b8/Flag_of_Liberia.svg'
	},
	LY: {
		label: 'Libya',
		value: 'LY',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_Libya.svg'
	},
	LI: {
		label: 'Liechtenstein',
		value: 'LI',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/47/Flag_of_Liechtenstein.svg'
	},
	LT: {
		label: 'Lithuania',
		value: 'LT',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Lithuania.svg'
	},
	LU: {
		label: 'Luxembourg',
		value: 'LU',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/da/Flag_of_Luxembourg.svg'
	},
	MO: {
		label: 'Macao',
		value: 'MO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/63/Flag_of_Macau.svg'
	},
	MG: {
		label: 'Madagascar',
		value: 'MG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Madagascar.svg'
	},
	MW: {
		label: 'Malawi',
		value: 'MW',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d1/Flag_of_Malawi.svg'
	},
	MY: {
		label: 'Malaysia',
		value: 'MY',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_Malaysia.svg'
	},
	MV: {
		label: 'Maldives',
		value: 'MV',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Maldives.svg'
	},
	ML: {
		label: 'Mali',
		value: 'ML',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_Mali.svg'
	},
	MT: {
		label: 'Malta',
		value: 'MT',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Malta.svg'
	},
	MH: {
		label: 'Marshall Islands (the)',
		value: 'MH',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/2e/Flag_of_the_Marshall_Islands.svg'
	},
	MQ: {
		label: 'Martinique',
		value: 'MQ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/52/Flag_of_Martinique.svg'
	},
	MR: {
		label: 'Mauritania',
		value: 'MR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/43/Flag_of_Mauritania.svg'
	},
	MU: {
		label: 'Mauritius',
		value: 'MU',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Mauritius.svg'
	},
	YT: {
		label: 'Mayotte',
		value: 'YT',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Flag_of_Mayotte_%28local%29.svg'
	},
	MX: {
		label: 'Mexico',
		value: 'MX',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.svg'
	},
	FM: {
		label: 'Micronesia (Federated States of)',
		value: 'FM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e4/Flag_of_the_Federated_States_of_Micron esia.svg'
	},
	MD: {
		label: 'Moldova (the Republic of)',
		value: 'MD',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Moldova.svg'
	},
	MC: {
		label: 'Monaco',
		value: 'MC',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ea/Flag_of_Monaco.svg'
	},
	MN: {
		label: 'Mongolia',
		value: 'MN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Mongolia.svg'
	},
	ME: {
		label: 'Montenegro',
		value: 'ME',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Montenegro.svg'
	},
	MS: {
		label: 'Montserrat',
		value: 'MS',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Montserrat.svg'
	},
	MA: {
		label: 'Morocco',
		value: 'MA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Morocco.svg'
	},

	MZ: {
		label: 'Mozambique',
		value: 'MZ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Mozambique.svg'
	},
	MM: {
		label: 'Myanmar',
		value: 'MM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/8c/Flag_of_Myanmar.svg'
	},
	NA: {
		label: 'Namibia',
		value: 'NA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Namibia.svg'
	},
	NR: {
		label: 'Nauru',
		value: 'NR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/30/Flag_of_Nauru.svg'
	},
	NP: {
		label: 'Nepal',
		value: 'NP',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9b/Flag_of_Nepal.svg'
	},
	NL: {
		label: 'Netherlands (the)',
		value: 'NL',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg'
	},
	NC: {
		label: 'New Caledonia',
		value: 'NC',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_New_Caledonia.svg'
	},
	NZ: {
		label: 'New Zealand',
		value: 'NZ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/3e/Flag_of_New_Zealand.svg'
	},
	NI: {
		label: 'Nicaragua',
		value: 'NI',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Nicaragua.svg'
	},
	NE: {
		label: 'Niger (the)',
		value: 'NE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f4/Flag_of_Niger.svg'
	},
	NG: {
		label: 'Nigeria',
		value: 'NG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_Nigeria.svg'
	},
	NU: {
		label: 'Niue',
		value: 'NU',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Niue.svg'
	},
	NF: {
		label: 'Norfolk Island',
		value: 'NF',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Norfolk_Island.svg'
	},
	MP: {
		label: 'Northern Mariana Islands (the)',
		value: 'MP',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e0/Flag_of_the_Northern_Mariana_Islands.sv g'
	},
	NO: {
		label: 'Norway',
		value: 'NO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg'
	},
	OM: {
		label: 'Oman',
		value: 'OM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Oman.svg'
	},
	PK: {
		label: 'Pakistan',
		value: 'PK',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg'
	},
	PW: {
		label: 'Palau',
		value: 'PW',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Palau.svg'
	},
	PS: {
		label: 'Palestine, State of',
		value: 'PS',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Palestine.svg'
	},
	PA: {
		label: 'Panama',
		value: 'PA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/a/ab/Flag_of_Panama.svg'
	},
	PG: {
		label: 'Papua New Guinea',
		value: 'PG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e3/Flag_of_Papua_New_Guinea.svg'
	},
	PY: {
		label: 'Paraguay',
		value: 'PY',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Paraguay.svg'
	},
	PE: {
		label: 'Peru',
		value: 'PE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Peru.svg'
	},
	PH: {
		label: 'Philippines (the)',
		value: 'PH',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_the_Philippines.svg'
	},
	PN: {
		label: 'Pitcairn',
		value: 'PN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_the_Pitcairn_Islands.svg'
	},

	PL: {
		label: 'Poland',
		value: 'PL',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/12/Flag_of_Poland.svg'
	},
	PT: {
		label: 'Portugal',
		value: 'PT',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg'
	},
	PR: {
		label: 'Puerto Rico',
		value: 'PR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/28/Flag_of_Puerto_Rico.svg'
	},
	QA: {
		label: 'Qatar',
		value: 'QA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Qatar.svg'
	},
	MK: {
		label: 'Republic of North Macedonia',
		value: 'MK',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_North_Macedonia.svg'
	},
	RO: {
		label: 'Romania',
		value: 'RO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Romania.svg'
	},
	RU: {
		label: 'Russian Federation (the)',
		value: 'RU',
		icon: 'https://upload.wikimedia.org/wikipedia/en/f/f3/Flag_of_Russia.svg'
	},
	RW: {
		label: 'Rwanda',
		value: 'RW',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Rwanda.svg'
	},
	RE: {
		label: 'Réunion',
		value: 'RE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_R%C3%A9union.svg'
	},
	BL: {
		label: 'Saint Barthélemy',
		value: 'BL',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/df/Flag_of_Saint_Barth%C3%A9lemy.svg'
	},
	SH: {
		label: 'Saint Helena, Ascension and Tristan da Cunha',
		value: 'SH',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Saint_Helena.svg'
	},
	KN: {
		label: 'Saint Kitts and Nevis',
		value: 'KN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Saint_Kitts_and_Nevis.svg'
	},
	LC: {
		label: 'Saint Lucia',
		value: 'LC',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Saint_Lucia.svg'
	},
	MF: {
		label: 'Saint Martin (French part)',
		value: 'MF',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_Saint_Martin.svg'
	},
	PM: {
		label: 'Saint Pierre and Miquelon',
		value: 'PM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_Saint-Pierre_and_Miquelon.svg'
	},
	VC: {
		label: 'Saint Vincent and the Grenadines',
		value: 'VC',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/6d/Flag_of_Saint_Vincent_and_the_Grenadin es.svg'
	},
	WS: {
		label: 'Samoa',
		value: 'WS',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Samoa.svg'
	},
	SM: {
		label: 'San Marino',
		value: 'SM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/b1/Flag_of_San_Marino.svg'
	},
	ST: {
		label: 'Sao Tome and Principe',
		value: 'ST',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Sao_Tome_and_Principe.svg'
	},
	SA: {
		label: 'Saudi Arabia',
		value: 'SA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg'
	},
	SN: {
		label: 'Senegal',
		value: 'SN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fd/Flag_of_Senegal.svg'
	},
	RS: {
		label: 'Serbia',
		value: 'RS',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/ff/Flag_of_Serbia.svg'
	},
	SC: {
		label: 'Seychelles',
		value: 'SC',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Seychelles.svg'
	},
	SL: {
		label: 'Sierra Leone',
		value: 'SL',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Sierra_Leone.svg'
	},
	SG: {
		label: 'Singapore',
		value: 'SG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Singapore.svg'
	},

	SX: {
		label: 'Sint Maarten (Dutch part)',
		value: 'SX',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Sint_Maarten.svg'
	},
	SK: {
		label: 'Slovakia',
		value: 'SK',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e6/Flag_of_Slovakia.svg'
	},
	SI: {
		label: 'Slovenia',
		value: 'SI',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f0/Flag_of_Slovenia.svg'
	},
	SB: {
		label: 'Solomon Islands',
		value: 'SB',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Solomon_Islands.svg'
	},
	SO: {
		label: 'Somalia',
		value: 'SO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_Somalia.svg'
	},
	ZA: {
		label: 'South Africa',
		value: 'ZA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a5/Flag_of_South_Africa.svg'
	},
	GS: {
		label: 'South Georgia and the South Sandwich Islands',
		value: 'GS',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_South_Georgia_and_the_South_S andwich_Islands.svg'
	},
	SS: {
		label: 'South Sudan',
		value: 'SS',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/7a/Flag_of_South_Sudan.svg'
	},
	ES: {
		label: 'Spain',
		value: 'ES',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Spain.svg'
	},
	LK: {
		label: 'Sri Lanka',
		value: 'LK',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg'
	},
	SD: {
		label: 'Sudan (the)',
		value: 'SD',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Sudan.svg'
	},
	SR: {
		label: 'Suriname',
		value: 'SR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/60/Flag_of_Suriname.svg'
	},
	SJ: {
		label: 'Svalbard and Jan Mayen',
		value: 'SJ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg'
	},
	SE: {
		label: 'Sweden',
		value: 'SE',
		icon: 'https://upload.wikimedia.org/wikipedia/en/4/4c/Flag_of_Sweden.svg'
	},
	CH: {
		label: 'Switzerland',
		value: 'CH',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Switzerland.svg'
	},
	SY: {
		label: 'Syrian Arab Republic',
		value: 'SY',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/53/Flag_of_Syria.svg'
	},
	TW: {
		label: 'Taiwan (Province of China)',
		value: 'TW',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/72/Flag_of_the_Republic_of_China.svg'
	},
	TJ: {
		label: 'Tajikistan',
		value: 'TJ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Tajikistan.svg'
	},
	TZ: {
		label: 'Tanzania, United Republic of',
		value: 'TZ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tanzania.svg'
	},
	TH: {
		label: 'Thailand',
		value: 'TH',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_Thailand.svg'
	},
	TL: {
		label: 'Timor-Leste',
		value: 'TL',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/26/Flag_of_East_Timor.svg'
	},
	TG: {
		label: 'Togo',
		value: 'TG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/68/Flag_of_Togo.svg'
	},
	TK: {
		label: 'Tokelau',
		value: 'TK',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/8e/Flag_of_Tokelau.svg'
	},
	TO: {
		label: 'Tonga',
		value: 'TO',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Tonga.svg'
	},
	TT: {
		label: 'Trinidad and Tobago',
		value: 'TT',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Trinidad_and_Tobago.svg'
	},
	TN: {
		label: 'Tunisia',
		value: 'TN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Tunisia.svg'
	},
	TR: {
		label: 'Turkey',
		value: 'TR',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg'
	},
	TM: {
		label: 'Turkmenistan',
		value: 'TM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Turkmenistan.svg'
	},
	TC: {
		label: 'Turks and Caicos Islands (the)',
		value: 'TC',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_the_Turks_and_Caicos_Islands.sv g'
	},
	TV: {
		label: 'Tuvalu',
		value: 'TV',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tuvalu.svg'
	},
	UG: {
		label: 'Uganda',
		value: 'UG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4e/Flag_of_Uganda.svg'
	},
	UA: {
		label: 'Ukraine',
		value: 'UA',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Ukraine.svg'
	},
	AE: {
		label: 'United Arab Emirates (the)',
		value: 'AE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg'
	},
	GB: {
		label: 'United Kingdom of Great Britain and Northern Ireland (the)',
		value: 'GB',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/a/ae/Flag_of_the_United_Kingdom.svg'
	},
	UM: {
		label: 'United States Minor Outlying Islands (the)',
		value: 'UM',
		icon: 'https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg'
	},
	US: {
		label: 'United States of America (the)',
		value: 'US',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg'
	},
	UY: {
		label: 'Uruguay',
		value: 'UY',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Uruguay.svg'
	},
	UZ: {
		label: 'Uzbekistan',
		value: 'UZ',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Uzbekistan.svg'
	},
	VU: {
		label: 'Vanuatu',
		value: 'VU',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Vanuatu.svg'
	},
	VE: {
		label: 'Venezuela (Bolivarian Republic of)',
		value: 'VE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/7b/Flag_of_Venezuela_%28state%29.svg'
	},
	VN: {
		label: 'Viet Nam',
		value: 'VN',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Vietnam.svg'
	},
	VG: {
		label: 'Virgin Islands (British)',
		value: 'VG',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/4/42/Flag_of_the_British_Virgin_Islands.svg'
	},
	VI: {
		label: 'Virgin Islands (U.S.)',
		value: 'VI',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f8/Flag_of_the_United_States_Virgin_Islands. svg'
	},
	WF: {
		label: 'Wallis and Futuna',
		value: 'WF',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d2/Flag_of_Wallis_and_Futuna.svg'
	},
	EH: {
		label: 'Western Sahara',
		value: 'EH',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/2/26/Flag_of_the_Sahrawi_Arab_Democratic_R epublic.svg'
	},
	YE: {
		label: 'Yemen',
		value: 'YE',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Yemen.svg'
	},
	ZM: {
		label: 'Zambia',
		value: 'ZM',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/0/06/Flag_of_Zambia.svg'
	},
	ZW: {
		label: 'Zimbabwe',
		value: 'ZW',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/6/6a/Flag_of_Zimbabwe.svg'
	}
};
