import BackButtonCabinet from 'pages/components/BackButtonCabinet/BackButtonCabinet';
import { useEffect, useState } from 'react';
import {
	OfferPaymentModel,
	TLanding,
	TOffer,
	offersApi
} from 'store/services/offers';

import { OfferSelection } from './OfferSelection';
import { FirstStep } from './Steps/FirstStep';
import { SecondStep, TOfferType } from './Steps/SecondStep';
import { ThirdStep } from './Steps/ThirdStep';

const CreatePromo = () => {
	const [step, setStep] = useState<number>(0);
	const [selectedOffer, setOffer] = useState<TOffer>();
	const [selectedOfferType, setOfferType] = useState<TOfferType>();
	const [selectedLanding, setLanding] = useState<TLanding>();

	const [paymentModelFilter, setPaymentModelFilter] =
		useState<OfferPaymentModel>();

	const { data: offers } = offersApi.useGetOffersQuery({
		...(paymentModelFilter && { paymentModel: paymentModelFilter })
	});

	const onChange = (nextStep: number): void => {
		setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
		window.scrollTo(0, 0);
	};

	const onNext = (): void => {
		onChange(step + 1);
	};

	const onPrevious = (): void => {
		onChange(step - 1);
	};

	useEffect(() => setLanding(undefined), [selectedOfferType]);

	return (
		<div className='create-promo'>
			<div className='create-promo-steps__head'>
				<BackButtonCabinet className='create-promo-steps__back' />
				<div className='create-promo-steps__title title'>Create new promo</div>
			</div>
			<div className='create-promo__steps create-promo-steps'>
				<OfferSelection
					setPaymentModelFilter={setPaymentModelFilter}
					selectedOfferType={selectedOfferType}
					selectedLanding={selectedLanding}
					offer={selectedOffer}
					onPrevious={onPrevious}
					onNext={onNext}
					step={step}
				/>

				<div className='create-promo-steps__sections'>
					{step === 0 && (
						<FirstStep
							offers={offers}
							setSelectedOffer={setOffer}
							selectedOffer={selectedOffer}
						/>
					)}
					{step === 1 && (
						<SecondStep
							setOfferType={setOfferType}
							selectedOfferType={selectedOfferType}
						/>
					)}
					{step === 2 && selectedOfferType?.value !== 'directLink' && (
						<ThirdStep
							setLanding={setLanding}
							selectedOffer={selectedOffer}
							selectedLanding={selectedLanding}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default CreatePromo;
